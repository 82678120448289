import rebundleData from './../../rebundle-data';
import { fetch } from 'whatwg-fetch';
import getBaseUrl from '../../utils/get-base-url';
import { usedWithinEmbeddedThemeContext } from '../../utils/customer-portal';
import { makeRequest } from '../../utils/request';
import { isOnetimePlan, isPrepaid } from '../../utils/plans';
import { hasPermissions, PERMISSIONS } from '../../plugins/permissions';
import { getCustomerPortalCredentials } from '../../utils/auth';

async function updateBundleContents(subscriptionId, bundle, customerToken, baseUrl) {
  const bundlesUrl = new URL(`${baseUrl}/subscriptions/${subscriptionId}/bundles`);
  bundlesUrl.searchParams.set('token', customerToken);
  if (usedWithinEmbeddedThemeContext()) {
    // TODO: Maybe we want to obtain the theme name from somewhere? It could be added in the CP window context
    bundlesUrl.searchParams.set('client', 'affinity');
  }

  const payload = {
    properties: bundle.properties.map(({ name, value }) => ({ name, value: String(value) })),
    bundle_selection: {
      selections: bundle.items.map((item) => {
        return {
          collection_id: parseInt(item.collectionId, 10),
          external_product_id: parseInt(item.productId, 10),
          external_variant_id: parseInt(item.variantId, 10),
          quantity: item.quantity,
        };
      }),
    },
  };

  if (bundle.plan_id) {
    payload['plan_id'] = bundle.plan_id;
  }

  return await makeRequest(bundlesUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

async function updateSubscriptionProperties(subscription, subscriptionId, customerToken, baseUrl) {
  if (!subscription) return;

  const subscriptionUrl = new URL(`${baseUrl}/subscriptions/${subscriptionId}`);
  subscriptionUrl.searchParams.set('token', customerToken);

  return await fetch(subscriptionUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(subscription),
  });
}

async function createSubscription(data, baseUrl, customerToken) {
  const subscriptionUrl = new URL(`${baseUrl}/subscriptions/`);
  subscriptionUrl.searchParams.set('token', customerToken);
  if (usedWithinEmbeddedThemeContext()) {
    subscriptionUrl.searchParams.set('client', 'affinity');
  }

  return await makeRequest(subscriptionUrl, { method: 'POST', body: JSON.stringify(data) });
}

async function swapSubscription(subscriptionId, data, customerToken, baseUrl) {
  const url = new URL(`${baseUrl}/subscriptions/${subscriptionId}/swap`);
  url.searchParams.set('token', customerToken);

  if (usedWithinEmbeddedThemeContext()) {
    url.searchParams.set('client', 'affinity');
  }

  return makeRequest(url, { method: 'POST', body: JSON.stringify(data) });
}

async function createOnetime(data, baseUrl, customerToken) {
  const subscriptionUrl = new URL(`${baseUrl}/onetimes`);
  subscriptionUrl.searchParams.set('token', customerToken);
  if (usedWithinEmbeddedThemeContext()) {
    subscriptionUrl.searchParams.set('client', 'affinity');
  }

  return await makeRequest(subscriptionUrl, { method: 'POST', body: JSON.stringify(data) });
}

export default {
  state: () => ({
    subscription: null,
  }),

  getters: {
    rechargeCustomerData: () => async () => {
      const { customerHash, token } = await getCustomerPortalCredentials();

      const path = window.location.pathname;
      const params = new URLSearchParams(window.location.search);
      let subscriptionId = params.get('subscription') || params.get('subscriptionId');
      if (path.indexOf('/subscriptions/') >= 0) {
        subscriptionId = path.split('/subscriptions/')[1].split('/')[0];
      } else if (window.BoxConfig?.subscriptionConfig?.subscriptionId) {
        subscriptionId = window.BoxConfig.subscriptionConfig.subscriptionId;
      }

      let bundleKey;
      if (path.indexOf('/bundles/') >= 0) {
        bundleKey = path.split('/bundles/')[1];
      }

      return {
        subscriptionId,
        bundleKey,
        customerToken: token,
        customerHash,
      };
    },

    getPropertyValue: (state) => (name) => {
      let propertyObject = state.subscription
        ? state.subscription.properties.find(function (property) {
            return property.name === name;
          })
        : null;
      return propertyObject ? propertyObject.value : null;
    },

    savedOrder: (_, getters, rootState) => {
      const settings = getters.productSettings.settings.settings ?? {};
      const displayVariantAsProduct = settings.display_variants_as_separate_products_in_list;

      // Read initial selection from BoxConfig initial state
      if (rootState.configInitialState?.items) {
        return rootState.configInitialState.items.reduce((acc, { externalVariantId, externalProductId, quantity }) => {
          const pId = displayVariantAsProduct ? externalVariantId : externalProductId;
          acc[externalVariantId] = { pId: Number(pId), vId: Number.parseInt(externalVariantId), qty: quantity };
          return acc;
        }, {});
      }

      let orderHash = getters.getPropertyValue('_box_order') || getters.getPropertyValue('box_order');
      return orderHash ? rebundleData.decodeToLegacyBoxOrder(orderHash, displayVariantAsProduct) : {};
    },

    hasUnsavedChanges: (state, getters) => {
      let orderKeys = Object.keys(getters.selectedContentsByVariantId);

      if (orderKeys.length === Object.keys(getters.savedOrder).length) {
        let ordersMatch = orderKeys.every(function (key) {
          return (
            getters.savedOrder[key] && getters.selectedContentsByVariantId[key].qty === getters.savedOrder[key].qty
          );
        });
        return !ordersMatch;
      } else {
        return true;
      }
    },

    selectedPlanChanged: (state, getters, rootState) => {
      if (!state.subscription) return false;

      const { order_interval_unit, order_interval_frequency, charge_interval_frequency } = state.subscription;
      // Uses the subscription frequency and unit data as default plan values if the subscription plan is no
      // longer available to force the user to select a new one
      const {
        intervalUnit = order_interval_unit,
        orderIntervalFrequency = order_interval_frequency,
        chargeIntervalFrequency = charge_interval_frequency,
      } = rootState.selectedPlan?.subscriptionPreferences ?? {};

      const unitChanged = intervalUnit !== order_interval_unit;
      const orderFrequencyChanged = Number(orderIntervalFrequency) !== Number(order_interval_frequency);
      const chargeFrequencyChanged = Number(chargeIntervalFrequency) !== Number(charge_interval_frequency);
      return unitChanged || orderFrequencyChanged || chargeFrequencyChanged;
    },

    canSave: (state, getters, rootState) => {
      let itemCountCorrect = getters.selectionIsBalanced;

      const frequencyChanged =
        Number(rootState.selectedFrequency) !== Number(state.subscription?.order_interval_frequency);
      const planChanged = getters.useRechargePlans ? getters.selectedPlanChanged : frequencyChanged;

      const variantChanged = getters.activeVariant.id !== state.subscription?.shopify_variant_id;
      const isFallbackSelection = !!state.subscription?.bundle_selections?.is_fallback;

      return itemCountCorrect && (getters.hasUnsavedChanges || variantChanged || planChanged || isFallbackSelection);
    },

    baseUrl: (state, getters, rootState) => async () => {
      const { customerHash } = await getters.rechargeCustomerData();
      return getBaseUrl({ appProxy: rootState.appProxy, customerHash });
    },

    getSubscriptionPreferences: (state, getters, rootState) => {
      if (getters.useRechargePlans) {
        return getters.selectedPlan.subscriptionPreferences;
      }

      const subscriptionDefaults =
        state.subscription?.product.subscription_defaults || rootState.product.subscriptionDefaults;
      return {
        orderIntervalFrequency: rootState.selectedFrequency,
        intervalUnit: subscriptionDefaults.order_interval_unit,
        chargeIntervalFrequency: state.subscription.is_prepaid
          ? subscriptionDefaults.charge_interval_frequency
          : rootState.selectedFrequency,
      };
    },

    shouldSendPlanId: (state, getters) => {
      /* The dynamicFilterProductsByDiscount setting is only true when it's a dynamic bundle and the store is using PSP */
      if (getters.useRechargePlans && getters.dynamicFilterProductsByDiscount) return true;

      // At this point, only legacy dynamic bundles and fixed price bundles should be considered to send the plan ID.
      // For old dynamic bundles (with dynamic_filter_products_by_discount=false), the price is calculated using
      // individual products plans configuration. So, we should not send the bundle product plan Id
      const canUsePlans = hasPermissions([PERMISSIONS.CAN_USE_PLANS], getters.getStorePermissions);
      return getters.useRechargePlans && canUsePlans && !getters.isDynamicBundle;
    },

    formatCreateSubscriptionPayloadWithPlans: (_, getters, rootState) => (initialPayload) => {
      initialPayload.purchaseItemPayload.plan_id = rootState.selectedPlan.id;
      initialPayload.bundlePayload.plan_id = rootState.selectedPlan.id;
      if (isOnetimePlan(rootState.selectedPlan)) return initialPayload;

      initialPayload.purchaseItemPayload.version = '2021-11';
      initialPayload.purchaseItemPayload.external_product_id = rootState.product.id;
      initialPayload.purchaseItemPayload.external_variant_id = initialPayload.purchaseItemPayload['shopify_variant_id'];
      return initialPayload;
    },

    formatCreateSubscriptionPayload: (_, getters, rootState) => (subscriptionConfig) => {
      const data = {
        purchaseItemPayload: {
          address_id: Number(subscriptionConfig.addressId),
          next_charge_scheduled_at: subscriptionConfig.nextChargeAt,
          quantity: 1,
          shopify_variant_id: rootState.activeVariantId,
        },
        bundlePayload: subscriptionConfig.bundle,
      };

      if (subscriptionConfig.giftId) {
        data.purchaseItemPayload.gift_id = subscriptionConfig.giftId;
      }

      if (getters.shouldSendPlanId) return getters.formatCreateSubscriptionPayloadWithPlans(data);

      if (!getters.isOnetimeSelected) {
        const isPrepaidProduct = isPrepaid(getters.activeVariant.variant.plans);

        const subscriptionPreferences = getters.getSubscriptionPreferences;
        data.purchaseItemPayload['order_interval_frequency'] = subscriptionPreferences.orderIntervalFrequency;
        data.purchaseItemPayload['order_interval_unit'] = subscriptionPreferences.intervalUnit;
        data.purchaseItemPayload['charge_interval_frequency'] = isPrepaidProduct
          ? subscriptionPreferences.chargeIntervalFrequency
          : subscriptionPreferences.orderIntervalFrequency;
      }

      return data;
    },

    formatEventPayload: (_, getters, rootState) => (type, data) => {
      const payload = {
        type,
        details: {
          planId: rootState.selectedPlan?.id ?? 0,
          externalProductId: rootState.product.id,
          externalVariantId: rootState.activeVariantId || rootState.configInitialState?.externalVariantId,
          items: data.bundle.items.map((item) => ({
            collectionSource: 'shopify',
            collectionId: item.collectionId,
            externalProductId: item.productId,
            externalVariantId: item.variantId,
            quantity: item.quantity,
            price: 0,
          })),
        },
      };

      if (getters.isDynamicBundle) {
        payload.details.items = payload.details.items.map((item) => {
          const product = getters.selectedContentsByVariantId[item.externalVariantId].product;
          const variant = product.variants.find((variant) => (variant.id = Number(item.externalVariantId)));
          return { ...item, price: getters.itemPriceByPlan(product, variant).active };
        });
      }

      return payload;
    },
  },

  actions: {
    async updateSubscription({ commit, state, getters }, { data, i18n }) {
      if (!getters.selectionIsBalanced) {
        commit('setBusyState', false);
        return false;
      }

      commit('setBusyState', true);

      const baseUrl = await getters.baseUrl();
      const { customerToken, subscriptionId } = await getters.rechargeCustomerData();

      // Remove box_contents and _box_order from the properties array
      // this is to avoid ending with two versions of box_contents
      // since we allow merchants to localize it on signup
      data.bundle.properties = data.bundle.properties.concat(
        state.subscription.properties.filter(
          (p) => !['box_contents', '_box_order', i18n.t('boxContents')].includes(p.name)
        )
      );

      // only update the subscription object if there are changing attributes
      // like the frequency, or variant.
      if (Object.keys(data.subscription).length) {
        const response = await updateSubscriptionProperties(
          { ...data.subscription },
          subscriptionId,
          customerToken,
          baseUrl
        );

        if (!response.ok) {
          const body = await response.text();
          throw new Error(body);
        }
      }

      await updateBundleContents(subscriptionId, data.bundle, customerToken, baseUrl);
      commit('setBusyState', false);
    },

    async createNewSubscription({ getters, rootState }, subscriptionConfig) {
      if (!getters.canSave) return;

      const { customerHash, customerToken } = await getters.rechargeCustomerData();
      const baseUrl = getBaseUrl({ appProxy: rootState.appProxy, customerHash });
      const { bundlePayload, purchaseItemPayload } = getters.formatCreateSubscriptionPayload(subscriptionConfig);

      let createSubscriptionFunction = createSubscription;
      if (getters.isOnetimeSelected) {
        createSubscriptionFunction = createOnetime;
      }

      const response = await createSubscriptionFunction(purchaseItemPayload, baseUrl, customerToken);
      const result = await response.json();
      let purchaseItem = result.onetime || result.subscription;
      if (response.ok) {
        await updateBundleContents(purchaseItem.id, bundlePayload, customerToken, baseUrl);
      }

      return purchaseItem;
    },

    async swapProductInSubscription({ getters }, data) {
      if (!getters.canSave) return;

      const baseUrl = await getters.baseUrl();
      const { customerToken, subscriptionId } = await getters.rechargeCustomerData();
      let response = await swapSubscription(subscriptionId, data.subscription, customerToken, baseUrl);
      if (!response.ok) {
        throw new Error(response.text());
      }

      response = await updateBundleContents(subscriptionId, data.bundle, customerToken, baseUrl);
      if (!response.ok) {
        throw new Error(response.text());
      }
      return response.json();
    },
  },

  mutations: {
    setSubscription(state, payload) {
      state.subscription = payload;
    },
  },
};
