// @ts-check
import { EXPECTED_VIEW_PARAM_VALUE } from '../store/constants';

/**
 * Check if the bundle is published
 * @param {Object} bundleProduct - Bundle product
 * @param {Object} bundleProduct.bundle_settings - Bundle product settings
 * @param {Object} bundleProduct.bundle_settings.layout_settings - Bundle product layout settings
 * @param {Boolean} bundleProduct.bundle_settings.layout_settings.published - Bundle product published status
 * @param {?String} paramView - View param
 * @returns {Boolean} Bundle published status
 */
export function isBundlePublished(bundleProduct, paramView = null) {
  const { layout_settings } = bundleProduct.bundle_settings;

  return layout_settings.published || paramView === EXPECTED_VIEW_PARAM_VALUE;
}
