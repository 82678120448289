// @ts-check

const INCENTIVE_TYPES = {
  TIER_DISCOUNT: 'tiered_discount',
};

export const LINE_ITEM_TYPES = { ONETIME: 'onetime', SUBSCRIPTION: 'subscription' };

export const CHARGE_TYPES = { CHECKOUT: 'checkout', RECURRING: 'recurring' };

const formatTierDiscount = (tierDiscount) => {
  const { tier } = tierDiscount;
  const quantityKey = Object.keys(tier).find((key) => key.includes('condition_quantity'));

  return {
    quantity: {
      condition: quantityKey?.split('_').pop() ?? 'gte',
      value: tier.condition_quantity_gte,
    },
    discountType: tier.discount_type,
    discountValue: tier.discount_value,
  };
};

export const tierDiscountIncentive = (incentive, incentiveType) => {
  return {
    id: incentive.id,
    incentiveType,
    chargeTypes: new Set(incentive.eligible_charge_types),
    lineItemTypes: new Set(incentive.eligible_line_item_types),
    bundleProductId: incentive.external_bundle_product_id.ecommerce,
    lookupKey: incentive.lookup_key,
    name: incentive.lookup_key,
    status: incentive.status,
    tiers: incentive.tiers.map(formatTierDiscount).sort((a, b) => a.quantity.value - b.quantity.value),
  };
};

const getActiveIncentives = (incentives) => {
  return incentives.filter((incentive) => incentive.status === 'published');
};

export const getActiveDiscountIncentives = (incentives) => {
  return getActiveIncentives(incentives).filter(
    (incentive) => incentive.incentiveType === INCENTIVE_TYPES.TIER_DISCOUNT
  );
};
