/* eslint-disable no-console */
import { hasFeatureFlag } from './feature-flag';

export class Logger {
  #name;
  #logRecords = [];
  static #instance;

  constructor(name) {
    this.#name = name;
  }

  static getInstance(name = 'Bundling Widget Diagnostic') {
    if (!this.#instance) {
      this.#instance = new Logger(name);
    }

    return this.#instance;
  }

  #formatMessage(level, message) {
    return [
      `[${level.toUpperCase()}]`,
      this.#name,
      `${new Date().toISOString().replace('T', ' ').split('.')[0]}`,
      message,
    ];
  }

  #log(level, message, group = true) {
    if (!hasFeatureFlag('diagnostic')) return;

    if (group) {
      this.#logRecords.push([level, message]);
      return;
    }

    const logFunctions = {
      info: console.info,
      warning: console.warn,
      error: console.error,
    };

    logFunctions[level](...this.#formatMessage(level, message));
  }

  info(message, group = true) {
    this.#log('info', message, group);
  }

  warning(message, group = true) {
    this.#log('warning', message, group);
  }

  error(message, group = true) {
    this.#log('error', message, group);
  }

  commitLogs(name) {
    if (!hasFeatureFlag('diagnostic')) return;

    console.group(`${this.#name}: ${name}`);

    this.#logRecords.forEach(([level, message]) => {
      this.#log(level, message, false);
    });
    this.#logRecords = [];

    console.groupEnd(name);
  }
}

export default {
  install(app) {
    const logger = Logger.getInstance('Bundling Widget Diagnostic');
    app.config.globalProperties.$logger = logger;
  },
};
