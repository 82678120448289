// @ts-check

/**
 * Format the contents param value from the current URL location
 * @param {String} contentsRaw Raw contents param value. Format: productId:variantId:quantity
 * @returns {Object} Preselected contents
 * @example
 * // returns { 123: { quantity: 1, variantId: 456 }, 789: { quantity: 2, variantId: 101112 } }
 * formatContents('123:456:1,789:101112:2');
 */
const formatContents = (contentsRaw) => {
  const contentData = {};
  contentsRaw.split(',').forEach((content) => {
    const data = content.split(':');
    const productId = Number(data[0]);
    const variantId = Number(data[1]);
    const quantity = Number(data[2]);
    contentData[productId] = { quantity, variantId };
  });

  return contentData;
};

export default {
  getters: {
    /**
     * Get the URLSearchParams object from the current URL location
     * @returns {URLSearchParams} URLSearchParams object
     */
    params() {
      return new URLSearchParams(window.location.search);
    },

    /**
     * Get the view param from the current URL location
     * @returns {?String} View param
     */
    paramsView(state, getters) {
      return getters.params.get('view');
    },

    /**
     * Get the step param from the current URL location to preselect step in multi-step layout
     * @returns {?String} step param
     */
    paramsStep(state, getters) {
      return getters.params.get('step');
    },

    /**
     * Get the variant param value from the current URL location
     * @returns {?Number} Variant id
     */
    paramsVariantId(state, getters) {
      const variantId = getters.params.get('variant');

      if (variantId) return Number(variantId);

      return null;
    },

    /**
     *  Get the contents param value from the current URL location
     * @returns {Object} Preselected contents
     */
    paramsContents(state, getters) {
      const contentsRaw = getters.params.get('contents');

      if (!contentsRaw) {
        return {};
      }

      return formatContents(contentsRaw);
    },
    /**
     * Get the add-ons-contents param value from the current URL location
     * @returns {Object} Preselected Add-ons contents
     */
    paramsAddonsContents(state, getters) {
      const contentsRaw = getters.params.get('add-ons-contents');

      if (!contentsRaw) {
        return {};
      }

      return formatContents(contentsRaw);
    },
    /**
     * Get the cross-sells-contents param value from the current URL location
     * @returns {Object} Preselected Cross-sells contents
     */
    paramsCrossSellsContents(state, getters) {
      const contentsRaw = getters.params.get('cross-sells-contents');

      if (!contentsRaw) {
        return {};
      }

      return formatContents(contentsRaw);
    },
  },
};
