import { Logger } from '../plugins/logger';
import { fetch } from 'whatwg-fetch';

/**
 *
 * @param {Object} config - The bundle data configuration
 * @param {string} [config.bundleDataCorsProxy] - This is the host name for a proxy in local development.
 * @param {string} [config.appProxy] - This is the App Proxy URL, used in pre-stage/pre-prod mostly.
 * @param {Object} [config.presentmentCurrency] -
 * @param {string} [config.presentmentCurrency.currency]
 * @param {string} [config.presentmentCurrency.countryCode]
 * @returns {Promise<Object | null>} The response, either with the Bundle Data or null, if there was any issue.
 */
export default async function loadBundleProduct(config) {
  if (config.presentmentCurrency?.currency && config.presentmentCurrency?.countryCode) {
    return loadBundleDataSubscription(config);
  }
  return loadBundleDataDirectly(config);
}

async function loadBundleDataSubscription(config) {
  const productId = config.product.id;
  const appProxy = config.bundleDataCorsProxy || config.appProxy || '/tools/recurring';

  const payload = new FormData();
  payload.append('form_type', 'localization');
  payload.append('_method', 'put');
  payload.append('return_to', `${appProxy}/bundle-data/${productId}`);
  payload.append('country_code', config.presentmentCurrency.countryCode);

  try {
    const response = await fetch('/localization', {
      headers: 'multipart/form-data',
      method: 'POST',
      body: payload,
    });

    if (!response.ok) {
      return loadBundleDataDirectly(config);
    }

    if (config.i18n) {
      config.i18n.numberFormats.custom.currency.currency = config.presentmentCurrency.currency;
    }

    return response.json();
  } catch (error) {
    return loadBundleDataDirectly(config);
  }
}

async function loadBundleDataDirectly(config) {
  const productId = config.product.id;
  const appProxy = config.bundleDataCorsProxy || config.appProxy || '/tools/recurring';

  try {
    const url = `${appProxy}/bundle-data/${productId}`;
    const response = await fetch(url, {
      headers: {
        accept: 'application/json',
      },
    });

    if (response.ok) {
      try {
        return await response.json();
      } catch (e) {
        Logger.getInstance().error(e);
        return null;
      }
    }

    return null;
  } catch (e) {
    return null;
  }
}
