export const CUSTOM_EVENTS = {
  WIDGET_MOUNTED: 'bundling-widget:ready',
  SHOPIFY_SECTION_LOAD: 'shopify:section:load',
  SUBSCRIPTION_SWAP: 'subscription:swap',
  SUBSCRIPTION_CREATE: 'subscription:create',
  SUBSCRIPTION_UPDATE: 'subscription:update',
  RELOAD: 'Recharge::bundling-widget::reload',
  SAVE_CHANGES: 'Recharge::bundling-widget::save-changes',
};

/**
 * Emits a custom event
 * @param {String} eventName - Custom event action name
 * @param {Object} [data] - Event data
 * @param {String} [data.type] - Event result type. Default: success
 * @param {*} [data.details] - Event details
 * @param {object} [options]
 * @param {boolean} [options.cancelable] - A boolean value indicating whether the event can be cancelled
 * @returns {boolean} - if the event was prevented by the listener
 */
export function emitCustomEvent(eventName, data = {}, options = {}) {
  const event = new CustomEvent(eventName, {
    ...options,
    detail: { type: data.type ?? 'success', data: data.details ?? {} },
  });
  return !document.dispatchEvent(event);
}
