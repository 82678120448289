import { fetch } from 'whatwg-fetch';

import { RetryableFetchError } from './exceptions';

function shouldRetry(isOk, code) {
  return !isOk && RetryableFetchError.RETRYABLE_STATUS_CODES.includes(code);
}

export async function makeRequest(url, options, attempt = 0) {
  if (attempt >= RetryableFetchError.MAX_ATTEMPTS) return null;

  try {
    // This is using "whatwg-fetch" instead of global fetch function to avoid conflicts with merchants that are overwriting
    // the global fetch behavior
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      },
      ...options,
    });

    if (shouldRetry(response.ok, response.status)) {
      return makeRequest(url, options, attempt + 1);
    }

    return response;
  } catch (error) {
    return makeRequest(url, options, attempt + 1);
  }
}
