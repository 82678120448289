// @ts-check
import { textAreEqual } from './text';

/**
 * @typedef {Object} SellingPlanOptions
 * @property {String} name - Selling plan option name.
 * @property {String} value - Selling plan option values.
 *
 * @typedef {Object} SellingPlan
 * @property {String} name
 * @property {Array<SellingPlanOptions>} options
 *
 * @typedef {Object} SellingPlanAllocation
 * @property {String} selling_plan_name - Selling plan name
 * @property {String} selling_plan_frequency_unit - Selling plan frequency and unit in the format "<frequency>-<unit>"
 */

/**
 * Get Recharge plan ids from Shopify selling plan options
 * @param {Array<SellingPlanOptions>} options - Shopify selling plan options
 * @returns {Array<String>} - Recharge plan ids
 */
export function getRechargePlanIdsFromSellingPlan(options) {
  return options.filter((opt) => opt.name === 'Recharge Plan ID').flatMap((opt) => opt.value) ?? [];
}

/**
 * Get frequency and unit from selling plan options
 * @param {Array<SellingPlanOptions>} options - Shopify selling plan options
 * @returns {String}
 */
export function getSellingPlanFrequencyAndUnit(options) {
  const { value } = options.find((opt) => opt.name === 'Order Frequency and Unit') ?? {};
  return value ?? '';
}

/**
 *
 * @param {SellingPlanAllocation} sellingPlanAllocation
 * @param {SellingPlan} targetSellingPlan
 * @returns {Boolean}
 */
export function isSameSellingPlan(sellingPlanAllocation, targetSellingPlan) {
  const { selling_plan_frequency_unit, selling_plan_name } = sellingPlanAllocation;
  const frequencyAndUnit = getSellingPlanFrequencyAndUnit(targetSellingPlan.options);

  const isSameFrequency = selling_plan_frequency_unit === frequencyAndUnit;

  // TODO: Remove validation/comparison by selling plan' name. https://recharge.atlassian.net/browse/BUN-1601
  return (!!selling_plan_frequency_unit && isSameFrequency) || textAreEqual(selling_plan_name, targetSellingPlan.name);
}

/**
 *
 * @param {Array<SellingPlan>} sellingPlans
 * @returns
 */
export function getSellingPlanFrequencies(sellingPlans) {
  return sellingPlans
    .map((sellingPlan) => {
      const frequencyAndUnit = getSellingPlanFrequencyAndUnit(sellingPlan.options);
      if (frequencyAndUnit) {
        return frequencyAndUnit;
      }

      // TODO: Remove validation/comparison by selling plan' name. https://recharge.atlassian.net/browse/BUN-1601
      return sellingPlan.name;
    })
    .filter(Boolean);
}

export const getHighestDiscountSellingPlan = (sellingPlans) =>
  sellingPlans
    // Sort by discount amount from higher to lower
    .sort((a, b) => a.price - b.price)[0];
