export const hasFeatureFlag = (featureFlag) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('enabled')?.split(',').includes(featureFlag);
};

export default {
  install: (app) => {
    // inject a hasFeatureFlag globally
    app.config.globalProperties.$hasFeatureFlag = hasFeatureFlag;
  },
};
