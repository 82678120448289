import { makeRequest } from './request';

const RECHARGE_API_URL_PROD = 'api.rechargeapps.com';
/**
 * Retrieves the API URL for the Recharge.
 *
 * This function returns the base URL for the Recharge API, either from an environment
 * variable (`process.env.RECHARGE_API_BASE_URL`) if set, or a default production URL.
 *
 * @returns {string} The base URL for the Recharge API.
 */
function getApiUrl() {
  return process.env.RECHARGE_API_BASE_URL || RECHARGE_API_URL_PROD;
}

/**
 * Retrieves a temporary token from the API.
 * @returns {Promise<string>} A promise that resolves to the temporary token if the request is successful,
 *                            or an empty string if an error occurs.
 */
async function getTokenFromApi() {
  const { session } = window.rechargePage;
  const url = new URL(`https://${getApiUrl()}/portal_access`);
  url.searchParams.append('shop_url', window.rechargePage.shop);

  try {
    const response = await makeRequest(url.toString(), {
      method: 'GET',
      headers: { 'X-Recharge-Access-Token': session.api_token },
    });
    const { temp_token } = await response.json();
    return temp_token;
  } catch (error) {
    return '';
  }
}

/**
 * Retrieves a customer portal token.
 *
 * This function checks the URL for a 'token' parameter and returns it if found.
 * If not found, it checks if the `rechargePage.session` is available and if not,
 * returns an empty string. If the session is available, it fetches the token
 * from an API.
 *
 * @returns {Promise<string>} A promise that resolves to the customer portal token.
 */
async function getCustomerPortalToken() {
  const params = new URLSearchParams(window.location.search);
  if (params.get('token')) return Promise.resolve(params.get('token'));

  if (!window.rechargePage?.session) return '';

  return await getTokenFromApi();
}

/**
 * Retrieves the customer hash from the current session or URL path.
 *
 * This function attempts to get the customer hash from multiple potential sources
 * in the following order:
 * 1. `window.rechargePage.session.customer_hash` if it exists.
 * 2. The URL path if it starts with '/tools/recurring/customers/'.
 * 3. The URL path if it contains '/portal/'.
 * 4. `window.BoxConfig.customerHash` if it exists.
 *
 * If none of these sources provide a customer hash, an empty string is returned.
 *
 * @returns {string} The customer hash if found, otherwise an empty string.
 */
function getCustomerHash() {
  const path = window.location.pathname;
  if (window.rechargePage?.session) {
    return window.rechargePage.session.customer_hash;
  } else if (path.startsWith('/tools/recurring/customers/')) {
    return path.split('/tools/recurring/customers/')[1].split('/')[0];
  } else if (path.split('/portal/')[1]) {
    return path.split('/portal/')[1].split('/')[0];
  }

  return window.BoxConfig?.customerHash ?? '';
}

/**
 * Fetches the customer portal credentials including a token and customer hash.
 *
 * @returns {Promise<Object>} A promise that resolves to an object containing the token and customer hash.
 * @returns {string} return.token - The customer portal token.
 * @returns {string} return.customerHash - The customer hash.
 */
export async function getCustomerPortalCredentials() {
  const customerHash = getCustomerHash();
  const token = await getCustomerPortalToken();

  return { token, customerHash };
}
