/**
 * Checks if the widget is being used in an embedded theme context.
 * An embedded theme is one that serves ReCharge Customer Portal using an external FE application,
 * Affinity and Landing pages are examples of embedded applications in ReCharge.
 * @returns boolean If the widget is being served in an embedded theme context.
 */
function usedWithinEmbeddedThemeContext() {
  const isCustomerPortal = Boolean(window.ReCharge?.settings?.customer_portal);
  const isLandingPage = Boolean(window.rechargePage?.schema);

  return isCustomerPortal || isLandingPage;
}

export { usedWithinEmbeddedThemeContext };
