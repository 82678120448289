import { usedWithinEmbeddedThemeContext } from './customer-portal';

export const WIDGET_TEMPLATES = {
  CREATE_SUBSCRIPTION: 'create-subscription',
  SWAP_PRODUCT_SUBSCRIPTION: 'swap-product-subscription',
  UPDATE_SUBSCRIPTION: 'subscription',
  PRODUCT: 'product',
};

/**
 * Get the configuration data to create subscription from BoxConfig
 * @returns {Object} Object with product, subscriptionConfig, and template value
 */
function getDataForCreatingSubscription() {
  const { product, subscriptionConfig, template } = window.BoxConfig || {};

  return {
    product: product ?? window.ReCharge?.product,
    subscriptionConfig: subscriptionConfig ?? window.ReCharge?.subscriptionConfig,
    template: template ?? window.ReCharge?.template,
  };
}

/**
 * Takes an initial config value and updates it with the last information from the global BoxConfig
 * @param {Object} config - Initial BoxConfig
 * @returns {Object} BoxConfig updated
 */
export function synchronizeBoxConfig(config) {
  if (!usedWithinEmbeddedThemeContext()) return config;

  const synchronizedConfig = { ...config, ...getDataForCreatingSubscription() };
  return synchronizedConfig;
}

/**
 * Checks if the widget is rendered in the context of a checkout based on the BoxConfig template.
 * @param {string} template - The template to be checked.
 * @returns {boolean} - Returns true if the template is the product template; otherwise, returns false.
 */
export function isRenderedInCheckoutContext(template) {
  // The "product" template is always and only used in the checkout context.
  return template === WIDGET_TEMPLATES.PRODUCT;
}

/**
 * Validate a initial state value
 * @param {object} initialState
 * @param {number} initialState.planId
 * @param {string} initialState.externalVariantId
 * @param {Array<{externalVariantId: string, externalProductId: string, quantity: number}>} initialState.items
 * @returns {boolean} true if the initial state is valid
 */
export const isInitialStateValid = (initialState) => {
  if (!initialState || typeof initialState !== 'object') return false;

  const isValidPlan = !Number.isNaN(Number(initialState.planId)) && Number.isFinite(Number(initialState.planId));
  const isValidVariantId = !!initialState.externalVariantId;
  const areItemsValid = initialState.items.every(
    (value) => !!value.externalVariantId && !!value.externalProductId && Number.isFinite(Number(value.quantity))
  );

  return isValidPlan && isValidVariantId && areItemsValid;
};
