export const discountByPercentage = (total, percent) => total - (percent / 100) * total;

export const discountByFixedAmount = (total, amount) => total - amount;

export const calculatePriceWithDiscount = (discountType, total, amount) => {
  if (discountType === 'percentage') {
    return discountByPercentage(Number(total) || 0, Number(amount) || 0);
  }

  return discountByFixedAmount(Number(total), Number(amount) || 0);
};

export const formatDiscountValue = (discountType, value, currencyFormatter) => {
  const numberValue = Number(value);
  if (discountType === 'percentage') {
    return `${numberValue}%`;
  }

  return currencyFormatter(numberValue, 'currency');
};
