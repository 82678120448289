import { ONETIME_FREQUENCY } from '../constants';
import { calculatePriceWithDiscount } from '../../utils/discounts';
import { getHighestDiscountPlan, getSubscriptionPlans, isOnetimePlan } from '../../utils/plans';

export default {
  getters: {
    bundleFixedPrice(state, getters, rootState) {
      const price = getters.variantActivePrice(rootState.product, getters.activeVariant);

      const compareAtPrice = getters.variantCompareAtPrice(rootState.product, getters.activeVariant);

      return {
        price,
        compareAtPrice,
      };
    },

    orderTotals: (state, getters, rootState) => {
      if (getters.useRechargePlans) {
        return getters.orderTotalWithPlans;
      }
      let totals = {};

      totals.compareAt = getters.variantHighestPrice(rootState.product, getters.activeVariant);

      totals.oneTime = getters.variantOneTimePrice(getters.activeVariant);

      totals.subscription = getters.variantSubscriptionPrice(rootState.product, getters.activeVariant);

      totals.active = getters.variantActivePrice(rootState.product, getters.activeVariant);

      if (getters.isDynamicBundle && getters.selectedNormalizedFrequency) {
        const oneTimePrice = getters.bundleDynamicPriceBySellingPlan(ONETIME_FREQUENCY);

        const subscriptionPlan = getters.isOnetimeSelected
          ? getters.getFirstSubscriptionFrequencyOption?.frequencyAndUnit
          : getters.bundleSellingPlanFrequencyUnit;
        let subscriptionPrice = getters.bundleDynamicPriceBySellingPlan(subscriptionPlan);
        if (subscriptionPrice) {
          // TODO: Remove validation/comparison by selling plan' name. https://recharge.atlassian.net/browse/BUN-1601
          const availableSubscriptionName = getters.bundleSellingPlans[0];
          subscriptionPrice = getters.bundleDynamicPriceBySellingPlan(availableSubscriptionName);
        }

        const currentPrice = getters.hasSubscribeAndSave ? subscriptionPrice : oneTimePrice;

        totals.active = currentPrice.active;
        totals.compareAt = currentPrice.compareAtPrice;
        totals.oneTime = oneTimePrice.oneTime;
        totals.subscription = subscriptionPrice.subscription;
      }

      if (Object.keys(getters.addonsOrderFiltered).length) {
        totals.active += getters.addonsOrderTotals.active;
        totals.compareAt += getters.addonsOrderTotals.compareAt;

        // Calculate price as onetime vs  subscription
        totals.subscription += getters.addonsOrderTotals.subscription;
        totals.oneTime += getters.addonsOrderTotals.oneTime;
      }

      return totals;
    },

    orderTotalWithPlans: (state, getters, rootState) => {
      if (!rootState.selectedPlan) return { compareAt: 0, active: 0 };

      if (getters.isDynamicBundle) {
        const subscriptionPrice = getters.bundleDynamicPriceBySellingPlan();
        return { active: subscriptionPrice.active, compareAt: subscriptionPrice.compareAtPrice };
      }
      const variantFullPrice = getters.getVariantFullPrice(getters.activeVariant.variant);
      let { discountType = 'percentage', discountAmount = 0 } = rootState.selectedPlan;

      /* For cases where Sub & Save and One Time plan are enabled simultaneously,
      we only must use the Sub & Save behavior if the one time plan discount is 0;
      otherwise, we must use the plan discount  */
      if (getters.isSubscribeAndSaveEnabled && isOnetimePlan(rootState.selectedPlan) && !Number(discountAmount)) {
        const variantFullPrice = getters.getVariantFullPrice(getters.activeVariant.variant);
        const subscriptionPlans = getSubscriptionPlans(getters.activeVariant.plans);
        const highestDiscountPlan = getHighestDiscountPlan(subscriptionPlans, variantFullPrice);
        discountType = highestDiscountPlan?.plan?.discountType || discountType;
        discountAmount = highestDiscountPlan?.plan?.discountAmount || discountAmount;
      }

      return {
        compareAt: variantFullPrice,
        active: calculatePriceWithDiscount(discountType, variantFullPrice, discountAmount),
      };
    },

    getVariantFullPrice: (state, getters) => (variant) => {
      let price = variant.selling_plan_allocations?.[0]?.compare_at_price;
      if (price) return price / 100;

      return getters.getVariantOneTimePrice(variant);
    },
  },
};
