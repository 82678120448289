import { createApp } from 'vue';
import { FocusTrap } from 'focus-trap-vue';
import FloatingVue from 'floating-vue';

import { createI18n } from 'vue-i18n';
import App from './App.vue';
import DynamicApp from './DynamicApp.vue';
import logger from './plugins/logger.js';
import hasFeatureFlag from './plugins/feature-flag';
import hasPermissions from './plugins/permissions';

import { createStore } from './store/store.js';
import loadWidgetSettings from './utils/load-widget-settings';
import { usedWithinEmbeddedThemeContext } from './utils/customer-portal';
import { CUSTOM_EVENTS } from './utils/events';

const sentryWorker = {
  captureException(error) {
    const tags = {
      shop: window.location.origin,
      path: window.location.pathname,
    };
    console.error(error); // eslint-disable-line no-console
    if (this.worker) {
      this.worker.postMessage([error, tags]);
    }
  },
};

// Check if the browser supports web workers
if (window.Worker) {
  try {
    sentryWorker.worker = new Worker(new URL('../static/sentry-worker.js', import.meta.url), { type: 'module' });
  } catch (error) {
    console.error('Error creating worker:', error);
  }
}

window.bundleApp = {};
window.RechargeBundle = window.RechargeBundle || {};

/**
 * Validate the current location to know if the widget can be mounted
 * @returns {Boolean} - If the widget should be mounted
 */
function isValidView() {
  return (
    window.location.pathname.indexOf('/portal/') === -1 ||
    window.location.pathname.indexOf('/subscriptions/') > 0 ||
    window.location.pathname.indexOf('/bundles/') > 0 ||
    // Lets mount the widget anywhere only when it's in the Affinity customer portal context
    usedWithinEmbeddedThemeContext()
  );
}

/**
 * @typedef {AppConfig} Bundling app
 * @property {String} targetApp - App component to mount (App or DynamicApp).
 * @property {Object} i18n - i18n configuration.
 * @property {Object} boxConfig - Box configuration based on the global BoxConfig object.
 *
 *
 * Setup the bundling widget app
 * @returns {Promise<AppConfig>} - The Vue configuration
 *
 */
async function setupApp() {
  // Remove existing port node from DOM to avoid duplicated elements
  let portNode = document.getElementById('bundling-port');
  if (portNode && typeof portNode.remove === 'function') {
    portNode.remove();
  }
  // Create element in DOM to host teleported elements like modals
  portNode = document.createElement('div');
  portNode.setAttribute('id', 'bundling-port');
  portNode.classList.add('rb-app', 'rb-port', 'trelative');

  // When in customer portal add an extra class
  if (window.ReCharge?.Prima) {
    portNode.classList.add('rb-cp-prima');
  } else if (window.ReCharge?.Novum) {
    portNode.classList.add('rb-cp-novum');
  } else if (usedWithinEmbeddedThemeContext()) {
    portNode.classList.add('recharge-theme');
  }

  document.body.appendChild(portNode);

  let boxConfig = {};
  if (typeof window.BoxConfig !== 'undefined') {
    boxConfig = window.BoxConfig;
  }

  if (isValidView()) {
    const locale = 'custom';
    let themeSettings = {};
    if (typeof Shopify !== 'undefined') {
      themeSettings = window.Shopify;
    }
    const currency = themeSettings.currency?.active ?? 'USD';
    const numberFormats = {
      custom: {
        currency: {
          currency,
          style: 'currency',
          currencyDisplay: 'symbol',
        },
      },
    };
    const dateTimeFormats = {
      custom: {
        short: {
          month: 'long',
        },
        long: {
          day: 'numeric',
          year: undefined,
          weekday: 'long',
          month: 'long',
        },
      },
    };
    const messages = {
      en: {
        boxContents: 'Contents',
        remainder: {
          add: 'Choose {remainder}',
          addMaximum: 'Choose up to {remainder}',
          addRange: 'Choose {min} - {max}',
          chooseAtLeast: 'Choose at least {remainder}',
          statusAdd: 'Choose {min}',
          statusChooseAtLeast: 'Choose at least {min}',
          statusAddRange: 'Choose {min} - {max}',
        },
        boxItem: {
          add: '+ Add',
          remove: '× Remove',
        },
        addToCart: {
          completed: 'Add to cart',
          add: 'Add {remainder} to continue',
          added: 'Added',
          remove: 'Remove {remainder} to continue',
          updateSelections: 'Update your selections to continue',
          soldOut: 'Sold out',
          error: 'Add to cart failed',
          dynamicRangesAdd: 'Add {min} - {max} to continue',
          dynamicRangesAddAtLeast: 'Add at least {min} to continue',
        },
        goToCart: 'Proceed to cart',
        alert: {
          planChanged: 'Plan changed - update your selections to continue',
          add: 'Choose {remainder} from {collectionTitle} to continue',
          remove: 'Remove {remainder} from {collectionTitle} to continue',
          selectionCount: '{count} selected',
          collectionComplete: '{collectionTitle} selection completed!',
          complete: 'Bundle completed! You can add to cart now',
          maxReached: 'You can order up to {max} of each item',
          dynamicRangesMaxSelectionCount: 'Add up to {max} to continue',
          dynamicRangeChooseAsManyAsYouLike: 'Choose as many as you like',
        },
        frequency: {
          label: 'Frequency',
          subscriptionLabel: 'Subscribe',
          oneTimeLabel: 'Only once',
          option: 'Only once|Every {unit}|Every {count} {unit}',
        },
        month: 'month | months',
        week: 'week | weeks',
        day: 'day | days',
        filters: {
          label: 'Filters',
          selectedCount: '{count} selected',
          apply: 'Apply',
          clear: 'Clear all',
        },
        addOns: {
          title: 'Feel like a little more?',
          description:
            'Add some delicious extras to your plan. We’ll send these with every delivery, until you tell us not to.',
        },
        oneOffExtras: {
          extras: 'One-off extras',
          title: 'Your order’s in! Fancy some one-off treats?',
          description: 'Products you select here will only be added to your first delivery.',
          summary: 'Summary',
          otherItems: 'Regular delivery',
          total: 'Total cost for your first delivery',
          addToCart: 'Add extras to cart',
        },
        multiStep: {
          summaryStep: {
            addons: 'Add-ons',
            total: 'Order total',
          },
        },
        back: 'Go back',
        next: 'Next',
        cancel: 'Cancel',
        subscriptionPage: {
          updateContents: 'Update contents',
          select: 'Select {title} contents',
          save: 'Save',
          saving: 'Saving your changes',
          saved: 'Your changes have been saved!',
          yourNextOrder: 'Your next order: {nextDeliveryDate}',
          missingContentsTitle: 'Select your contents to continue',
          missingContentsDescription:
            'Your next order is {nextDeliveryDate}. Make sure to choose your bundle contents before then.',
        },
        screenReadersOnly: {
          learnMore: 'Learn more about {title}',
          progress: 'Progress',
        },
        order: {
          add: 'Add to order',
        },
        incentives: {
          discountTierTitle: 'Save {discount}',
          discountTierMessage: 'Add {quantity},',
          discountTierSummaryMessage:
            'You earned an {discountMsj} because you added more to your bundle. Earn even more',
          discountTierExtraOff: 'extra {discount} off',
        },
      },
      custom: {},
    };

    // Load widget data from the ReCharge CDN
    const settings = await loadWidgetSettings();

    if (boxConfig.customLocale) {
      let translations = JSON.stringify(boxConfig.customLocale, function (key, value) {
        if (!value) return undefined;
        return value;
      });
      translations = JSON.parse(translations);

      messages.custom = translations;
    }

    // Always merge with the bundle settings
    messages.custom = {
      ...settings?.widget_settings?.bundle_translations,
      ...messages.custom,
    };

    if (boxConfig.numberFormats) {
      numberFormats.custom = boxConfig.numberFormats;
    }
    if (boxConfig.dateTimeFormats) {
      dateTimeFormats.custom = boxConfig.dateTimeFormats;
    }

    const i18n = createI18n({
      datetimeFormats: dateTimeFormats,
      numberFormats,
      locale,
      fallbackLocale: 'en',
      messages, // set locale messages
      fallbackWarn: false,
    });

    let targetApp = App;
    const path = window.location.pathname;
    const isDynamicBundle = !!path.match(/\/portal\/[0-9a-f]+\/bundles\//);

    if (isDynamicBundle) {
      targetApp = DynamicApp;
    }

    return { targetApp, i18n, boxConfig };
  }

  return { targetApp: null, i18n: null, boxConfig: null };
}

/**
 * Mount the Vue app
 * @param {String} nodeSelector - The DOM node selector to mount the app to
 */
let app = null;
async function startApp(nodeSelector) {
  const { targetApp, i18n, boxConfig } = await setupApp();
  if (!isValidView() || !targetApp) return;

  // Unmount existing app to ensure the new app is mounted correctly
  if (app && typeof app.unmount === 'function') {
    app.unmount();
  }

  app = createApp(targetApp, { boxConfig });
  app
    .use(i18n)
    .use(logger)
    .use(hasFeatureFlag)
    .use(hasPermissions)
    .use(createStore(i18n.global, app.config.globalProperties.$logger))
    .use(FloatingVue, { popperClass: 'rb-app' });

  app.component('FocusTrap', FocusTrap);

  const isProd = process.env.NODE_ENV === 'production';
  app.config.errorHandler = function (err) {
    sentryWorker.captureException(err);

    /**
     * We want to propagate the error to let the app crash
     * and be able to catch errors in tests and development time
     */
    if (!isProd) {
      throw err;
    }
  };

  app.mount(nodeSelector);
}

/**
 * Get the DOM node selector to mount the app to
 * @returns {String} - The DOM node selector to mount the app to
 */
function getTargetNode() {
  if (window.BoxConfig?.appendTo) {
    return '#bundling-widget';
  }

  const containerId = window.BoxConfig?.containerId ?? 'bundling-app';
  let targetNodeSelector = document.getElementById(containerId) ? `#${containerId}` : '#rebundle_app';
  return targetNodeSelector;
}

let targetNodeSelector = getTargetNode();
if (window.BoxConfig?.appendTo) {
  setTimeout(function () {
    if (isValidView() && window.BoxConfig.appendTo) {
      const target = document.getElementsByClassName(window.BoxConfig.appendTo)[0];
      const container = document.createElement('div');
      container.setAttribute('id', 'bundling-widget');
      target.insertAdjacentElement('afterbegin', container);
    }
    startApp(targetNodeSelector);
  }, 500);
} else {
  startApp(targetNodeSelector);
}

function reloadWidget(event) {
  targetNodeSelector = getTargetNode();
  if (event.target.querySelector(targetNodeSelector)) {
    startApp(targetNodeSelector);
  }
}

document.addEventListener(CUSTOM_EVENTS.SHOPIFY_SECTION_LOAD, reloadWidget);

document.addEventListener(CUSTOM_EVENTS.RELOAD, reloadWidget);
