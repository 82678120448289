import { DateTime } from 'luxon';
import { parseSubscriptionUnits } from '../utils/frequencies';
import getPriceAsNumber from './../utils/get-price-as-number';
import nextDeliveryDate from './../utils/next-delivery';
import { ONETIME_FREQUENCY, ONETIME_SELLING_PLAN_ID, ONETIME_SELLING_PLAN_NAME } from './constants';
import { WIDGET_TEMPLATES } from '../utils/box-config';
import { PERMISSIONS, hasPermissions } from '../plugins/permissions';
import { usedWithinEmbeddedThemeContext } from '../utils/customer-portal';
import { getActivePlans, getSubscriptionPlans, isOnetimePlan } from '../utils/plans';
import { formatPricesAdjustments } from '../utils/product';
import { Logger } from '../plugins/logger';
import { getSellingPlanFrequencyAndUnit } from '../utils/selling-plan';

export default (i18n) => {
  return {
    getStorePermissions: (state) => {
      return state.permissions ?? {};
    },

    isDynamicBundle: (state, getters) => {
      return getters.productSettings?.priceRule === 'dynamic';
    },

    addonsEnabled: (state, getters) => {
      const setAsEnabled = getters.productSettings.addons?.enabled === false ? false : true;
      return setAsEnabled && getters.addonProducts.length;
    },

    crossSellProducts: (state) => {
      let crossSellProducts = state.product.crossSells?.products;
      // check for length as some times it's an empty string;
      crossSellProducts = crossSellProducts?.length ? crossSellProducts : [];
      const products = crossSellProducts.filter((product) => {
        if (product.requires_selling_plan) {
          Logger.getInstance().warning(
            `Product '${product.title}' has 'requires_selling_plan' set to true. Make sure this can be bought as a one-time purchase.`
          );
        }
        return !product.requires_selling_plan;
      });

      if (!products.length) {
        Logger.getInstance().error(
          "None of the cross sell products had 'requires_selling_plan' set to false. Make sure these can be bought as a one-time purchase."
        );
      }
      const logCommitMessage = `Cross Sells - products filtered by requires_selling_plan==false`;
      Logger.getInstance().commitLogs(logCommitMessage);

      return products;
    },

    displaySingleButton: (state, getters) => {
      const allVariantsItemCount = state.product.variants.map((variant) => {
        return Number(getters.getVariantConfig(variant).itemsCount);
      });

      return getters.maxPerItem === 1 || allVariantsItemCount.every((itemCount) => itemCount === 1);
    },

    availableVariants: (state, getters) => {
      return state.product.variants.filter((variant) => {
        const isVariantDisabled = getters.getVariantConfig(variant).disabled;
        const isSameVariantTitle = state.subscription.subscription?.variant_title === variant.title;
        const isSameVariantId = state.subscription.subscription?.shopify_variant_id === variant.id;

        return !isVariantDisabled || isSameVariantTitle || isSameVariantId;
      });
    },

    defaultVariant: (state, getters) => {
      const defaultVariantId =
        getters.paramsVariantId || getters.productSettings.defaultVariantId || state.product.defaultVariantId;

      return (
        getters.availableVariants.find((variant) => Number(variant.id) === Number(defaultVariantId)) ||
        getters.availableVariants[0]
      );
    },

    savedContents: (state, getters) => {
      if (!state.subscription.subscription) {
        return [];
      }

      let contents = [];

      Object.keys(getters.savedOrder).forEach((key) => {
        let item = getters.savedOrder[key];
        if (getters.activeVariantProducts[item.pId]) {
          contents.push(`${item.qty}x ${getters.activeVariantProducts[item.pId].title}`);
        }
      });

      return contents;
    },

    activeVariantProducts: (state, getters) => {
      let products = {};

      getters.dataSources.forEach((ds) => {
        getters.getDataSourceCollection(ds).products.forEach((p) => {
          products[p.id.toString()] = p;
        });
      });

      return products;
    },

    activeVariant: (state, getters) => {
      const variant =
        state.product.variants.find((variant) => Number(variant.id) === state.activeVariantId) ||
        getters.defaultVariant;

      if (!variant) {
        Logger.getInstance().error(
          'None of the bundle product variants are enabled. Make sure the bundle product has at least one variant set to be visible.',
          false
        );
      }

      return variant;
    },

    getVariantConfig: () => (variant) => {
      return variant.bundleConfig ?? variant.rebundleConfig;
    },

    activeVariantConfig: (state, getters) => {
      return getters.getVariantConfig(getters.activeVariant);
    },

    oneTimePlanAllocation: (state, getters) => {
      let plans = getters.activeVariant.sellingPlanAllocations;

      return {
        price: plans[0] ? plans[0].compareAtPrice || plans[0].price : 0,
        sellingPlan: {
          id: ONETIME_SELLING_PLAN_ID,
          name: i18n.tc('frequency.option', 0),
          options: [],
        },
      };
    },

    sellingPlanAllocations: (state, getters) => {
      let plans = getters.activeVariant.sellingPlanAllocations;

      if (!getters.activeVariant.requiresSellingPlan) {
        plans = [getters.oneTimePlanAllocation, ...plans];
      }

      return plans;
    },

    frequencyUnitSingular: (state) => {
      let unit;
      const { CREATE_SUBSCRIPTION, SWAP_PRODUCT_SUBSCRIPTION } = WIDGET_TEMPLATES;
      if (state.subscription.subscription && Object.keys(state.product.subscriptionData).length === 0) {
        unit = state.subscription.subscription.product.subscription_defaults.order_interval_unit;
      } else if ('shipping_interval_unit_type' in state.product.subscriptionData) {
        unit = state.product.subscriptionData['shipping_interval_unit_type'];
      } else if (
        [CREATE_SUBSCRIPTION, SWAP_PRODUCT_SUBSCRIPTION].includes(state.template) &&
        state.product.subscriptionDefaults
      ) {
        return state.product.subscriptionDefaults.order_interval_unit;
      } else {
        return '';
      }

      const lastChar = unit.slice(-1);

      return lastChar === 's' ? unit.substring(0, unit.length - 1).toLowerCase() : unit.toLowerCase();
    },

    contentsByVariant: (state) => {
      // TODO: Completely deprecate contentsByVariant usage
      return state.product.contentsByVariant;
    },

    dataSources: (state, getters) => {
      let dataSources = getters.activeVariantConfig.dataSources;

      // Add collectionId property to data sources if missing
      if (dataSources[0] && !dataSources[0].collectionId) {
        const id = getters.activeVariant.id.toString();
        dataSources = dataSources.map((ds, index) => {
          // Legacy data structure uses "contentsByVariant"
          const collection = getters.contentsByVariant[id][index];
          ds.collectionId = Number(collection.id);
          return ds;
        });
      }

      // We must filter variant data sources that include a data source that
      // is no longer available in the collections list because merchant
      // deletes or disabled the collection in Shopify
      return dataSources.filter((ds) => !!Object.keys(ds.collection).length);
    },

    getDataSourceCollection: (state, getters) => (dataSource) => {
      const bundleAllCollections = state.product.collections;
      let collection = {};
      if (getters.contentsByVariant) {
        // Legacy data structure uses "contentsByVariant"
        const id = getters.activeVariant.id.toString();
        const index = getters.activeVariantConfig.dataSources.findIndex((ds) => ds.handle === dataSource.handle);
        collection = getters.contentsByVariant[id][index];
      } else if (bundleAllCollections) {
        // New data structure uses "collections"
        collection = bundleAllCollections[dataSource.collectionId];
      }
      return collection ?? { title: '', products: [] };
    },

    allDataSourceRequired: (state, getters) => {
      return (
        getters.dataSources.length > 1 && getters.dataSources.every((d) => d.itemsCount.bounded && d.itemsCount.min > 0)
      );
    },

    boxItemCount: (state, getters) => {
      return Number(getters.activeVariantConfig.itemsCount);
    },

    maxPerItem: (state, getters) => {
      return Number(getters.productSettings.maxPerItem);
    },

    hasItemRestrictions: (state, getters) => {
      return Number(getters.productSettings.maxPerItem) > 0;
    },

    layoutSettings: (state, getters) => {
      const settings = Object.assign({}, getters.productSettings.layoutSettings || {});

      return settings;
    },

    customizationDisabledMessage: (state, getters) => {
      return getters.productSettings.customizationDisabledMessage || "You can't customize your box at the moment.";
    },

    betweenCustomizationWindow: (state, getters) => {
      let customizationWindow = Number(getters.productSettings.customizationWindow);
      if (!state.subscription.subscription) return true;

      if (customizationWindow > 0) {
        if (!state.subscription.subscription.next_charge_scheduled_at) return false;

        let now = DateTime.now();
        const nextChargeDate = getters.nextDeliveryDate;
        const customizationBegins = nextChargeDate.minus({
          days: customizationWindow,
        });

        return now >= customizationBegins;
      } else {
        return true;
      }
    },

    requireUserToSelectContents: (state, getters) => {
      return (
        getters.isCustomizable &&
        getters.betweenCustomizationWindow &&
        (!getters.selectionIsBalanced || getters.savedContents.length === 0)
      );
    },

    nextDeliveryDate: (state) => {
      if (!state.subscription.subscription) {
        return DateTime.now();
      }

      const subscription = state.subscription.subscription;
      let nextDelivery = subscription.next_charge_scheduled_at || DateTime.now();
      if (subscription.is_prepaid) {
        let scheduledDelivery = nextDeliveryDate(subscription.delivery_schedule, subscription);
        if (scheduledDelivery) {
          nextDelivery = scheduledDelivery.date;
        }
      }
      return DateTime.fromISO(nextDelivery);
    },

    productSettings: (state) => {
      let settings = {};
      if (state.product.bundleSettings || state.product.rebundleSettings) {
        settings = state.product.bundleSettings ?? state.product.rebundleSettings;
      }
      return settings;
    },

    isCustomizable: (state, getters) => {
      return getters.productSettings.customizable ?? true;
    },

    isFallbackSelection: (state, getters) => {
      const bundleSelections = getters.subscription.bundle_selections;
      return (
        bundleSelections &&
        bundleSelections.selections &&
        bundleSelections.selections.every((selection) => !selection.collection_id)
      );
    },

    missingVariants: (state) => state.missingVariants,

    selectedContentsByVariantId: (state) => {
      var filteredKeys = Object.keys(state.selectedContents).filter(function (item) {
        return state.selectedContents[item].qty > 0;
      });

      var filteredOrder = {};

      filteredKeys.forEach(function (key) {
        const variantId = state.selectedContents[key].vId;
        filteredOrder[variantId] = state.selectedContents[key];
      });

      return filteredOrder;
    },

    selectedContentsString: (state, getters) => {
      return Object.keys(getters.selectedContentsByVariantId)
        .map(function (item) {
          return `${getters.selectedContentsByVariantId[item].qty}x ${getters.selectedContentsByVariantId[item].title}`;
        })
        .join('\n');
    },

    selectedContentsCount: (state) => {
      return Object.values(state.selectedContents).reduce(function (a, b) {
        return a + Number(b.qty);
      }, 0);
    },

    selectedContentsCountByCollectionId: (state) => {
      let countBySource = {};
      Object.values(state.selectedContents).forEach((item) => {
        if (!countBySource[item.dsId]) {
          countBySource[item.dsId] = 0;
        }
        countBySource[item.dsId] += item.qty;
      });
      return countBySource;
    },

    // the selection is balance when all data sources are between their boundaries and the total is equal to the box item count
    selectionIsBalanced: (state, getters) => {
      let total = 0;

      let withinBoundaries = getters.dataSources.every((dataSource) => {
        let count = getters.selectedContentsCountByCollectionId[dataSource.collectionId] || 0;
        total += count;
        if (dataSource.itemsCount.bounded) {
          return count >= dataSource.itemsCount.min && count <= dataSource.itemsCount.max;
        } else {
          return true;
        }
      });

      return getters.selectionSatisfyNumberOfProducts(total) && withinBoundaries;
    },

    remainder: (state, getters) => {
      if (getters.hasDynamicRanges) {
        return getters.dynamicRangeCountDifference;
      }

      return Number(getters.boxItemCount) - getters.selectedContentsCount;
    },

    // computes if an user can pick elements from any data source
    dataSourceState: (state, getters) => {
      if (!getters.allDataSourceRequired) {
        return [];
      }

      return getters.dataSources.map((dataSource, i) => {
        const collectionId = dataSource.collectionId;
        const count = getters.selectedContentsCountByCollectionId[collectionId] || 0;
        return [
          collectionId,
          count === dataSource.itemsCount.max ? 1 : 0,
          dataSource.itemsCount.max - count, // pending,
          getters.activeVariant.id,
          i,
        ];
      });
    },

    requiredDataSources: (state, getters) => {
      return getters.dataSources
        .filter((dataSource) => {
          return dataSource.itemsCount.bounded && dataSource.itemsCount.min > 0;
        })
        .map((dataSource, i) => {
          let count = getters.selectedContentsCountByCollectionId[dataSource.collectionId] || 0;
          let pending = dataSource.itemsCount.min - count;
          return [pending < 0 ? 0 : pending, getters.activeVariant.id, i];
        });
    },

    canAdd: (state, getters) => (dataSource) => {
      if (getters.hasDynamicRanges) {
        return getters.canAddDynamicBundle(dataSource);
      }

      if (getters.selectionIsBalanced) {
        return false;
      }

      let count = getters.selectedContentsCountByCollectionId[dataSource.collectionId] || 0;
      let requiredPendingCount = getters.remainingRequiredProducts;
      const pending = getters.boxItemCount - getters.selectedContentsCount;

      if (!dataSource.itemsCount.bounded) {
        return pending > 0 && pending > requiredPendingCount;
      }

      if (dataSource.itemsCount.min === 0) {
        return pending - requiredPendingCount > 0 && count < dataSource.itemsCount.max;
      } else if (dataSource.itemsCount.min < dataSource.itemsCount.max) {
        return (
          (count < dataSource.itemsCount.min || pending - requiredPendingCount > 0) && count < dataSource.itemsCount.max
        );
      } else {
        return count < dataSource.itemsCount.max;
      }
    },

    isShopifySubscription: (state) => {
      return !!state.product?.sellingPlanGroups.length;
    },

    isPrepaidSubscription: (state) => {
      return state.subscription.subscription && state.subscription.subscription.is_prepaid;
    },

    useSellingPlans(state, getters) {
      return getters.isShopifySubscription && !getters.subscription;
    },

    rechargeFrequencyToSellingPlan: (state, getters) => {
      if (getters.isShopifySubscription) {
        let map = {};

        state.subscription.subscription.plans.forEach((plan) => {
          const option = plan.subscription_preferences;
          map[`${option.order_interval_frequency}-${option.interval_unit}`.toLocaleLowerCase()] = {
            id: parseInt(plan.external_plan_id),
          };
        });

        return map;
      } else {
        return {};
      }
    },
    // given an active variant and recharge frequency maps it to a Shopify selling plan allocation
    mappedSellingPlanAllocation: (state, getters) => {
      if (
        getters.isShopifySubscription &&
        getters.savedFrequencyIsValidOption &&
        state.selectedFrequency &&
        getters.activeVariant
      ) {
        let frequency = state.selectedFrequency;
        let unit = state.subscription.subscription.order_interval_unit;
        let key = `${frequency}-${unit}`.toLocaleLowerCase();
        let sellingPlan = getters.rechargeFrequencyToSellingPlan[key];
        let sellingPlanAllocation = getters.activeVariant.sellingPlanAllocations.find(({ sellingPlan: allocation }) => {
          return allocation.id === sellingPlan.id;
        });
        return {
          discount_variant_price: sellingPlanAllocation.price / 100,
        };
      } else {
        return null;
      }
    },

    oneTimeAllowed: (state, getters) => {
      if (getters.isShopifySubscription) {
        return !getters.activeVariant.requiresSellingPlan;
      } else {
        return getters.hasOneTime;
      }
    },

    hasOneTime: (state) => {
      let isSubscriptionOnly = state.product.subscriptionData.is_subscription_only || 'false';
      return !state.subscription.subscription && !JSON.parse(isSubscriptionOnly.toLowerCase());
    },

    subscription: (state) => {
      return state.subscription.subscription;
    },

    hasSubscription: (state) => {
      // if subscriptionData is empty then we assume a shopify subscription
      if (state.product.subscriptionData.has_subscription) {
        return JSON.parse(state.product.subscriptionData.has_subscription.toLowerCase());
      } else {
        return false;
      }
    },

    frequencyOptions: (state, getters) => {
      let hasOneTime = getters.hasOneTime;
      let shippingFrequency, frequencyOptions;
      const { CREATE_SUBSCRIPTION, SWAP_PRODUCT_SUBSCRIPTION } = WIDGET_TEMPLATES;

      // This will only be read if there is a subscription and the product is a shopify subscription
      if (getters.isShopifySubscription && !!state.subscription.subscription) {
        frequencyOptions =
          state.subscription.subscription.product.subscription_defaults.order_interval_frequency_options;
      } else if (
        [CREATE_SUBSCRIPTION, SWAP_PRODUCT_SUBSCRIPTION].includes(state.template) &&
        getters.isShopifySubscription
      ) {
        hasOneTime = ['subscription_and_onetime', 'onetime_only'].includes(
          state.product.subscriptionDefaults.storefront_purchase_options
        );
        frequencyOptions = state.product.subscriptionDefaults.order_interval_frequency_options || [];
      } else {
        shippingFrequency = state.product.subscriptionData['shipping_interval_frequency'];
        frequencyOptions = getters.hasSubscription && shippingFrequency ? shippingFrequency.split(',') : [];
      }

      // Ensures the Onetime frequency option won't be available for swap template
      if (hasOneTime && state.template !== SWAP_PRODUCT_SUBSCRIPTION)
        frequencyOptions.unshift(String(ONETIME_SELLING_PLAN_ID));

      // If the saved frequency and unit in the subscription option does not match any of the bundle frequency options
      // It adds the saved frequency and unit to the frequency options
      if (!getters.savedFrequencyIsValidOption) {
        // This statement covers an edge case where the subascription has a different unit than the bundle because
        // the merchant changed bundle configuration
        const { unit, frequency } = getters.subscriptionDeliveryConfiguration;

        frequencyOptions = Array.from(new Set([`${frequency}-${unit}`, ...frequencyOptions]));
      }

      return frequencyOptions;
    },

    plansOptions: (state, getters) => {
      if (!('plans' in getters.activeVariant.variant) || state.subscription?.subscription?.status === 'ONETIME')
        return [];

      // Onetime plan must be available only when the user is creating a new "subscription"
      // and it's in the context of the customer portal
      if (usedWithinEmbeddedThemeContext() && state.template === WIDGET_TEMPLATES.CREATE_SUBSCRIPTION) {
        return getActivePlans(getters.activeVariant.variant.plans);
      }

      return getSubscriptionPlans(getters.activeVariant.variant.plans);
    },

    subscriptionDeliveryConfiguration(state) {
      const subscription = state.subscription.subscription;
      if (subscription) {
        const savedFrequency = subscription?.order_interval_frequency;
        const savedUnit = subscription?.order_interval_unit;
        return { unit: parseSubscriptionUnits(savedUnit), frequency: savedFrequency };
      }

      return {};
    },

    getSellingPlanId: (state) => {
      if (state.selectedPlan?.externalPlanId) {
        return Number(state.selectedPlan.externalPlanId);
      }

      const sellingPlan = state.selectedSellingPlanAllocation?.sellingPlan;
      return state.selectedFrequency == null ? sellingPlan?.id : Number(state.selectedFrequency);
    },

    hasSelectedFrequency: (state, getters) => {
      return !!getters.getSellingPlanId;
    },

    variantCompareAtPrice: (state, getters) => (product, variant) => {
      let oneTimePrice = getters.variantOneTimePrice(variant);
      let compareAtPrice = variant.compareAtPrice ?? variant.compare_at_price;

      if (typeof compareAtPrice === 'string') {
        const locale = i18n.locale;
        const currency = i18n.numberFormats[locale]?.currency.currency ?? 'USD';
        compareAtPrice = getPriceAsNumber(variant.compareAtPrice, currency);
      } else {
        compareAtPrice = compareAtPrice / 100;
      }

      return compareAtPrice || oneTimePrice;
    },

    variantHighestPrice: (state, getters) => (product, variant) => {
      const oneTimePrice = getters.variantOneTimePrice(variant);
      const compareAtPrice = getters.variantCompareAtPrice(product, variant);
      const subscriptionPrice = getters.variantSubscriptionPrice(product, variant);

      return Math.max(oneTimePrice, compareAtPrice, subscriptionPrice);
    },

    getVariantSellingPlanAllocation: (state) => (variant) => {
      const planId = state.selectedSellingPlanAllocation?.sellingPlan?.id;
      const sellingPlanAllocation = variant.sellingPlanAllocations?.find(
        ({ allocation }) => allocation.selling_plan.id === planId
      );

      if (sellingPlanAllocation) return sellingPlanAllocation;

      return variant.sellingPlanAllocations[0];
    },

    variantOneTimePrice: (state, getters) => (variant) => {
      let oneTimePrice = getters.getVariantOneTimePrice(variant);

      if (getters.isShopifySubscription && variant.sellingPlanAllocations?.length) {
        const variantSellingPlanAllocation = getters.getVariantSellingPlanAllocation(variant);
        oneTimePrice = variantSellingPlanAllocation.compareAtPrice || variantSellingPlanAllocation.price;
        oneTimePrice = oneTimePrice / 100;
      }

      return oneTimePrice;
    },

    getVariantOneTimePrice: () => (variant) => {
      let oneTimePrice = variant.price;
      if (typeof oneTimePrice === 'string') {
        // bundle and addon products object contain massaged data, where price is a string
        const locale = i18n.locale;
        const currency = i18n.numberFormats[locale]?.currency.currency ?? 'USD';
        oneTimePrice = getPriceAsNumber(variant.price, currency);
      } else {
        // crossSell products object has Shopify structure, where price is a number
        oneTimePrice = oneTimePrice / 100;
      }

      return oneTimePrice;
    },

    variantSubscriptionPrice: (state, getters) => (product, variant) => {
      let subscriptionPrice;

      let oneTimePrice = getters.variantOneTimePrice(variant);

      if (getters.isShopifySubscription && variant.sellingPlanAllocations?.length) {
        const variantSellingPlan = getters.getVariantSellingPlanAllocation(variant);
        subscriptionPrice = variantSellingPlan.price / 100;
      } else {
        subscriptionPrice =
          Number(getters.discountedVariantData(product, variant.id)?.discount_variant_price) ?? oneTimePrice;
      }

      return subscriptionPrice || oneTimePrice;
    },

    variantActivePrice: (state, getters) => (product, variant) => {
      let oneTimePrice = getters.variantOneTimePrice(variant);
      let subscriptionPrice = getters.variantSubscriptionPrice(product, variant);

      if (getters.getSellingPlanId !== ONETIME_SELLING_PLAN_ID) {
        return subscriptionPrice;
      } else if (usedWithinEmbeddedThemeContext() && getters.isSubscribeAndSaveEnabled) {
        /* 
          When the Subscribe & Save feature is enabled, we must handle onetime prices as subscription price.
          This is only for the UI and it does not affect the global state.
        */
      } else {
        return oneTimePrice;
      }
    },

    selectedSellingPlanFrequencyAndUnit(state, getters) {
      // If there is a Recharge plan selected
      if (state.selectedPlan) {
        const { intervalUnit, orderIntervalFrequency } = state.selectedPlan.subscriptionPreferences;
        const frequency = getters.isShopifySubscription
          ? `${orderIntervalFrequency}-${intervalUnit}`
          : String(orderIntervalFrequency);
        return isOnetimePlan(state.selectedPlan) ? ONETIME_FREQUENCY : frequency;
      }

      if (getters.isShopifySubscription && !getters.subscription) {
        const { frequencyAndUnit } = getters.normalizedFrequencyOptions.find(
          (option) => option.id === state.selectedSellingPlanAllocation?.sellingPlan?.id
        );

        return frequencyAndUnit;
      }

      return state.selectedFrequency;
    },

    // TODO: Remove validation/comparison by selling plan' name. https://recharge.atlassian.net/browse/BUN-1601
    selectedSellingPlanName(state, getters) {
      if (state.selectedPlan) {
        return getters.selectedNormalizedFrequency?.value.sellingPlan.name;
      }

      if (getters.isShopifySubscription && !getters.subscription) {
        const sellingPlan = getters.selectedNormalizedFrequency?.value.sellingPlan;
        const isOneTimeFrequency = sellingPlan?.id === ONETIME_SELLING_PLAN_ID;
        return isOneTimeFrequency ? ONETIME_SELLING_PLAN_NAME : sellingPlan?.name;
      }

      if (getters.selectedNormalizedFrequency === '0') {
        return ONETIME_SELLING_PLAN_NAME;
      }

      return getters.selectedNormalizedFrequency;
    },

    sellingPlanPriceAdjustments(state, getters) {
      if (getters.isShopifySubscription) {
        const sellingPlan = getters.selectedNormalizedFrequency?.value.sellingPlan;
        return new Set(formatPricesAdjustments(sellingPlan.price_adjustments));
      }

      let pricesAdjustments = [];
      const discountValue = state.product.subscriptionData?.discount_percentage;
      if (discountValue) {
        pricesAdjustments = [`percentage-${Number(discountValue) || 0}`];
      }

      return new Set(pricesAdjustments);
    },

    isSubscribeAndSaveEnabled(state, getters) {
      const { SUBSCRIBE_AND_SAVE_DISCOUNT } = PERMISSIONS;
      return hasPermissions([SUBSCRIBE_AND_SAVE_DISCOUNT], getters.getStorePermissions);
    },

    hasSubscribeAndSave(state) {
      if (state.selectedSellingPlanAllocation) {
        return state.selectedSellingPlanAllocation.sellingPlan.id !== 0;
      }

      return Number.parseInt(state.selectedFrequency) > 0;
    },

    normalizedFrequencyOptions(state, getters) {
      if (getters.isShopifySubscription) {
        return getters.sellingPlanAllocations.map((option) => {
          const frequencyAndUnit = getSellingPlanFrequencyAndUnit(option.sellingPlan.options);
          return {
            frequencyAndUnit: frequencyAndUnit || String(option.sellingPlan.id),
            id: option.sellingPlan.id,
            name: option.sellingPlan.name,
            value: option,
            key: option.sellingPlan.id,
          };
        });
      } else if (getters.frequencyOptions.length > 0) {
        return getters.frequencyOptions.map((option) => ({
          frequencyAndUnit: option,
          id: option,
          name: option,
          value: option,
          key: option,
        }));
      }

      return [];
    },

    selectedNormalizedFrequency(state, getters) {
      if (state.selectedPlan) {
        const orderIntervalFrequency = String(state.selectedPlan.subscriptionPreferences.orderIntervalFrequency);
        const { sellingPlanGroup, externalPlanId, title } = state.selectedPlan;
        const fallbackPlan = { name: orderIntervalFrequency === '0' ? title : orderIntervalFrequency };

        return {
          value: {
            sellingPlan: getters.isShopifySubscription ? sellingPlanGroup.selling_plans[externalPlanId] : fallbackPlan,
          },
        };
      }

      if (getters.isShopifySubscription && !getters.subscription) {
        return getters.normalizedFrequencyOptions.find(
          (option) => option.value.sellingPlan?.id === state.selectedSellingPlanAllocation?.sellingPlan?.id
        );
      }

      return state.selectedFrequency;
    },

    selectedQty: (state) => (item) => {
      return state.selectedContents[item.id]?.qty || 0;
    },

    discountedVariantData: () => (product, variantId) => {
      let discountedVariantData = {};

      if (product.subscriptionData && Object.keys(product.subscriptionData).length) {
        const originalToHidden = product.subscriptionData.original_to_hidden_variant_map ?? {};
        discountedVariantData = originalToHidden[variantId] ?? {};
      }

      return discountedVariantData;
    },

    excludedLineItemProperties: (state) => {
      return state?.shopSettings.excludedLineItemProperties ?? [];
    },

    multiStepActiveSteps: (state, getters) => {
      let widgetSteps = [];
      let currentIndex = 1;

      getters.layoutSettings.templateSettings.multiStep.steps.forEach((step) => {
        // Only display variantSelector step if there are multiple variants
        const variantCount = state.product.variants.length;
        if (step.type === 'variantSelector' && variantCount === 1) return false;

        // Only display addons step if there are any addons
        if (step.type === 'addons' && !getters.addonProducts.length) return false;

        // Ignore deprecated step type "cross-sells"
        if (step.type === 'cross-sells') return false;

        if (!step.disable) {
          let currentStep = JSON.parse(JSON.stringify(step));
          currentStep.index = currentIndex.toString();
          widgetSteps.push(currentStep);
          currentIndex++;
        }
      });

      return widgetSteps;
    },

    savedFrequencyIsValidOption: (state) => {
      // Check if the subscription frequency is one of the options configured in Recharge
      const subscription = state.subscription.subscription;

      if (subscription) {
        const subscription_rules = subscription.product.subscription_defaults;
        const product_frequency_value = subscription.order_interval_frequency;
        const product_frequency_unit = subscription.order_interval_unit;

        const subscription_frequency_options = subscription_rules.order_interval_frequency_options;
        const subscription_frequency_unit = subscription_rules.order_interval_unit;

        // Parse the Shopify units to Recharge units to cover all the frequency selections
        const unit = parseSubscriptionUnits(subscription_frequency_unit);
        return product_frequency_unit === unit && subscription_frequency_options.includes(product_frequency_value);
      } else {
        return true;
      }
    },

    shopFeatureFlags: (state) => {
      return state.product.shopFeatureFlags ?? [];
    },

    getImageSrc(width, i) {
      let imageUrl = i ? this.item.images[i] : this.item.featured_image;
      return this.resizeImage(imageUrl, width);
    },

    resizeImage:
      () =>
      (url, width = 700) => {
        if (!url) return null;

        let imgUrl = new URL(url, `${location.protocol}//${location.hostname}`);
        const oldPath = imgUrl.pathname;
        const pathArray = oldPath.split('.');
        const fileExtension = pathArray.pop();
        const baseUrl = pathArray.join('.');
        let newPath = `${baseUrl}_${width}x.${fileExtension}`;

        let newImgUrl = new URL(imgUrl);
        newImgUrl.pathname = newPath;

        return newImgUrl.toString();
      },

    totalSelectionsMatchItemsCount: (state, getters) => (totalItems) => getters.boxItemCount === totalItems,

    selectionSatisfyNumberOfProducts: (state, getters) => (totalItems) => {
      if (getters.hasDynamicRanges) {
        return getters.selectionsWithinVariantRanges(totalItems);
      }

      return getters.totalSelectionsMatchItemsCount(totalItems);
    },

    canAddWithUnlimitedCollection: (state, getters) => {
      const pendingRequiredProductsCount = getters.remainingRequiredProducts;
      if (getters.hasDynamicRanges) {
        // It uses the bounds of the dynamic range to ensure that the dynamic bundle ranges cover the limits of the collection
        const { max } = getters.getDynamicRangesBounds;
        return (
          getters.selectedContentsCount < max && max - getters.selectedContentsCount > pendingRequiredProductsCount
        );
      }

      return getters.remainder > 0 && getters.remainder > pendingRequiredProductsCount;
    },

    selectionIsInCollectionBounds: (state, getters) => (dataSource) => {
      const count = getters.selectedContentsCountByCollectionId[dataSource.collectionId] || 0;
      const { min } = dataSource.itemsCount;
      const { max } = getters.getDynamicRangesBounds;
      /* Calculate the max limit using the max range value, current selection , and remaining required products to ensure that echar collection respect all the collection limits
       *  Example:
       *  Dynamic range: min: 1, max: 3
       *  Collection A: min: 1, max: 3
       *  Collection B: min: 1, max: 3
       *  In this cae the real max limit is 2 because the sum of the min values (required products) is 2 and the max range value is 3.
       *  It'll change according to the user selection
       */
      let maxValue = dataSource.itemsCount.max;
      if (Number.isFinite(max)) {
        maxValue = max + min - (getters.selectedContentsCount + getters.remainingRequiredProducts);
        maxValue = Math.min(maxValue, dataSource.itemsCount.max);
      }

      return count < maxValue;
    },

    canAddWithLimitedCollection: (state, getters) => (dataSource) => {
      const isInCollectionBounds = getters.selectionIsInCollectionBounds(dataSource);
      if (!isInCollectionBounds || !getters.hasDynamicRanges) return isInCollectionBounds;

      const { max } = getters.getDynamicRangesBounds;
      const remaining = max - getters.selectedContentsCount;
      return remaining > 0 && isInCollectionBounds;
    },

    remainingRequiredProducts: (state, getters) => {
      const sumDataSourceProducts = (accumulator, currentValue) => accumulator + currentValue[0];
      return getters.requiredDataSources.reduce(sumDataSourceProducts, 0);
    },

    getVariantBySelectedOptions: (state) => (selectedVariantOptions) => {
      /**
       * This function sort and concat the selected variant options values using "/" and
       * find a product's variant where the variant's title matches with the selection
       *
       * INPUT
       *    Product Example: {
       *      variants: [
       *        {title: 'Red / Small', ...},
       *        {title: 'Green / Small', ....},
       *        ...
       *      ],
       *      options: [
       *        {name: 'Size', values: ['Small'], position: 2}
       *        {name: 'Color', values: ['Red', 'Green'], position: 1}
       *      ],
       *      ...
       *    }
       *
       *    Selected variant options example: {
       *      Size: {position: 2, value: 'Small'},
       *      Color: {position: 1, value: 'Red'}
       *    }
       *
       * OUTPUT
       *    {title: 'Red / Small', ...}
       */
      const variantTitle = Object.keys(selectedVariantOptions)
        .sort((a, b) => selectedVariantOptions[a].position - selectedVariantOptions[b].position)
        .map((key) => selectedVariantOptions[key].value)
        .join(' / ');

      return state.product.variants.find(({ title }) => title === variantTitle);
    },

    showStickyStatusbar: (state, getters) => {
      const settings = getters.layoutSettings;

      return settings.showStickyStatusBar ?? false;
    },

    bundlePriceRule: (state) => state.product.product.bundle_settings.price_rule ?? 'fixed',

    useRechargePlans: (state) => {
      return state.template !== WIDGET_TEMPLATES.PRODUCT;
    },

    isOnetimeSelected: (state) => {
      return (
        state.selectedSellingPlanAllocation?.sellingPlan?.id === ONETIME_SELLING_PLAN_ID ||
        state.selectedFrequency === String(ONETIME_SELLING_PLAN_ID) ||
        (!!state.selectedPlan && isOnetimePlan(state.selectedPlan))
      );
    },

    dynamicFilterProductsByDiscount: (state, getters) => {
      const dynamicFilterProductsByDiscount = getters.productSettings.dynamicFilterProductsByDiscount;
      return dynamicFilterProductsByDiscount && getters.isDynamicBundle;
    },

    selectedPlan: (state, getters) => {
      return state.selectedPlan ?? getters.activeVariant.plans[0];
    },

    dataSourcesByProductVariant: () => (bundleProduct, subscription) => {
      const bundleSelections = subscription.bundle_selections;
      const bundleVariant = bundleProduct.bundle_settings.variants.find(
        (variant) => variant.external_variant_id === String(bundleSelections?.external_variant_id)
      );
      if (!bundleVariant) return {};

      const dataSources = {};
      bundleVariant.option_sources.forEach(({ option_source_id }) => {
        dataSources[option_source_id] = bundleProduct.collections[option_source_id]?.products || [];
      });
      return Object.entries(dataSources).reduce((acc, [collectionId, products]) => {
        products.forEach((product) => {
          product.variants.forEach((variant) => {
            acc[variant.id] = {
              collectionId,
              product,
              variant,
            };
          });
        });

        return acc;
      }, {});
    },

    variantTitleFromInitialState: (state, getters) => {
      const initialVariantId = state.configInitialState?.externalVariantId;
      if (!initialVariantId) return null;

      const variant = getters.availableVariants.find((variant) => variant.id === Number(initialVariantId));
      return variant?.title || null;
    },
  };
};
