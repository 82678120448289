const FREQUENCY_UNITS = {
  day: 'day',
  days: 'day',
  week: 'week',
  weeks: 'week',
  month: 'month',
  months: 'month',
};

/**
 * Parse Shopify subscription units to Recharge units
 * @param {string} unit
 * @returns parsed unit in Recharge format
 */
export const parseSubscriptionUnits = (unit) => FREQUENCY_UNITS[unit.toLowerCase()] ?? unit;
