var __commonJS = (cb, mod) => () => (mod || cb((mod = {exports: {}}).exports, mod), mod.exports);

// lib/generated/generated.js
var require_generated = __commonJS((exports2) => {
  var __create = Object.create;
  var __defProp = Object.defineProperty;
  var __getProtoOf = Object.getPrototypeOf;
  var __hasOwnProp = Object.prototype.hasOwnProperty;
  var __getOwnPropNames = Object.getOwnPropertyNames;
  var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
  var __markAsModule = (target) => __defProp(target, "__esModule", {value: true});
  var __commonJS2 = (cb, mod) => () => (mod || cb((mod = {exports: {}}).exports, mod), mod.exports);
  var __export = (target, all) => {
    for (var name in all)
      __defProp(target, name, {get: all[name], enumerable: true});
  };
  var __reExport = (target, module22, desc) => {
    if (module22 && typeof module22 === "object" || typeof module22 === "function") {
      for (let key of __getOwnPropNames(module22))
        if (!__hasOwnProp.call(target, key) && key !== "default")
          __defProp(target, key, {get: () => module22[key], enumerable: !(desc = __getOwnPropDesc(module22, key)) || desc.enumerable});
    }
    return target;
  };
  var __toModule = (module22) => {
    return __reExport(__markAsModule(__defProp(module22 != null ? __create(__getProtoOf(module22)) : {}, "default", module22 && module22.__esModule && "default" in module22 ? {get: () => module22.default, enumerable: true} : {value: module22, enumerable: true})), module22);
  };
  var require_freeGlobal = __commonJS2((exports3, module22) => {
    var freeGlobal = typeof global == "object" && global && global.Object === Object && global;
    module22.exports = freeGlobal;
  });
  var require_root = __commonJS2((exports3, module22) => {
    var freeGlobal = require_freeGlobal();
    var freeSelf = typeof self == "object" && self && self.Object === Object && self;
    var root = freeGlobal || freeSelf || Function("return this")();
    module22.exports = root;
  });
  var require_Symbol = __commonJS2((exports3, module22) => {
    var root = require_root();
    var Symbol2 = root.Symbol;
    module22.exports = Symbol2;
  });
  var require_getRawTag = __commonJS2((exports3, module22) => {
    var Symbol2 = require_Symbol();
    var objectProto = Object.prototype;
    var hasOwnProperty = objectProto.hasOwnProperty;
    var nativeObjectToString = objectProto.toString;
    var symToStringTag = Symbol2 ? Symbol2.toStringTag : void 0;
    function getRawTag(value) {
      var isOwn = hasOwnProperty.call(value, symToStringTag), tag = value[symToStringTag];
      try {
        value[symToStringTag] = void 0;
        var unmasked = true;
      } catch (e) {
      }
      var result = nativeObjectToString.call(value);
      if (unmasked) {
        if (isOwn) {
          value[symToStringTag] = tag;
        } else {
          delete value[symToStringTag];
        }
      }
      return result;
    }
    module22.exports = getRawTag;
  });
  var require_objectToString = __commonJS2((exports3, module22) => {
    var objectProto = Object.prototype;
    var nativeObjectToString = objectProto.toString;
    function objectToString(value) {
      return nativeObjectToString.call(value);
    }
    module22.exports = objectToString;
  });
  var require_baseGetTag = __commonJS2((exports3, module22) => {
    var Symbol2 = require_Symbol();
    var getRawTag = require_getRawTag();
    var objectToString = require_objectToString();
    var nullTag = "[object Null]";
    var undefinedTag = "[object Undefined]";
    var symToStringTag = Symbol2 ? Symbol2.toStringTag : void 0;
    function baseGetTag(value) {
      if (value == null) {
        return value === void 0 ? undefinedTag : nullTag;
      }
      return symToStringTag && symToStringTag in Object(value) ? getRawTag(value) : objectToString(value);
    }
    module22.exports = baseGetTag;
  });
  var require_isObjectLike = __commonJS2((exports3, module22) => {
    function isObjectLike(value) {
      return value != null && typeof value == "object";
    }
    module22.exports = isObjectLike;
  });
  var require_isNumber = __commonJS2((exports3, module22) => {
    var baseGetTag = require_baseGetTag();
    var isObjectLike = require_isObjectLike();
    var numberTag = "[object Number]";
    function isNumber(value) {
      return typeof value == "number" || isObjectLike(value) && baseGetTag(value) == numberTag;
    }
    module22.exports = isNumber;
  });
  var require_isObject = __commonJS2((exports3, module22) => {
    function isObject(value) {
      var type = typeof value;
      return value != null && (type == "object" || type == "function");
    }
    module22.exports = isObject;
  });
  var require_isFunction = __commonJS2((exports3, module22) => {
    var baseGetTag = require_baseGetTag();
    var isObject = require_isObject();
    var asyncTag = "[object AsyncFunction]";
    var funcTag = "[object Function]";
    var genTag = "[object GeneratorFunction]";
    var proxyTag = "[object Proxy]";
    function isFunction(value) {
      if (!isObject(value)) {
        return false;
      }
      var tag = baseGetTag(value);
      return tag == funcTag || tag == genTag || tag == asyncTag || tag == proxyTag;
    }
    module22.exports = isFunction;
  });
  var require_coreJsData = __commonJS2((exports3, module22) => {
    var root = require_root();
    var coreJsData = root["__core-js_shared__"];
    module22.exports = coreJsData;
  });
  var require_isMasked = __commonJS2((exports3, module22) => {
    var coreJsData = require_coreJsData();
    var maskSrcKey = function() {
      var uid = /[^.]+$/.exec(coreJsData && coreJsData.keys && coreJsData.keys.IE_PROTO || "");
      return uid ? "Symbol(src)_1." + uid : "";
    }();
    function isMasked(func) {
      return !!maskSrcKey && maskSrcKey in func;
    }
    module22.exports = isMasked;
  });
  var require_toSource = __commonJS2((exports3, module22) => {
    var funcProto = Function.prototype;
    var funcToString = funcProto.toString;
    function toSource(func) {
      if (func != null) {
        try {
          return funcToString.call(func);
        } catch (e) {
        }
        try {
          return func + "";
        } catch (e) {
        }
      }
      return "";
    }
    module22.exports = toSource;
  });
  var require_baseIsNative = __commonJS2((exports3, module22) => {
    var isFunction = require_isFunction();
    var isMasked = require_isMasked();
    var isObject = require_isObject();
    var toSource = require_toSource();
    var reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
    var reIsHostCtor = /^\[object .+?Constructor\]$/;
    var funcProto = Function.prototype;
    var objectProto = Object.prototype;
    var funcToString = funcProto.toString;
    var hasOwnProperty = objectProto.hasOwnProperty;
    var reIsNative = RegExp("^" + funcToString.call(hasOwnProperty).replace(reRegExpChar, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$");
    function baseIsNative(value) {
      if (!isObject(value) || isMasked(value)) {
        return false;
      }
      var pattern = isFunction(value) ? reIsNative : reIsHostCtor;
      return pattern.test(toSource(value));
    }
    module22.exports = baseIsNative;
  });
  var require_getValue = __commonJS2((exports3, module22) => {
    function getValue(object, key) {
      return object == null ? void 0 : object[key];
    }
    module22.exports = getValue;
  });
  var require_getNative = __commonJS2((exports3, module22) => {
    var baseIsNative = require_baseIsNative();
    var getValue = require_getValue();
    function getNative(object, key) {
      var value = getValue(object, key);
      return baseIsNative(value) ? value : void 0;
    }
    module22.exports = getNative;
  });
  var require_defineProperty = __commonJS2((exports3, module22) => {
    var getNative = require_getNative();
    var defineProperty = function() {
      try {
        var func = getNative(Object, "defineProperty");
        func({}, "", {});
        return func;
      } catch (e) {
      }
    }();
    module22.exports = defineProperty;
  });
  var require_baseAssignValue = __commonJS2((exports3, module22) => {
    var defineProperty = require_defineProperty();
    function baseAssignValue(object, key, value) {
      if (key == "__proto__" && defineProperty) {
        defineProperty(object, key, {
          configurable: true,
          enumerable: true,
          value,
          writable: true
        });
      } else {
        object[key] = value;
      }
    }
    module22.exports = baseAssignValue;
  });
  var require_eq = __commonJS2((exports3, module22) => {
    function eq(value, other) {
      return value === other || value !== value && other !== other;
    }
    module22.exports = eq;
  });
  var require_assignValue = __commonJS2((exports3, module22) => {
    var baseAssignValue = require_baseAssignValue();
    var eq = require_eq();
    var objectProto = Object.prototype;
    var hasOwnProperty = objectProto.hasOwnProperty;
    function assignValue(object, key, value) {
      var objValue = object[key];
      if (!(hasOwnProperty.call(object, key) && eq(objValue, value)) || value === void 0 && !(key in object)) {
        baseAssignValue(object, key, value);
      }
    }
    module22.exports = assignValue;
  });
  var require_copyObject = __commonJS2((exports3, module22) => {
    var assignValue = require_assignValue();
    var baseAssignValue = require_baseAssignValue();
    function copyObject(source, props, object, customizer) {
      var isNew = !object;
      object || (object = {});
      var index = -1, length = props.length;
      while (++index < length) {
        var key = props[index];
        var newValue = customizer ? customizer(object[key], source[key], key, object, source) : void 0;
        if (newValue === void 0) {
          newValue = source[key];
        }
        if (isNew) {
          baseAssignValue(object, key, newValue);
        } else {
          assignValue(object, key, newValue);
        }
      }
      return object;
    }
    module22.exports = copyObject;
  });
  var require_identity = __commonJS2((exports3, module22) => {
    function identity(value) {
      return value;
    }
    module22.exports = identity;
  });
  var require_apply = __commonJS2((exports3, module22) => {
    function apply(func, thisArg, args) {
      switch (args.length) {
        case 0:
          return func.call(thisArg);
        case 1:
          return func.call(thisArg, args[0]);
        case 2:
          return func.call(thisArg, args[0], args[1]);
        case 3:
          return func.call(thisArg, args[0], args[1], args[2]);
      }
      return func.apply(thisArg, args);
    }
    module22.exports = apply;
  });
  var require_overRest = __commonJS2((exports3, module22) => {
    var apply = require_apply();
    var nativeMax = Math.max;
    function overRest(func, start, transform) {
      start = nativeMax(start === void 0 ? func.length - 1 : start, 0);
      return function() {
        var args = arguments, index = -1, length = nativeMax(args.length - start, 0), array = Array(length);
        while (++index < length) {
          array[index] = args[start + index];
        }
        index = -1;
        var otherArgs = Array(start + 1);
        while (++index < start) {
          otherArgs[index] = args[index];
        }
        otherArgs[start] = transform(array);
        return apply(func, this, otherArgs);
      };
    }
    module22.exports = overRest;
  });
  var require_constant = __commonJS2((exports3, module22) => {
    function constant(value) {
      return function() {
        return value;
      };
    }
    module22.exports = constant;
  });
  var require_baseSetToString = __commonJS2((exports3, module22) => {
    var constant = require_constant();
    var defineProperty = require_defineProperty();
    var identity = require_identity();
    var baseSetToString = !defineProperty ? identity : function(func, string) {
      return defineProperty(func, "toString", {
        configurable: true,
        enumerable: false,
        value: constant(string),
        writable: true
      });
    };
    module22.exports = baseSetToString;
  });
  var require_shortOut = __commonJS2((exports3, module22) => {
    var HOT_COUNT = 800;
    var HOT_SPAN = 16;
    var nativeNow = Date.now;
    function shortOut(func) {
      var count = 0, lastCalled = 0;
      return function() {
        var stamp = nativeNow(), remaining = HOT_SPAN - (stamp - lastCalled);
        lastCalled = stamp;
        if (remaining > 0) {
          if (++count >= HOT_COUNT) {
            return arguments[0];
          }
        } else {
          count = 0;
        }
        return func.apply(void 0, arguments);
      };
    }
    module22.exports = shortOut;
  });
  var require_setToString = __commonJS2((exports3, module22) => {
    var baseSetToString = require_baseSetToString();
    var shortOut = require_shortOut();
    var setToString = shortOut(baseSetToString);
    module22.exports = setToString;
  });
  var require_baseRest = __commonJS2((exports3, module22) => {
    var identity = require_identity();
    var overRest = require_overRest();
    var setToString = require_setToString();
    function baseRest(func, start) {
      return setToString(overRest(func, start, identity), func + "");
    }
    module22.exports = baseRest;
  });
  var require_isLength = __commonJS2((exports3, module22) => {
    var MAX_SAFE_INTEGER = 9007199254740991;
    function isLength(value) {
      return typeof value == "number" && value > -1 && value % 1 == 0 && value <= MAX_SAFE_INTEGER;
    }
    module22.exports = isLength;
  });
  var require_isArrayLike = __commonJS2((exports3, module22) => {
    var isFunction = require_isFunction();
    var isLength = require_isLength();
    function isArrayLike(value) {
      return value != null && isLength(value.length) && !isFunction(value);
    }
    module22.exports = isArrayLike;
  });
  var require_isIndex = __commonJS2((exports3, module22) => {
    var MAX_SAFE_INTEGER = 9007199254740991;
    var reIsUint = /^(?:0|[1-9]\d*)$/;
    function isIndex(value, length) {
      var type = typeof value;
      length = length == null ? MAX_SAFE_INTEGER : length;
      return !!length && (type == "number" || type != "symbol" && reIsUint.test(value)) && (value > -1 && value % 1 == 0 && value < length);
    }
    module22.exports = isIndex;
  });
  var require_isIterateeCall = __commonJS2((exports3, module22) => {
    var eq = require_eq();
    var isArrayLike = require_isArrayLike();
    var isIndex = require_isIndex();
    var isObject = require_isObject();
    function isIterateeCall(value, index, object) {
      if (!isObject(object)) {
        return false;
      }
      var type = typeof index;
      if (type == "number" ? isArrayLike(object) && isIndex(index, object.length) : type == "string" && index in object) {
        return eq(object[index], value);
      }
      return false;
    }
    module22.exports = isIterateeCall;
  });
  var require_createAssigner = __commonJS2((exports3, module22) => {
    var baseRest = require_baseRest();
    var isIterateeCall = require_isIterateeCall();
    function createAssigner(assigner) {
      return baseRest(function(object, sources) {
        var index = -1, length = sources.length, customizer = length > 1 ? sources[length - 1] : void 0, guard = length > 2 ? sources[2] : void 0;
        customizer = assigner.length > 3 && typeof customizer == "function" ? (length--, customizer) : void 0;
        if (guard && isIterateeCall(sources[0], sources[1], guard)) {
          customizer = length < 3 ? void 0 : customizer;
          length = 1;
        }
        object = Object(object);
        while (++index < length) {
          var source = sources[index];
          if (source) {
            assigner(object, source, index, customizer);
          }
        }
        return object;
      });
    }
    module22.exports = createAssigner;
  });
  var require_baseTimes = __commonJS2((exports3, module22) => {
    function baseTimes(n, iteratee) {
      var index = -1, result = Array(n);
      while (++index < n) {
        result[index] = iteratee(index);
      }
      return result;
    }
    module22.exports = baseTimes;
  });
  var require_baseIsArguments = __commonJS2((exports3, module22) => {
    var baseGetTag = require_baseGetTag();
    var isObjectLike = require_isObjectLike();
    var argsTag = "[object Arguments]";
    function baseIsArguments(value) {
      return isObjectLike(value) && baseGetTag(value) == argsTag;
    }
    module22.exports = baseIsArguments;
  });
  var require_isArguments = __commonJS2((exports3, module22) => {
    var baseIsArguments = require_baseIsArguments();
    var isObjectLike = require_isObjectLike();
    var objectProto = Object.prototype;
    var hasOwnProperty = objectProto.hasOwnProperty;
    var propertyIsEnumerable = objectProto.propertyIsEnumerable;
    var isArguments = baseIsArguments(function() {
      return arguments;
    }()) ? baseIsArguments : function(value) {
      return isObjectLike(value) && hasOwnProperty.call(value, "callee") && !propertyIsEnumerable.call(value, "callee");
    };
    module22.exports = isArguments;
  });
  var require_isArray = __commonJS2((exports3, module22) => {
    var isArray = Array.isArray;
    module22.exports = isArray;
  });
  var require_stubFalse = __commonJS2((exports3, module22) => {
    function stubFalse() {
      return false;
    }
    module22.exports = stubFalse;
  });
  var require_isBuffer = __commonJS2((exports3, module22) => {
    var root = require_root();
    var stubFalse = require_stubFalse();
    var freeExports = typeof exports3 == "object" && exports3 && !exports3.nodeType && exports3;
    var freeModule = freeExports && typeof module22 == "object" && module22 && !module22.nodeType && module22;
    var moduleExports = freeModule && freeModule.exports === freeExports;
    var Buffer2 = moduleExports ? root.Buffer : void 0;
    var nativeIsBuffer = Buffer2 ? Buffer2.isBuffer : void 0;
    var isBuffer = nativeIsBuffer || stubFalse;
    module22.exports = isBuffer;
  });
  var require_baseIsTypedArray = __commonJS2((exports3, module22) => {
    var baseGetTag = require_baseGetTag();
    var isLength = require_isLength();
    var isObjectLike = require_isObjectLike();
    var argsTag = "[object Arguments]";
    var arrayTag = "[object Array]";
    var boolTag = "[object Boolean]";
    var dateTag = "[object Date]";
    var errorTag = "[object Error]";
    var funcTag = "[object Function]";
    var mapTag = "[object Map]";
    var numberTag = "[object Number]";
    var objectTag = "[object Object]";
    var regexpTag = "[object RegExp]";
    var setTag = "[object Set]";
    var stringTag = "[object String]";
    var weakMapTag = "[object WeakMap]";
    var arrayBufferTag = "[object ArrayBuffer]";
    var dataViewTag = "[object DataView]";
    var float32Tag = "[object Float32Array]";
    var float64Tag = "[object Float64Array]";
    var int8Tag = "[object Int8Array]";
    var int16Tag = "[object Int16Array]";
    var int32Tag = "[object Int32Array]";
    var uint8Tag = "[object Uint8Array]";
    var uint8ClampedTag = "[object Uint8ClampedArray]";
    var uint16Tag = "[object Uint16Array]";
    var uint32Tag = "[object Uint32Array]";
    var typedArrayTags = {};
    typedArrayTags[float32Tag] = typedArrayTags[float64Tag] = typedArrayTags[int8Tag] = typedArrayTags[int16Tag] = typedArrayTags[int32Tag] = typedArrayTags[uint8Tag] = typedArrayTags[uint8ClampedTag] = typedArrayTags[uint16Tag] = typedArrayTags[uint32Tag] = true;
    typedArrayTags[argsTag] = typedArrayTags[arrayTag] = typedArrayTags[arrayBufferTag] = typedArrayTags[boolTag] = typedArrayTags[dataViewTag] = typedArrayTags[dateTag] = typedArrayTags[errorTag] = typedArrayTags[funcTag] = typedArrayTags[mapTag] = typedArrayTags[numberTag] = typedArrayTags[objectTag] = typedArrayTags[regexpTag] = typedArrayTags[setTag] = typedArrayTags[stringTag] = typedArrayTags[weakMapTag] = false;
    function baseIsTypedArray(value) {
      return isObjectLike(value) && isLength(value.length) && !!typedArrayTags[baseGetTag(value)];
    }
    module22.exports = baseIsTypedArray;
  });
  var require_baseUnary = __commonJS2((exports3, module22) => {
    function baseUnary(func) {
      return function(value) {
        return func(value);
      };
    }
    module22.exports = baseUnary;
  });
  var require_nodeUtil = __commonJS2((exports3, module22) => {
    var freeGlobal = require_freeGlobal();
    var freeExports = typeof exports3 == "object" && exports3 && !exports3.nodeType && exports3;
    var freeModule = freeExports && typeof module22 == "object" && module22 && !module22.nodeType && module22;
    var moduleExports = freeModule && freeModule.exports === freeExports;
    var freeProcess = moduleExports && freeGlobal.process;
    var nodeUtil = function() {
      try {
        var types2 = freeModule && freeModule.require && freeModule.require("util").types;
        if (types2) {
          return types2;
        }
        return freeProcess && freeProcess.binding && freeProcess.binding("util");
      } catch (e) {
      }
    }();
    module22.exports = nodeUtil;
  });
  var require_isTypedArray = __commonJS2((exports3, module22) => {
    var baseIsTypedArray = require_baseIsTypedArray();
    var baseUnary = require_baseUnary();
    var nodeUtil = require_nodeUtil();
    var nodeIsTypedArray = nodeUtil && nodeUtil.isTypedArray;
    var isTypedArray = nodeIsTypedArray ? baseUnary(nodeIsTypedArray) : baseIsTypedArray;
    module22.exports = isTypedArray;
  });
  var require_arrayLikeKeys = __commonJS2((exports3, module22) => {
    var baseTimes = require_baseTimes();
    var isArguments = require_isArguments();
    var isArray = require_isArray();
    var isBuffer = require_isBuffer();
    var isIndex = require_isIndex();
    var isTypedArray = require_isTypedArray();
    var objectProto = Object.prototype;
    var hasOwnProperty = objectProto.hasOwnProperty;
    function arrayLikeKeys(value, inherited) {
      var isArr = isArray(value), isArg = !isArr && isArguments(value), isBuff = !isArr && !isArg && isBuffer(value), isType = !isArr && !isArg && !isBuff && isTypedArray(value), skipIndexes = isArr || isArg || isBuff || isType, result = skipIndexes ? baseTimes(value.length, String) : [], length = result.length;
      for (var key in value) {
        if ((inherited || hasOwnProperty.call(value, key)) && !(skipIndexes && (key == "length" || isBuff && (key == "offset" || key == "parent") || isType && (key == "buffer" || key == "byteLength" || key == "byteOffset") || isIndex(key, length)))) {
          result.push(key);
        }
      }
      return result;
    }
    module22.exports = arrayLikeKeys;
  });
  var require_isPrototype = __commonJS2((exports3, module22) => {
    var objectProto = Object.prototype;
    function isPrototype(value) {
      var Ctor = value && value.constructor, proto = typeof Ctor == "function" && Ctor.prototype || objectProto;
      return value === proto;
    }
    module22.exports = isPrototype;
  });
  var require_nativeKeysIn = __commonJS2((exports3, module22) => {
    function nativeKeysIn(object) {
      var result = [];
      if (object != null) {
        for (var key in Object(object)) {
          result.push(key);
        }
      }
      return result;
    }
    module22.exports = nativeKeysIn;
  });
  var require_baseKeysIn = __commonJS2((exports3, module22) => {
    var isObject = require_isObject();
    var isPrototype = require_isPrototype();
    var nativeKeysIn = require_nativeKeysIn();
    var objectProto = Object.prototype;
    var hasOwnProperty = objectProto.hasOwnProperty;
    function baseKeysIn(object) {
      if (!isObject(object)) {
        return nativeKeysIn(object);
      }
      var isProto = isPrototype(object), result = [];
      for (var key in object) {
        if (!(key == "constructor" && (isProto || !hasOwnProperty.call(object, key)))) {
          result.push(key);
        }
      }
      return result;
    }
    module22.exports = baseKeysIn;
  });
  var require_keysIn = __commonJS2((exports3, module22) => {
    var arrayLikeKeys = require_arrayLikeKeys();
    var baseKeysIn = require_baseKeysIn();
    var isArrayLike = require_isArrayLike();
    function keysIn(object) {
      return isArrayLike(object) ? arrayLikeKeys(object, true) : baseKeysIn(object);
    }
    module22.exports = keysIn;
  });
  var require_assignIn = __commonJS2((exports3, module22) => {
    var copyObject = require_copyObject();
    var createAssigner = require_createAssigner();
    var keysIn = require_keysIn();
    var assignIn = createAssigner(function(object, source) {
      copyObject(source, keysIn(source), object);
    });
    module22.exports = assignIn;
  });
  var require_extend = __commonJS2((exports3, module22) => {
    module22.exports = require_assignIn();
  });
  var require_arrayEvery = __commonJS2((exports3, module22) => {
    function arrayEvery(array, predicate) {
      var index = -1, length = array == null ? 0 : array.length;
      while (++index < length) {
        if (!predicate(array[index], index, array)) {
          return false;
        }
      }
      return true;
    }
    module22.exports = arrayEvery;
  });
  var require_createBaseFor = __commonJS2((exports3, module22) => {
    function createBaseFor(fromRight) {
      return function(object, iteratee, keysFunc) {
        var index = -1, iterable = Object(object), props = keysFunc(object), length = props.length;
        while (length--) {
          var key = props[fromRight ? length : ++index];
          if (iteratee(iterable[key], key, iterable) === false) {
            break;
          }
        }
        return object;
      };
    }
    module22.exports = createBaseFor;
  });
  var require_baseFor = __commonJS2((exports3, module22) => {
    var createBaseFor = require_createBaseFor();
    var baseFor = createBaseFor();
    module22.exports = baseFor;
  });
  var require_overArg = __commonJS2((exports3, module22) => {
    function overArg(func, transform) {
      return function(arg) {
        return func(transform(arg));
      };
    }
    module22.exports = overArg;
  });
  var require_nativeKeys = __commonJS2((exports3, module22) => {
    var overArg = require_overArg();
    var nativeKeys = overArg(Object.keys, Object);
    module22.exports = nativeKeys;
  });
  var require_baseKeys = __commonJS2((exports3, module22) => {
    var isPrototype = require_isPrototype();
    var nativeKeys = require_nativeKeys();
    var objectProto = Object.prototype;
    var hasOwnProperty = objectProto.hasOwnProperty;
    function baseKeys(object) {
      if (!isPrototype(object)) {
        return nativeKeys(object);
      }
      var result = [];
      for (var key in Object(object)) {
        if (hasOwnProperty.call(object, key) && key != "constructor") {
          result.push(key);
        }
      }
      return result;
    }
    module22.exports = baseKeys;
  });
  var require_keys = __commonJS2((exports3, module22) => {
    var arrayLikeKeys = require_arrayLikeKeys();
    var baseKeys = require_baseKeys();
    var isArrayLike = require_isArrayLike();
    function keys(object) {
      return isArrayLike(object) ? arrayLikeKeys(object) : baseKeys(object);
    }
    module22.exports = keys;
  });
  var require_baseForOwn = __commonJS2((exports3, module22) => {
    var baseFor = require_baseFor();
    var keys = require_keys();
    function baseForOwn(object, iteratee) {
      return object && baseFor(object, iteratee, keys);
    }
    module22.exports = baseForOwn;
  });
  var require_createBaseEach = __commonJS2((exports3, module22) => {
    var isArrayLike = require_isArrayLike();
    function createBaseEach(eachFunc, fromRight) {
      return function(collection, iteratee) {
        if (collection == null) {
          return collection;
        }
        if (!isArrayLike(collection)) {
          return eachFunc(collection, iteratee);
        }
        var length = collection.length, index = fromRight ? length : -1, iterable = Object(collection);
        while (fromRight ? index-- : ++index < length) {
          if (iteratee(iterable[index], index, iterable) === false) {
            break;
          }
        }
        return collection;
      };
    }
    module22.exports = createBaseEach;
  });
  var require_baseEach = __commonJS2((exports3, module22) => {
    var baseForOwn = require_baseForOwn();
    var createBaseEach = require_createBaseEach();
    var baseEach = createBaseEach(baseForOwn);
    module22.exports = baseEach;
  });
  var require_baseEvery = __commonJS2((exports3, module22) => {
    var baseEach = require_baseEach();
    function baseEvery(collection, predicate) {
      var result = true;
      baseEach(collection, function(value, index, collection2) {
        result = !!predicate(value, index, collection2);
        return result;
      });
      return result;
    }
    module22.exports = baseEvery;
  });
  var require_listCacheClear = __commonJS2((exports3, module22) => {
    function listCacheClear() {
      this.__data__ = [];
      this.size = 0;
    }
    module22.exports = listCacheClear;
  });
  var require_assocIndexOf = __commonJS2((exports3, module22) => {
    var eq = require_eq();
    function assocIndexOf(array, key) {
      var length = array.length;
      while (length--) {
        if (eq(array[length][0], key)) {
          return length;
        }
      }
      return -1;
    }
    module22.exports = assocIndexOf;
  });
  var require_listCacheDelete = __commonJS2((exports3, module22) => {
    var assocIndexOf = require_assocIndexOf();
    var arrayProto = Array.prototype;
    var splice = arrayProto.splice;
    function listCacheDelete(key) {
      var data = this.__data__, index = assocIndexOf(data, key);
      if (index < 0) {
        return false;
      }
      var lastIndex = data.length - 1;
      if (index == lastIndex) {
        data.pop();
      } else {
        splice.call(data, index, 1);
      }
      --this.size;
      return true;
    }
    module22.exports = listCacheDelete;
  });
  var require_listCacheGet = __commonJS2((exports3, module22) => {
    var assocIndexOf = require_assocIndexOf();
    function listCacheGet(key) {
      var data = this.__data__, index = assocIndexOf(data, key);
      return index < 0 ? void 0 : data[index][1];
    }
    module22.exports = listCacheGet;
  });
  var require_listCacheHas = __commonJS2((exports3, module22) => {
    var assocIndexOf = require_assocIndexOf();
    function listCacheHas(key) {
      return assocIndexOf(this.__data__, key) > -1;
    }
    module22.exports = listCacheHas;
  });
  var require_listCacheSet = __commonJS2((exports3, module22) => {
    var assocIndexOf = require_assocIndexOf();
    function listCacheSet(key, value) {
      var data = this.__data__, index = assocIndexOf(data, key);
      if (index < 0) {
        ++this.size;
        data.push([key, value]);
      } else {
        data[index][1] = value;
      }
      return this;
    }
    module22.exports = listCacheSet;
  });
  var require_ListCache = __commonJS2((exports3, module22) => {
    var listCacheClear = require_listCacheClear();
    var listCacheDelete = require_listCacheDelete();
    var listCacheGet = require_listCacheGet();
    var listCacheHas = require_listCacheHas();
    var listCacheSet = require_listCacheSet();
    function ListCache(entries) {
      var index = -1, length = entries == null ? 0 : entries.length;
      this.clear();
      while (++index < length) {
        var entry = entries[index];
        this.set(entry[0], entry[1]);
      }
    }
    ListCache.prototype.clear = listCacheClear;
    ListCache.prototype["delete"] = listCacheDelete;
    ListCache.prototype.get = listCacheGet;
    ListCache.prototype.has = listCacheHas;
    ListCache.prototype.set = listCacheSet;
    module22.exports = ListCache;
  });
  var require_stackClear = __commonJS2((exports3, module22) => {
    var ListCache = require_ListCache();
    function stackClear() {
      this.__data__ = new ListCache();
      this.size = 0;
    }
    module22.exports = stackClear;
  });
  var require_stackDelete = __commonJS2((exports3, module22) => {
    function stackDelete(key) {
      var data = this.__data__, result = data["delete"](key);
      this.size = data.size;
      return result;
    }
    module22.exports = stackDelete;
  });
  var require_stackGet = __commonJS2((exports3, module22) => {
    function stackGet(key) {
      return this.__data__.get(key);
    }
    module22.exports = stackGet;
  });
  var require_stackHas = __commonJS2((exports3, module22) => {
    function stackHas(key) {
      return this.__data__.has(key);
    }
    module22.exports = stackHas;
  });
  var require_Map = __commonJS2((exports3, module22) => {
    var getNative = require_getNative();
    var root = require_root();
    var Map2 = getNative(root, "Map");
    module22.exports = Map2;
  });
  var require_nativeCreate = __commonJS2((exports3, module22) => {
    var getNative = require_getNative();
    var nativeCreate = getNative(Object, "create");
    module22.exports = nativeCreate;
  });
  var require_hashClear = __commonJS2((exports3, module22) => {
    var nativeCreate = require_nativeCreate();
    function hashClear() {
      this.__data__ = nativeCreate ? nativeCreate(null) : {};
      this.size = 0;
    }
    module22.exports = hashClear;
  });
  var require_hashDelete = __commonJS2((exports3, module22) => {
    function hashDelete(key) {
      var result = this.has(key) && delete this.__data__[key];
      this.size -= result ? 1 : 0;
      return result;
    }
    module22.exports = hashDelete;
  });
  var require_hashGet = __commonJS2((exports3, module22) => {
    var nativeCreate = require_nativeCreate();
    var HASH_UNDEFINED = "__lodash_hash_undefined__";
    var objectProto = Object.prototype;
    var hasOwnProperty = objectProto.hasOwnProperty;
    function hashGet(key) {
      var data = this.__data__;
      if (nativeCreate) {
        var result = data[key];
        return result === HASH_UNDEFINED ? void 0 : result;
      }
      return hasOwnProperty.call(data, key) ? data[key] : void 0;
    }
    module22.exports = hashGet;
  });
  var require_hashHas = __commonJS2((exports3, module22) => {
    var nativeCreate = require_nativeCreate();
    var objectProto = Object.prototype;
    var hasOwnProperty = objectProto.hasOwnProperty;
    function hashHas(key) {
      var data = this.__data__;
      return nativeCreate ? data[key] !== void 0 : hasOwnProperty.call(data, key);
    }
    module22.exports = hashHas;
  });
  var require_hashSet = __commonJS2((exports3, module22) => {
    var nativeCreate = require_nativeCreate();
    var HASH_UNDEFINED = "__lodash_hash_undefined__";
    function hashSet(key, value) {
      var data = this.__data__;
      this.size += this.has(key) ? 0 : 1;
      data[key] = nativeCreate && value === void 0 ? HASH_UNDEFINED : value;
      return this;
    }
    module22.exports = hashSet;
  });
  var require_Hash = __commonJS2((exports3, module22) => {
    var hashClear = require_hashClear();
    var hashDelete = require_hashDelete();
    var hashGet = require_hashGet();
    var hashHas = require_hashHas();
    var hashSet = require_hashSet();
    function Hash(entries) {
      var index = -1, length = entries == null ? 0 : entries.length;
      this.clear();
      while (++index < length) {
        var entry = entries[index];
        this.set(entry[0], entry[1]);
      }
    }
    Hash.prototype.clear = hashClear;
    Hash.prototype["delete"] = hashDelete;
    Hash.prototype.get = hashGet;
    Hash.prototype.has = hashHas;
    Hash.prototype.set = hashSet;
    module22.exports = Hash;
  });
  var require_mapCacheClear = __commonJS2((exports3, module22) => {
    var Hash = require_Hash();
    var ListCache = require_ListCache();
    var Map2 = require_Map();
    function mapCacheClear() {
      this.size = 0;
      this.__data__ = {
        hash: new Hash(),
        map: new (Map2 || ListCache)(),
        string: new Hash()
      };
    }
    module22.exports = mapCacheClear;
  });
  var require_isKeyable = __commonJS2((exports3, module22) => {
    function isKeyable(value) {
      var type = typeof value;
      return type == "string" || type == "number" || type == "symbol" || type == "boolean" ? value !== "__proto__" : value === null;
    }
    module22.exports = isKeyable;
  });
  var require_getMapData = __commonJS2((exports3, module22) => {
    var isKeyable = require_isKeyable();
    function getMapData(map, key) {
      var data = map.__data__;
      return isKeyable(key) ? data[typeof key == "string" ? "string" : "hash"] : data.map;
    }
    module22.exports = getMapData;
  });
  var require_mapCacheDelete = __commonJS2((exports3, module22) => {
    var getMapData = require_getMapData();
    function mapCacheDelete(key) {
      var result = getMapData(this, key)["delete"](key);
      this.size -= result ? 1 : 0;
      return result;
    }
    module22.exports = mapCacheDelete;
  });
  var require_mapCacheGet = __commonJS2((exports3, module22) => {
    var getMapData = require_getMapData();
    function mapCacheGet(key) {
      return getMapData(this, key).get(key);
    }
    module22.exports = mapCacheGet;
  });
  var require_mapCacheHas = __commonJS2((exports3, module22) => {
    var getMapData = require_getMapData();
    function mapCacheHas(key) {
      return getMapData(this, key).has(key);
    }
    module22.exports = mapCacheHas;
  });
  var require_mapCacheSet = __commonJS2((exports3, module22) => {
    var getMapData = require_getMapData();
    function mapCacheSet(key, value) {
      var data = getMapData(this, key), size = data.size;
      data.set(key, value);
      this.size += data.size == size ? 0 : 1;
      return this;
    }
    module22.exports = mapCacheSet;
  });
  var require_MapCache = __commonJS2((exports3, module22) => {
    var mapCacheClear = require_mapCacheClear();
    var mapCacheDelete = require_mapCacheDelete();
    var mapCacheGet = require_mapCacheGet();
    var mapCacheHas = require_mapCacheHas();
    var mapCacheSet = require_mapCacheSet();
    function MapCache(entries) {
      var index = -1, length = entries == null ? 0 : entries.length;
      this.clear();
      while (++index < length) {
        var entry = entries[index];
        this.set(entry[0], entry[1]);
      }
    }
    MapCache.prototype.clear = mapCacheClear;
    MapCache.prototype["delete"] = mapCacheDelete;
    MapCache.prototype.get = mapCacheGet;
    MapCache.prototype.has = mapCacheHas;
    MapCache.prototype.set = mapCacheSet;
    module22.exports = MapCache;
  });
  var require_stackSet = __commonJS2((exports3, module22) => {
    var ListCache = require_ListCache();
    var Map2 = require_Map();
    var MapCache = require_MapCache();
    var LARGE_ARRAY_SIZE = 200;
    function stackSet(key, value) {
      var data = this.__data__;
      if (data instanceof ListCache) {
        var pairs = data.__data__;
        if (!Map2 || pairs.length < LARGE_ARRAY_SIZE - 1) {
          pairs.push([key, value]);
          this.size = ++data.size;
          return this;
        }
        data = this.__data__ = new MapCache(pairs);
      }
      data.set(key, value);
      this.size = data.size;
      return this;
    }
    module22.exports = stackSet;
  });
  var require_Stack = __commonJS2((exports3, module22) => {
    var ListCache = require_ListCache();
    var stackClear = require_stackClear();
    var stackDelete = require_stackDelete();
    var stackGet = require_stackGet();
    var stackHas = require_stackHas();
    var stackSet = require_stackSet();
    function Stack(entries) {
      var data = this.__data__ = new ListCache(entries);
      this.size = data.size;
    }
    Stack.prototype.clear = stackClear;
    Stack.prototype["delete"] = stackDelete;
    Stack.prototype.get = stackGet;
    Stack.prototype.has = stackHas;
    Stack.prototype.set = stackSet;
    module22.exports = Stack;
  });
  var require_setCacheAdd = __commonJS2((exports3, module22) => {
    var HASH_UNDEFINED = "__lodash_hash_undefined__";
    function setCacheAdd(value) {
      this.__data__.set(value, HASH_UNDEFINED);
      return this;
    }
    module22.exports = setCacheAdd;
  });
  var require_setCacheHas = __commonJS2((exports3, module22) => {
    function setCacheHas(value) {
      return this.__data__.has(value);
    }
    module22.exports = setCacheHas;
  });
  var require_SetCache = __commonJS2((exports3, module22) => {
    var MapCache = require_MapCache();
    var setCacheAdd = require_setCacheAdd();
    var setCacheHas = require_setCacheHas();
    function SetCache(values) {
      var index = -1, length = values == null ? 0 : values.length;
      this.__data__ = new MapCache();
      while (++index < length) {
        this.add(values[index]);
      }
    }
    SetCache.prototype.add = SetCache.prototype.push = setCacheAdd;
    SetCache.prototype.has = setCacheHas;
    module22.exports = SetCache;
  });
  var require_arraySome = __commonJS2((exports3, module22) => {
    function arraySome(array, predicate) {
      var index = -1, length = array == null ? 0 : array.length;
      while (++index < length) {
        if (predicate(array[index], index, array)) {
          return true;
        }
      }
      return false;
    }
    module22.exports = arraySome;
  });
  var require_cacheHas = __commonJS2((exports3, module22) => {
    function cacheHas(cache, key) {
      return cache.has(key);
    }
    module22.exports = cacheHas;
  });
  var require_equalArrays = __commonJS2((exports3, module22) => {
    var SetCache = require_SetCache();
    var arraySome = require_arraySome();
    var cacheHas = require_cacheHas();
    var COMPARE_PARTIAL_FLAG = 1;
    var COMPARE_UNORDERED_FLAG = 2;
    function equalArrays(array, other, bitmask, customizer, equalFunc, stack) {
      var isPartial = bitmask & COMPARE_PARTIAL_FLAG, arrLength = array.length, othLength = other.length;
      if (arrLength != othLength && !(isPartial && othLength > arrLength)) {
        return false;
      }
      var arrStacked = stack.get(array);
      var othStacked = stack.get(other);
      if (arrStacked && othStacked) {
        return arrStacked == other && othStacked == array;
      }
      var index = -1, result = true, seen = bitmask & COMPARE_UNORDERED_FLAG ? new SetCache() : void 0;
      stack.set(array, other);
      stack.set(other, array);
      while (++index < arrLength) {
        var arrValue = array[index], othValue = other[index];
        if (customizer) {
          var compared = isPartial ? customizer(othValue, arrValue, index, other, array, stack) : customizer(arrValue, othValue, index, array, other, stack);
        }
        if (compared !== void 0) {
          if (compared) {
            continue;
          }
          result = false;
          break;
        }
        if (seen) {
          if (!arraySome(other, function(othValue2, othIndex) {
            if (!cacheHas(seen, othIndex) && (arrValue === othValue2 || equalFunc(arrValue, othValue2, bitmask, customizer, stack))) {
              return seen.push(othIndex);
            }
          })) {
            result = false;
            break;
          }
        } else if (!(arrValue === othValue || equalFunc(arrValue, othValue, bitmask, customizer, stack))) {
          result = false;
          break;
        }
      }
      stack["delete"](array);
      stack["delete"](other);
      return result;
    }
    module22.exports = equalArrays;
  });
  var require_Uint8Array = __commonJS2((exports3, module22) => {
    var root = require_root();
    var Uint8Array2 = root.Uint8Array;
    module22.exports = Uint8Array2;
  });
  var require_mapToArray = __commonJS2((exports3, module22) => {
    function mapToArray(map) {
      var index = -1, result = Array(map.size);
      map.forEach(function(value, key) {
        result[++index] = [key, value];
      });
      return result;
    }
    module22.exports = mapToArray;
  });
  var require_setToArray = __commonJS2((exports3, module22) => {
    function setToArray(set) {
      var index = -1, result = Array(set.size);
      set.forEach(function(value) {
        result[++index] = value;
      });
      return result;
    }
    module22.exports = setToArray;
  });
  var require_equalByTag = __commonJS2((exports3, module22) => {
    var Symbol2 = require_Symbol();
    var Uint8Array2 = require_Uint8Array();
    var eq = require_eq();
    var equalArrays = require_equalArrays();
    var mapToArray = require_mapToArray();
    var setToArray = require_setToArray();
    var COMPARE_PARTIAL_FLAG = 1;
    var COMPARE_UNORDERED_FLAG = 2;
    var boolTag = "[object Boolean]";
    var dateTag = "[object Date]";
    var errorTag = "[object Error]";
    var mapTag = "[object Map]";
    var numberTag = "[object Number]";
    var regexpTag = "[object RegExp]";
    var setTag = "[object Set]";
    var stringTag = "[object String]";
    var symbolTag = "[object Symbol]";
    var arrayBufferTag = "[object ArrayBuffer]";
    var dataViewTag = "[object DataView]";
    var symbolProto = Symbol2 ? Symbol2.prototype : void 0;
    var symbolValueOf = symbolProto ? symbolProto.valueOf : void 0;
    function equalByTag(object, other, tag, bitmask, customizer, equalFunc, stack) {
      switch (tag) {
        case dataViewTag:
          if (object.byteLength != other.byteLength || object.byteOffset != other.byteOffset) {
            return false;
          }
          object = object.buffer;
          other = other.buffer;
        case arrayBufferTag:
          if (object.byteLength != other.byteLength || !equalFunc(new Uint8Array2(object), new Uint8Array2(other))) {
            return false;
          }
          return true;
        case boolTag:
        case dateTag:
        case numberTag:
          return eq(+object, +other);
        case errorTag:
          return object.name == other.name && object.message == other.message;
        case regexpTag:
        case stringTag:
          return object == other + "";
        case mapTag:
          var convert = mapToArray;
        case setTag:
          var isPartial = bitmask & COMPARE_PARTIAL_FLAG;
          convert || (convert = setToArray);
          if (object.size != other.size && !isPartial) {
            return false;
          }
          var stacked = stack.get(object);
          if (stacked) {
            return stacked == other;
          }
          bitmask |= COMPARE_UNORDERED_FLAG;
          stack.set(object, other);
          var result = equalArrays(convert(object), convert(other), bitmask, customizer, equalFunc, stack);
          stack["delete"](object);
          return result;
        case symbolTag:
          if (symbolValueOf) {
            return symbolValueOf.call(object) == symbolValueOf.call(other);
          }
      }
      return false;
    }
    module22.exports = equalByTag;
  });
  var require_arrayPush = __commonJS2((exports3, module22) => {
    function arrayPush(array, values) {
      var index = -1, length = values.length, offset = array.length;
      while (++index < length) {
        array[offset + index] = values[index];
      }
      return array;
    }
    module22.exports = arrayPush;
  });
  var require_baseGetAllKeys = __commonJS2((exports3, module22) => {
    var arrayPush = require_arrayPush();
    var isArray = require_isArray();
    function baseGetAllKeys(object, keysFunc, symbolsFunc) {
      var result = keysFunc(object);
      return isArray(object) ? result : arrayPush(result, symbolsFunc(object));
    }
    module22.exports = baseGetAllKeys;
  });
  var require_arrayFilter = __commonJS2((exports3, module22) => {
    function arrayFilter(array, predicate) {
      var index = -1, length = array == null ? 0 : array.length, resIndex = 0, result = [];
      while (++index < length) {
        var value = array[index];
        if (predicate(value, index, array)) {
          result[resIndex++] = value;
        }
      }
      return result;
    }
    module22.exports = arrayFilter;
  });
  var require_stubArray = __commonJS2((exports3, module22) => {
    function stubArray() {
      return [];
    }
    module22.exports = stubArray;
  });
  var require_getSymbols = __commonJS2((exports3, module22) => {
    var arrayFilter = require_arrayFilter();
    var stubArray = require_stubArray();
    var objectProto = Object.prototype;
    var propertyIsEnumerable = objectProto.propertyIsEnumerable;
    var nativeGetSymbols = Object.getOwnPropertySymbols;
    var getSymbols = !nativeGetSymbols ? stubArray : function(object) {
      if (object == null) {
        return [];
      }
      object = Object(object);
      return arrayFilter(nativeGetSymbols(object), function(symbol) {
        return propertyIsEnumerable.call(object, symbol);
      });
    };
    module22.exports = getSymbols;
  });
  var require_getAllKeys = __commonJS2((exports3, module22) => {
    var baseGetAllKeys = require_baseGetAllKeys();
    var getSymbols = require_getSymbols();
    var keys = require_keys();
    function getAllKeys(object) {
      return baseGetAllKeys(object, keys, getSymbols);
    }
    module22.exports = getAllKeys;
  });
  var require_equalObjects = __commonJS2((exports3, module22) => {
    var getAllKeys = require_getAllKeys();
    var COMPARE_PARTIAL_FLAG = 1;
    var objectProto = Object.prototype;
    var hasOwnProperty = objectProto.hasOwnProperty;
    function equalObjects(object, other, bitmask, customizer, equalFunc, stack) {
      var isPartial = bitmask & COMPARE_PARTIAL_FLAG, objProps = getAllKeys(object), objLength = objProps.length, othProps = getAllKeys(other), othLength = othProps.length;
      if (objLength != othLength && !isPartial) {
        return false;
      }
      var index = objLength;
      while (index--) {
        var key = objProps[index];
        if (!(isPartial ? key in other : hasOwnProperty.call(other, key))) {
          return false;
        }
      }
      var objStacked = stack.get(object);
      var othStacked = stack.get(other);
      if (objStacked && othStacked) {
        return objStacked == other && othStacked == object;
      }
      var result = true;
      stack.set(object, other);
      stack.set(other, object);
      var skipCtor = isPartial;
      while (++index < objLength) {
        key = objProps[index];
        var objValue = object[key], othValue = other[key];
        if (customizer) {
          var compared = isPartial ? customizer(othValue, objValue, key, other, object, stack) : customizer(objValue, othValue, key, object, other, stack);
        }
        if (!(compared === void 0 ? objValue === othValue || equalFunc(objValue, othValue, bitmask, customizer, stack) : compared)) {
          result = false;
          break;
        }
        skipCtor || (skipCtor = key == "constructor");
      }
      if (result && !skipCtor) {
        var objCtor = object.constructor, othCtor = other.constructor;
        if (objCtor != othCtor && ("constructor" in object && "constructor" in other) && !(typeof objCtor == "function" && objCtor instanceof objCtor && typeof othCtor == "function" && othCtor instanceof othCtor)) {
          result = false;
        }
      }
      stack["delete"](object);
      stack["delete"](other);
      return result;
    }
    module22.exports = equalObjects;
  });
  var require_DataView = __commonJS2((exports3, module22) => {
    var getNative = require_getNative();
    var root = require_root();
    var DataView = getNative(root, "DataView");
    module22.exports = DataView;
  });
  var require_Promise = __commonJS2((exports3, module22) => {
    var getNative = require_getNative();
    var root = require_root();
    var Promise2 = getNative(root, "Promise");
    module22.exports = Promise2;
  });
  var require_Set = __commonJS2((exports3, module22) => {
    var getNative = require_getNative();
    var root = require_root();
    var Set = getNative(root, "Set");
    module22.exports = Set;
  });
  var require_WeakMap = __commonJS2((exports3, module22) => {
    var getNative = require_getNative();
    var root = require_root();
    var WeakMap = getNative(root, "WeakMap");
    module22.exports = WeakMap;
  });
  var require_getTag = __commonJS2((exports3, module22) => {
    var DataView = require_DataView();
    var Map2 = require_Map();
    var Promise2 = require_Promise();
    var Set = require_Set();
    var WeakMap = require_WeakMap();
    var baseGetTag = require_baseGetTag();
    var toSource = require_toSource();
    var mapTag = "[object Map]";
    var objectTag = "[object Object]";
    var promiseTag = "[object Promise]";
    var setTag = "[object Set]";
    var weakMapTag = "[object WeakMap]";
    var dataViewTag = "[object DataView]";
    var dataViewCtorString = toSource(DataView);
    var mapCtorString = toSource(Map2);
    var promiseCtorString = toSource(Promise2);
    var setCtorString = toSource(Set);
    var weakMapCtorString = toSource(WeakMap);
    var getTag = baseGetTag;
    if (DataView && getTag(new DataView(new ArrayBuffer(1))) != dataViewTag || Map2 && getTag(new Map2()) != mapTag || Promise2 && getTag(Promise2.resolve()) != promiseTag || Set && getTag(new Set()) != setTag || WeakMap && getTag(new WeakMap()) != weakMapTag) {
      getTag = function(value) {
        var result = baseGetTag(value), Ctor = result == objectTag ? value.constructor : void 0, ctorString = Ctor ? toSource(Ctor) : "";
        if (ctorString) {
          switch (ctorString) {
            case dataViewCtorString:
              return dataViewTag;
            case mapCtorString:
              return mapTag;
            case promiseCtorString:
              return promiseTag;
            case setCtorString:
              return setTag;
            case weakMapCtorString:
              return weakMapTag;
          }
        }
        return result;
      };
    }
    module22.exports = getTag;
  });
  var require_baseIsEqualDeep = __commonJS2((exports3, module22) => {
    var Stack = require_Stack();
    var equalArrays = require_equalArrays();
    var equalByTag = require_equalByTag();
    var equalObjects = require_equalObjects();
    var getTag = require_getTag();
    var isArray = require_isArray();
    var isBuffer = require_isBuffer();
    var isTypedArray = require_isTypedArray();
    var COMPARE_PARTIAL_FLAG = 1;
    var argsTag = "[object Arguments]";
    var arrayTag = "[object Array]";
    var objectTag = "[object Object]";
    var objectProto = Object.prototype;
    var hasOwnProperty = objectProto.hasOwnProperty;
    function baseIsEqualDeep(object, other, bitmask, customizer, equalFunc, stack) {
      var objIsArr = isArray(object), othIsArr = isArray(other), objTag = objIsArr ? arrayTag : getTag(object), othTag = othIsArr ? arrayTag : getTag(other);
      objTag = objTag == argsTag ? objectTag : objTag;
      othTag = othTag == argsTag ? objectTag : othTag;
      var objIsObj = objTag == objectTag, othIsObj = othTag == objectTag, isSameTag = objTag == othTag;
      if (isSameTag && isBuffer(object)) {
        if (!isBuffer(other)) {
          return false;
        }
        objIsArr = true;
        objIsObj = false;
      }
      if (isSameTag && !objIsObj) {
        stack || (stack = new Stack());
        return objIsArr || isTypedArray(object) ? equalArrays(object, other, bitmask, customizer, equalFunc, stack) : equalByTag(object, other, objTag, bitmask, customizer, equalFunc, stack);
      }
      if (!(bitmask & COMPARE_PARTIAL_FLAG)) {
        var objIsWrapped = objIsObj && hasOwnProperty.call(object, "__wrapped__"), othIsWrapped = othIsObj && hasOwnProperty.call(other, "__wrapped__");
        if (objIsWrapped || othIsWrapped) {
          var objUnwrapped = objIsWrapped ? object.value() : object, othUnwrapped = othIsWrapped ? other.value() : other;
          stack || (stack = new Stack());
          return equalFunc(objUnwrapped, othUnwrapped, bitmask, customizer, stack);
        }
      }
      if (!isSameTag) {
        return false;
      }
      stack || (stack = new Stack());
      return equalObjects(object, other, bitmask, customizer, equalFunc, stack);
    }
    module22.exports = baseIsEqualDeep;
  });
  var require_baseIsEqual = __commonJS2((exports3, module22) => {
    var baseIsEqualDeep = require_baseIsEqualDeep();
    var isObjectLike = require_isObjectLike();
    function baseIsEqual(value, other, bitmask, customizer, stack) {
      if (value === other) {
        return true;
      }
      if (value == null || other == null || !isObjectLike(value) && !isObjectLike(other)) {
        return value !== value && other !== other;
      }
      return baseIsEqualDeep(value, other, bitmask, customizer, baseIsEqual, stack);
    }
    module22.exports = baseIsEqual;
  });
  var require_baseIsMatch = __commonJS2((exports3, module22) => {
    var Stack = require_Stack();
    var baseIsEqual = require_baseIsEqual();
    var COMPARE_PARTIAL_FLAG = 1;
    var COMPARE_UNORDERED_FLAG = 2;
    function baseIsMatch(object, source, matchData, customizer) {
      var index = matchData.length, length = index, noCustomizer = !customizer;
      if (object == null) {
        return !length;
      }
      object = Object(object);
      while (index--) {
        var data = matchData[index];
        if (noCustomizer && data[2] ? data[1] !== object[data[0]] : !(data[0] in object)) {
          return false;
        }
      }
      while (++index < length) {
        data = matchData[index];
        var key = data[0], objValue = object[key], srcValue = data[1];
        if (noCustomizer && data[2]) {
          if (objValue === void 0 && !(key in object)) {
            return false;
          }
        } else {
          var stack = new Stack();
          if (customizer) {
            var result = customizer(objValue, srcValue, key, object, source, stack);
          }
          if (!(result === void 0 ? baseIsEqual(srcValue, objValue, COMPARE_PARTIAL_FLAG | COMPARE_UNORDERED_FLAG, customizer, stack) : result)) {
            return false;
          }
        }
      }
      return true;
    }
    module22.exports = baseIsMatch;
  });
  var require_isStrictComparable = __commonJS2((exports3, module22) => {
    var isObject = require_isObject();
    function isStrictComparable(value) {
      return value === value && !isObject(value);
    }
    module22.exports = isStrictComparable;
  });
  var require_getMatchData = __commonJS2((exports3, module22) => {
    var isStrictComparable = require_isStrictComparable();
    var keys = require_keys();
    function getMatchData(object) {
      var result = keys(object), length = result.length;
      while (length--) {
        var key = result[length], value = object[key];
        result[length] = [key, value, isStrictComparable(value)];
      }
      return result;
    }
    module22.exports = getMatchData;
  });
  var require_matchesStrictComparable = __commonJS2((exports3, module22) => {
    function matchesStrictComparable(key, srcValue) {
      return function(object) {
        if (object == null) {
          return false;
        }
        return object[key] === srcValue && (srcValue !== void 0 || key in Object(object));
      };
    }
    module22.exports = matchesStrictComparable;
  });
  var require_baseMatches = __commonJS2((exports3, module22) => {
    var baseIsMatch = require_baseIsMatch();
    var getMatchData = require_getMatchData();
    var matchesStrictComparable = require_matchesStrictComparable();
    function baseMatches(source) {
      var matchData = getMatchData(source);
      if (matchData.length == 1 && matchData[0][2]) {
        return matchesStrictComparable(matchData[0][0], matchData[0][1]);
      }
      return function(object) {
        return object === source || baseIsMatch(object, source, matchData);
      };
    }
    module22.exports = baseMatches;
  });
  var require_isSymbol = __commonJS2((exports3, module22) => {
    var baseGetTag = require_baseGetTag();
    var isObjectLike = require_isObjectLike();
    var symbolTag = "[object Symbol]";
    function isSymbol(value) {
      return typeof value == "symbol" || isObjectLike(value) && baseGetTag(value) == symbolTag;
    }
    module22.exports = isSymbol;
  });
  var require_isKey = __commonJS2((exports3, module22) => {
    var isArray = require_isArray();
    var isSymbol = require_isSymbol();
    var reIsDeepProp = /\.|\[(?:[^[\]]*|(["'])(?:(?!\1)[^\\]|\\.)*?\1)\]/;
    var reIsPlainProp = /^\w*$/;
    function isKey(value, object) {
      if (isArray(value)) {
        return false;
      }
      var type = typeof value;
      if (type == "number" || type == "symbol" || type == "boolean" || value == null || isSymbol(value)) {
        return true;
      }
      return reIsPlainProp.test(value) || !reIsDeepProp.test(value) || object != null && value in Object(object);
    }
    module22.exports = isKey;
  });
  var require_memoize = __commonJS2((exports3, module22) => {
    var MapCache = require_MapCache();
    var FUNC_ERROR_TEXT = "Expected a function";
    function memoize(func, resolver) {
      if (typeof func != "function" || resolver != null && typeof resolver != "function") {
        throw new TypeError(FUNC_ERROR_TEXT);
      }
      var memoized = function() {
        var args = arguments, key = resolver ? resolver.apply(this, args) : args[0], cache = memoized.cache;
        if (cache.has(key)) {
          return cache.get(key);
        }
        var result = func.apply(this, args);
        memoized.cache = cache.set(key, result) || cache;
        return result;
      };
      memoized.cache = new (memoize.Cache || MapCache)();
      return memoized;
    }
    memoize.Cache = MapCache;
    module22.exports = memoize;
  });
  var require_memoizeCapped = __commonJS2((exports3, module22) => {
    var memoize = require_memoize();
    var MAX_MEMOIZE_SIZE = 500;
    function memoizeCapped(func) {
      var result = memoize(func, function(key) {
        if (cache.size === MAX_MEMOIZE_SIZE) {
          cache.clear();
        }
        return key;
      });
      var cache = result.cache;
      return result;
    }
    module22.exports = memoizeCapped;
  });
  var require_stringToPath = __commonJS2((exports3, module22) => {
    var memoizeCapped = require_memoizeCapped();
    var rePropName = /[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|$))/g;
    var reEscapeChar = /\\(\\)?/g;
    var stringToPath = memoizeCapped(function(string) {
      var result = [];
      if (string.charCodeAt(0) === 46) {
        result.push("");
      }
      string.replace(rePropName, function(match, number, quote, subString) {
        result.push(quote ? subString.replace(reEscapeChar, "$1") : number || match);
      });
      return result;
    });
    module22.exports = stringToPath;
  });
  var require_arrayMap = __commonJS2((exports3, module22) => {
    function arrayMap(array, iteratee) {
      var index = -1, length = array == null ? 0 : array.length, result = Array(length);
      while (++index < length) {
        result[index] = iteratee(array[index], index, array);
      }
      return result;
    }
    module22.exports = arrayMap;
  });
  var require_baseToString = __commonJS2((exports3, module22) => {
    var Symbol2 = require_Symbol();
    var arrayMap = require_arrayMap();
    var isArray = require_isArray();
    var isSymbol = require_isSymbol();
    var INFINITY = 1 / 0;
    var symbolProto = Symbol2 ? Symbol2.prototype : void 0;
    var symbolToString = symbolProto ? symbolProto.toString : void 0;
    function baseToString(value) {
      if (typeof value == "string") {
        return value;
      }
      if (isArray(value)) {
        return arrayMap(value, baseToString) + "";
      }
      if (isSymbol(value)) {
        return symbolToString ? symbolToString.call(value) : "";
      }
      var result = value + "";
      return result == "0" && 1 / value == -INFINITY ? "-0" : result;
    }
    module22.exports = baseToString;
  });
  var require_toString = __commonJS2((exports3, module22) => {
    var baseToString = require_baseToString();
    function toString(value) {
      return value == null ? "" : baseToString(value);
    }
    module22.exports = toString;
  });
  var require_castPath = __commonJS2((exports3, module22) => {
    var isArray = require_isArray();
    var isKey = require_isKey();
    var stringToPath = require_stringToPath();
    var toString = require_toString();
    function castPath(value, object) {
      if (isArray(value)) {
        return value;
      }
      return isKey(value, object) ? [value] : stringToPath(toString(value));
    }
    module22.exports = castPath;
  });
  var require_toKey = __commonJS2((exports3, module22) => {
    var isSymbol = require_isSymbol();
    var INFINITY = 1 / 0;
    function toKey(value) {
      if (typeof value == "string" || isSymbol(value)) {
        return value;
      }
      var result = value + "";
      return result == "0" && 1 / value == -INFINITY ? "-0" : result;
    }
    module22.exports = toKey;
  });
  var require_baseGet = __commonJS2((exports3, module22) => {
    var castPath = require_castPath();
    var toKey = require_toKey();
    function baseGet(object, path) {
      path = castPath(path, object);
      var index = 0, length = path.length;
      while (object != null && index < length) {
        object = object[toKey(path[index++])];
      }
      return index && index == length ? object : void 0;
    }
    module22.exports = baseGet;
  });
  var require_get = __commonJS2((exports3, module22) => {
    var baseGet = require_baseGet();
    function get(object, path, defaultValue) {
      var result = object == null ? void 0 : baseGet(object, path);
      return result === void 0 ? defaultValue : result;
    }
    module22.exports = get;
  });
  var require_baseHasIn = __commonJS2((exports3, module22) => {
    function baseHasIn(object, key) {
      return object != null && key in Object(object);
    }
    module22.exports = baseHasIn;
  });
  var require_hasPath = __commonJS2((exports3, module22) => {
    var castPath = require_castPath();
    var isArguments = require_isArguments();
    var isArray = require_isArray();
    var isIndex = require_isIndex();
    var isLength = require_isLength();
    var toKey = require_toKey();
    function hasPath(object, path, hasFunc) {
      path = castPath(path, object);
      var index = -1, length = path.length, result = false;
      while (++index < length) {
        var key = toKey(path[index]);
        if (!(result = object != null && hasFunc(object, key))) {
          break;
        }
        object = object[key];
      }
      if (result || ++index != length) {
        return result;
      }
      length = object == null ? 0 : object.length;
      return !!length && isLength(length) && isIndex(key, length) && (isArray(object) || isArguments(object));
    }
    module22.exports = hasPath;
  });
  var require_hasIn = __commonJS2((exports3, module22) => {
    var baseHasIn = require_baseHasIn();
    var hasPath = require_hasPath();
    function hasIn(object, path) {
      return object != null && hasPath(object, path, baseHasIn);
    }
    module22.exports = hasIn;
  });
  var require_baseMatchesProperty = __commonJS2((exports3, module22) => {
    var baseIsEqual = require_baseIsEqual();
    var get = require_get();
    var hasIn = require_hasIn();
    var isKey = require_isKey();
    var isStrictComparable = require_isStrictComparable();
    var matchesStrictComparable = require_matchesStrictComparable();
    var toKey = require_toKey();
    var COMPARE_PARTIAL_FLAG = 1;
    var COMPARE_UNORDERED_FLAG = 2;
    function baseMatchesProperty(path, srcValue) {
      if (isKey(path) && isStrictComparable(srcValue)) {
        return matchesStrictComparable(toKey(path), srcValue);
      }
      return function(object) {
        var objValue = get(object, path);
        return objValue === void 0 && objValue === srcValue ? hasIn(object, path) : baseIsEqual(srcValue, objValue, COMPARE_PARTIAL_FLAG | COMPARE_UNORDERED_FLAG);
      };
    }
    module22.exports = baseMatchesProperty;
  });
  var require_baseProperty = __commonJS2((exports3, module22) => {
    function baseProperty(key) {
      return function(object) {
        return object == null ? void 0 : object[key];
      };
    }
    module22.exports = baseProperty;
  });
  var require_basePropertyDeep = __commonJS2((exports3, module22) => {
    var baseGet = require_baseGet();
    function basePropertyDeep(path) {
      return function(object) {
        return baseGet(object, path);
      };
    }
    module22.exports = basePropertyDeep;
  });
  var require_property = __commonJS2((exports3, module22) => {
    var baseProperty = require_baseProperty();
    var basePropertyDeep = require_basePropertyDeep();
    var isKey = require_isKey();
    var toKey = require_toKey();
    function property(path) {
      return isKey(path) ? baseProperty(toKey(path)) : basePropertyDeep(path);
    }
    module22.exports = property;
  });
  var require_baseIteratee = __commonJS2((exports3, module22) => {
    var baseMatches = require_baseMatches();
    var baseMatchesProperty = require_baseMatchesProperty();
    var identity = require_identity();
    var isArray = require_isArray();
    var property = require_property();
    function baseIteratee(value) {
      if (typeof value == "function") {
        return value;
      }
      if (value == null) {
        return identity;
      }
      if (typeof value == "object") {
        return isArray(value) ? baseMatchesProperty(value[0], value[1]) : baseMatches(value);
      }
      return property(value);
    }
    module22.exports = baseIteratee;
  });
  var require_every = __commonJS2((exports3, module22) => {
    var arrayEvery = require_arrayEvery();
    var baseEvery = require_baseEvery();
    var baseIteratee = require_baseIteratee();
    var isArray = require_isArray();
    var isIterateeCall = require_isIterateeCall();
    function every(collection, predicate, guard) {
      var func = isArray(collection) ? arrayEvery : baseEvery;
      if (guard && isIterateeCall(collection, predicate, guard)) {
        predicate = void 0;
      }
      return func(collection, baseIteratee(predicate, 3));
    }
    module22.exports = every;
  });
  var require_util = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    exports3.calculatePadding = calculatePadding;
    exports3.slicePadding = slicePadding;
    var _every = require_every();
    var _every2 = _interopRequireDefault(_every);
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : {default: obj};
    }
    function calculatePadding(length) {
      switch (length % 4) {
        case 0:
          return 0;
        case 1:
          return 3;
        case 2:
          return 2;
        case 3:
          return 1;
        default:
          return null;
      }
    }
    function slicePadding(io, length) {
      var padding = io.slice(length);
      var allZero = (0, _every2.default)(padding.buffer(), function(byte) {
        return byte === 0;
      });
      if (allZero !== true) {
        throw new Error("XDR Read Error: invalid padding");
      }
    }
  });
  var require_cursor = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    exports3.Cursor = void 0;
    var _createClass = function() {
      function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
          var descriptor = props[i];
          descriptor.enumerable = descriptor.enumerable || false;
          descriptor.configurable = true;
          if ("value" in descriptor)
            descriptor.writable = true;
          Object.defineProperty(target, descriptor.key, descriptor);
        }
      }
      return function(Constructor, protoProps, staticProps) {
        if (protoProps)
          defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
          defineProperties(Constructor, staticProps);
        return Constructor;
      };
    }();
    var _util = require_util();
    function _classCallCheck(instance, Constructor) {
      if (!(instance instanceof Constructor)) {
        throw new TypeError("Cannot call a class as a function");
      }
    }
    var Cursor = function() {
      function Cursor2(buffer) {
        _classCallCheck(this, Cursor2);
        if (!(buffer instanceof Buffer)) {
          buffer = typeof buffer === "number" ? Buffer.alloc(buffer) : Buffer.from(buffer);
        }
        this._setBuffer(buffer);
        this.rewind();
      }
      _createClass(Cursor2, [{
        key: "_setBuffer",
        value: function _setBuffer(buffer) {
          this._buffer = buffer;
          this.length = buffer.length;
        }
      }, {
        key: "buffer",
        value: function buffer() {
          return this._buffer;
        }
      }, {
        key: "tap",
        value: function tap(cb) {
          cb(this);
          return this;
        }
      }, {
        key: "clone",
        value: function clone(newIndex) {
          var c = new this.constructor(this.buffer());
          c.seek(arguments.length === 0 ? this.tell() : newIndex);
          return c;
        }
      }, {
        key: "tell",
        value: function tell() {
          return this._index;
        }
      }, {
        key: "seek",
        value: function seek(op, index) {
          if (arguments.length === 1) {
            index = op;
            op = "=";
          }
          if (op === "+") {
            this._index += index;
          } else if (op === "-") {
            this._index -= index;
          } else {
            this._index = index;
          }
          return this;
        }
      }, {
        key: "rewind",
        value: function rewind() {
          return this.seek(0);
        }
      }, {
        key: "eof",
        value: function eof() {
          return this.tell() === this.buffer().length;
        }
      }, {
        key: "write",
        value: function write(string, length, encoding) {
          return this.seek("+", this.buffer().write(string, this.tell(), length, encoding));
        }
      }, {
        key: "fill",
        value: function fill(value, length) {
          if (arguments.length === 1) {
            length = this.buffer().length - this.tell();
          }
          this.buffer().fill(value, this.tell(), this.tell() + length);
          this.seek("+", length);
          return this;
        }
      }, {
        key: "slice",
        value: function slice(length) {
          if (arguments.length === 0) {
            length = this.length - this.tell();
          }
          var c = new this.constructor(this.buffer().slice(this.tell(), this.tell() + length));
          this.seek("+", length);
          return c;
        }
      }, {
        key: "copyFrom",
        value: function copyFrom(source) {
          var buf = source instanceof Buffer ? source : source.buffer();
          buf.copy(this.buffer(), this.tell(), 0, buf.length);
          this.seek("+", buf.length);
          return this;
        }
      }, {
        key: "concat",
        value: function concat(list) {
          list.forEach(function(item, i) {
            if (item instanceof Cursor2) {
              list[i] = item.buffer();
            }
          });
          list.unshift(this.buffer());
          var b = Buffer.concat(list);
          this._setBuffer(b);
          return this;
        }
      }, {
        key: "toString",
        value: function toString(encoding, length) {
          if (arguments.length === 0) {
            encoding = "utf8";
            length = this.buffer().length - this.tell();
          } else if (arguments.length === 1) {
            length = this.buffer().length - this.tell();
          }
          var val = this.buffer().toString(encoding, this.tell(), this.tell() + length);
          this.seek("+", length);
          return val;
        }
      }, {
        key: "writeBufferPadded",
        value: function writeBufferPadded(buffer) {
          var padding = (0, _util.calculatePadding)(buffer.length);
          var paddingBuffer = Buffer.alloc(padding);
          return this.copyFrom(new Cursor2(buffer)).copyFrom(new Cursor2(paddingBuffer));
        }
      }]);
      return Cursor2;
    }();
    [[1, ["readInt8", "readUInt8"]], [2, ["readInt16BE", "readInt16LE", "readUInt16BE", "readUInt16LE"]], [4, ["readInt32BE", "readInt32LE", "readUInt32BE", "readUInt32LE", "readFloatBE", "readFloatLE"]], [8, ["readDoubleBE", "readDoubleLE"]]].forEach(function(arr) {
      arr[1].forEach(function(method) {
        Cursor.prototype[method] = function read() {
          var val = this.buffer()[method](this.tell());
          this.seek("+", arr[0]);
          return val;
        };
      });
    });
    [[1, ["writeInt8", "writeUInt8"]], [2, ["writeInt16BE", "writeInt16LE", "writeUInt16BE", "writeUInt16LE"]], [4, ["writeInt32BE", "writeInt32LE", "writeUInt32BE", "writeUInt32LE", "writeFloatBE", "writeFloatLE"]], [8, ["writeDoubleBE", "writeDoubleLE"]]].forEach(function(arr) {
      arr[1].forEach(function(method) {
        Cursor.prototype[method] = function write(val) {
          this.buffer()[method](val, this.tell());
          this.seek("+", arr[0]);
          return this;
        };
      });
    });
    exports3.Cursor = Cursor;
  });
  var require_io_mixin = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    exports3.default = includeIoMixin;
    var _extend = require_extend();
    var _extend2 = _interopRequireDefault(_extend);
    var _isFunction = require_isFunction();
    var _isFunction2 = _interopRequireDefault(_isFunction);
    var _cursor = require_cursor();
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : {default: obj};
    }
    var BUFFER_SIZE = Math.pow(2, 16);
    var staticMethods = {
      toXDR: function toXDR(val) {
        var cursor = new _cursor.Cursor(BUFFER_SIZE);
        this.write(val, cursor);
        var bytesWritten = cursor.tell();
        cursor.rewind();
        return cursor.slice(bytesWritten).buffer();
      },
      fromXDR: function fromXDR(input) {
        var format = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "raw";
        var buffer = void 0;
        switch (format) {
          case "raw":
            buffer = input;
            break;
          case "hex":
            buffer = Buffer.from(input, "hex");
            break;
          case "base64":
            buffer = Buffer.from(input, "base64");
            break;
          default:
            throw new Error("Invalid format " + format + ', must be "raw", "hex", "base64"');
        }
        var cursor = new _cursor.Cursor(buffer);
        var result = this.read(cursor);
        return result;
      },
      validateXDR: function validateXDR(input) {
        var format = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "raw";
        try {
          this.fromXDR(input, format);
          return true;
        } catch (e) {
          return false;
        }
      }
    };
    var instanceMethods = {
      toXDR: function toXDR() {
        var format = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "raw";
        var buffer = this.constructor.toXDR(this);
        switch (format) {
          case "raw":
            return buffer;
          case "hex":
            return buffer.toString("hex");
          case "base64":
            return buffer.toString("base64");
          default:
            throw new Error("Invalid format " + format + ', must be "raw", "hex", "base64"');
        }
      }
    };
    function includeIoMixin(obj) {
      (0, _extend2.default)(obj, staticMethods);
      if ((0, _isFunction2.default)(obj)) {
        (0, _extend2.default)(obj.prototype, instanceMethods);
      }
    }
  });
  var require_int = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    exports3.Int = void 0;
    var _isNumber = require_isNumber();
    var _isNumber2 = _interopRequireDefault(_isNumber);
    var _ioMixin = require_io_mixin();
    var _ioMixin2 = _interopRequireDefault(_ioMixin);
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : {default: obj};
    }
    var Int = exports3.Int = {
      read: function read(io) {
        return io.readInt32BE();
      },
      write: function write(value, io) {
        if (!(0, _isNumber2.default)(value)) {
          throw new Error("XDR Write Error: not a number");
        }
        if (Math.floor(value) !== value) {
          throw new Error("XDR Write Error: not an integer");
        }
        io.writeInt32BE(value);
      },
      isValid: function isValid(value) {
        if (!(0, _isNumber2.default)(value)) {
          return false;
        }
        if (Math.floor(value) !== value) {
          return false;
        }
        return value >= Int.MIN_VALUE && value <= Int.MAX_VALUE;
      }
    };
    Int.MAX_VALUE = Math.pow(2, 31) - 1;
    Int.MIN_VALUE = -Math.pow(2, 31);
    (0, _ioMixin2.default)(Int);
  });
  var require_Long = __commonJS2((exports3, module22) => {
    /**
     * @license Long.js (c) 2013 Daniel Wirtz <dcode@dcode.io>
     * Released under the Apache License, Version 2.0
     * see: https://github.com/dcodeIO/Long.js for details
     */
    (function(global2, factory) {
      if (typeof define === "function" && define["amd"])
        define([], factory);
      else if (typeof module22 === "object" && module22 && module22["exports"])
        module22["exports"] = factory();
      else
        (global2["dcodeIO"] = global2["dcodeIO"] || {})["Long"] = factory();
    })(exports3, function() {
      "use strict";
      function Long(low, high, unsigned) {
        this.low = low | 0;
        this.high = high | 0;
        this.unsigned = !!unsigned;
      }
      Long.__isLong__;
      Object.defineProperty(Long.prototype, "__isLong__", {
        value: true,
        enumerable: false,
        configurable: false
      });
      Long.isLong = function isLong(obj) {
        return (obj && obj["__isLong__"]) === true;
      };
      var INT_CACHE = {};
      var UINT_CACHE = {};
      Long.fromInt = function fromInt(value, unsigned) {
        var obj, cachedObj;
        if (!unsigned) {
          value = value | 0;
          if (-128 <= value && value < 128) {
            cachedObj = INT_CACHE[value];
            if (cachedObj)
              return cachedObj;
          }
          obj = new Long(value, value < 0 ? -1 : 0, false);
          if (-128 <= value && value < 128)
            INT_CACHE[value] = obj;
          return obj;
        } else {
          value = value >>> 0;
          if (0 <= value && value < 256) {
            cachedObj = UINT_CACHE[value];
            if (cachedObj)
              return cachedObj;
          }
          obj = new Long(value, (value | 0) < 0 ? -1 : 0, true);
          if (0 <= value && value < 256)
            UINT_CACHE[value] = obj;
          return obj;
        }
      };
      Long.fromNumber = function fromNumber(value, unsigned) {
        unsigned = !!unsigned;
        if (isNaN(value) || !isFinite(value))
          return Long.ZERO;
        if (!unsigned && value <= -TWO_PWR_63_DBL)
          return Long.MIN_VALUE;
        if (!unsigned && value + 1 >= TWO_PWR_63_DBL)
          return Long.MAX_VALUE;
        if (unsigned && value >= TWO_PWR_64_DBL)
          return Long.MAX_UNSIGNED_VALUE;
        if (value < 0)
          return Long.fromNumber(-value, unsigned).negate();
        return new Long(value % TWO_PWR_32_DBL | 0, value / TWO_PWR_32_DBL | 0, unsigned);
      };
      Long.fromBits = function fromBits(lowBits, highBits, unsigned) {
        return new Long(lowBits, highBits, unsigned);
      };
      Long.fromString = function fromString(str, unsigned, radix) {
        if (str.length === 0)
          throw Error("number format error: empty string");
        if (str === "NaN" || str === "Infinity" || str === "+Infinity" || str === "-Infinity")
          return Long.ZERO;
        if (typeof unsigned === "number")
          radix = unsigned, unsigned = false;
        radix = radix || 10;
        if (radix < 2 || 36 < radix)
          throw Error("radix out of range: " + radix);
        var p;
        if ((p = str.indexOf("-")) > 0)
          throw Error('number format error: interior "-" character: ' + str);
        else if (p === 0)
          return Long.fromString(str.substring(1), unsigned, radix).negate();
        var radixToPower = Long.fromNumber(Math.pow(radix, 8));
        var result = Long.ZERO;
        for (var i = 0; i < str.length; i += 8) {
          var size = Math.min(8, str.length - i);
          var value = parseInt(str.substring(i, i + size), radix);
          if (size < 8) {
            var power = Long.fromNumber(Math.pow(radix, size));
            result = result.multiply(power).add(Long.fromNumber(value));
          } else {
            result = result.multiply(radixToPower);
            result = result.add(Long.fromNumber(value));
          }
        }
        result.unsigned = unsigned;
        return result;
      };
      Long.fromValue = function fromValue(val) {
        if (val instanceof Long)
          return val;
        if (typeof val === "number")
          return Long.fromNumber(val);
        if (typeof val === "string")
          return Long.fromString(val);
        return new Long(val.low, val.high, val.unsigned);
      };
      var TWO_PWR_16_DBL = 1 << 16;
      var TWO_PWR_24_DBL = 1 << 24;
      var TWO_PWR_32_DBL = TWO_PWR_16_DBL * TWO_PWR_16_DBL;
      var TWO_PWR_64_DBL = TWO_PWR_32_DBL * TWO_PWR_32_DBL;
      var TWO_PWR_63_DBL = TWO_PWR_64_DBL / 2;
      var TWO_PWR_24 = Long.fromInt(TWO_PWR_24_DBL);
      Long.ZERO = Long.fromInt(0);
      Long.UZERO = Long.fromInt(0, true);
      Long.ONE = Long.fromInt(1);
      Long.UONE = Long.fromInt(1, true);
      Long.NEG_ONE = Long.fromInt(-1);
      Long.MAX_VALUE = Long.fromBits(4294967295 | 0, 2147483647 | 0, false);
      Long.MAX_UNSIGNED_VALUE = Long.fromBits(4294967295 | 0, 4294967295 | 0, true);
      Long.MIN_VALUE = Long.fromBits(0, 2147483648 | 0, false);
      Long.prototype.toInt = function toInt() {
        return this.unsigned ? this.low >>> 0 : this.low;
      };
      Long.prototype.toNumber = function toNumber() {
        if (this.unsigned) {
          return (this.high >>> 0) * TWO_PWR_32_DBL + (this.low >>> 0);
        }
        return this.high * TWO_PWR_32_DBL + (this.low >>> 0);
      };
      Long.prototype.toString = function toString(radix) {
        radix = radix || 10;
        if (radix < 2 || 36 < radix)
          throw RangeError("radix out of range: " + radix);
        if (this.isZero())
          return "0";
        var rem;
        if (this.isNegative()) {
          if (this.equals(Long.MIN_VALUE)) {
            var radixLong = Long.fromNumber(radix);
            var div = this.divide(radixLong);
            rem = div.multiply(radixLong).subtract(this);
            return div.toString(radix) + rem.toInt().toString(radix);
          } else
            return "-" + this.negate().toString(radix);
        }
        var radixToPower = Long.fromNumber(Math.pow(radix, 6), this.unsigned);
        rem = this;
        var result = "";
        while (true) {
          var remDiv = rem.divide(radixToPower), intval = rem.subtract(remDiv.multiply(radixToPower)).toInt() >>> 0, digits = intval.toString(radix);
          rem = remDiv;
          if (rem.isZero())
            return digits + result;
          else {
            while (digits.length < 6)
              digits = "0" + digits;
            result = "" + digits + result;
          }
        }
      };
      Long.prototype.getHighBits = function getHighBits() {
        return this.high;
      };
      Long.prototype.getHighBitsUnsigned = function getHighBitsUnsigned() {
        return this.high >>> 0;
      };
      Long.prototype.getLowBits = function getLowBits() {
        return this.low;
      };
      Long.prototype.getLowBitsUnsigned = function getLowBitsUnsigned() {
        return this.low >>> 0;
      };
      Long.prototype.getNumBitsAbs = function getNumBitsAbs() {
        if (this.isNegative())
          return this.equals(Long.MIN_VALUE) ? 64 : this.negate().getNumBitsAbs();
        var val = this.high != 0 ? this.high : this.low;
        for (var bit = 31; bit > 0; bit--)
          if ((val & 1 << bit) != 0)
            break;
        return this.high != 0 ? bit + 33 : bit + 1;
      };
      Long.prototype.isZero = function isZero() {
        return this.high === 0 && this.low === 0;
      };
      Long.prototype.isNegative = function isNegative() {
        return !this.unsigned && this.high < 0;
      };
      Long.prototype.isPositive = function isPositive() {
        return this.unsigned || this.high >= 0;
      };
      Long.prototype.isOdd = function isOdd() {
        return (this.low & 1) === 1;
      };
      Long.prototype.isEven = function isEven() {
        return (this.low & 1) === 0;
      };
      Long.prototype.equals = function equals(other) {
        if (!Long.isLong(other))
          other = Long.fromValue(other);
        if (this.unsigned !== other.unsigned && this.high >>> 31 === 1 && other.high >>> 31 === 1)
          return false;
        return this.high === other.high && this.low === other.low;
      };
      Long.eq = Long.prototype.equals;
      Long.prototype.notEquals = function notEquals(other) {
        return !this.equals(other);
      };
      Long.neq = Long.prototype.notEquals;
      Long.prototype.lessThan = function lessThan(other) {
        return this.compare(other) < 0;
      };
      Long.prototype.lt = Long.prototype.lessThan;
      Long.prototype.lessThanOrEqual = function lessThanOrEqual(other) {
        return this.compare(other) <= 0;
      };
      Long.prototype.lte = Long.prototype.lessThanOrEqual;
      Long.prototype.greaterThan = function greaterThan(other) {
        return this.compare(other) > 0;
      };
      Long.prototype.gt = Long.prototype.greaterThan;
      Long.prototype.greaterThanOrEqual = function greaterThanOrEqual(other) {
        return this.compare(other) >= 0;
      };
      Long.prototype.gte = Long.prototype.greaterThanOrEqual;
      Long.prototype.compare = function compare(other) {
        if (!Long.isLong(other))
          other = Long.fromValue(other);
        if (this.equals(other))
          return 0;
        var thisNeg = this.isNegative(), otherNeg = other.isNegative();
        if (thisNeg && !otherNeg)
          return -1;
        if (!thisNeg && otherNeg)
          return 1;
        if (!this.unsigned)
          return this.subtract(other).isNegative() ? -1 : 1;
        return other.high >>> 0 > this.high >>> 0 || other.high === this.high && other.low >>> 0 > this.low >>> 0 ? -1 : 1;
      };
      Long.prototype.negate = function negate() {
        if (!this.unsigned && this.equals(Long.MIN_VALUE))
          return Long.MIN_VALUE;
        return this.not().add(Long.ONE);
      };
      Long.prototype.neg = Long.prototype.negate;
      Long.prototype.add = function add(addend) {
        if (!Long.isLong(addend))
          addend = Long.fromValue(addend);
        var a48 = this.high >>> 16;
        var a32 = this.high & 65535;
        var a16 = this.low >>> 16;
        var a00 = this.low & 65535;
        var b48 = addend.high >>> 16;
        var b32 = addend.high & 65535;
        var b16 = addend.low >>> 16;
        var b00 = addend.low & 65535;
        var c48 = 0, c32 = 0, c16 = 0, c00 = 0;
        c00 += a00 + b00;
        c16 += c00 >>> 16;
        c00 &= 65535;
        c16 += a16 + b16;
        c32 += c16 >>> 16;
        c16 &= 65535;
        c32 += a32 + b32;
        c48 += c32 >>> 16;
        c32 &= 65535;
        c48 += a48 + b48;
        c48 &= 65535;
        return Long.fromBits(c16 << 16 | c00, c48 << 16 | c32, this.unsigned);
      };
      Long.prototype.subtract = function subtract(subtrahend) {
        if (!Long.isLong(subtrahend))
          subtrahend = Long.fromValue(subtrahend);
        return this.add(subtrahend.negate());
      };
      Long.prototype.sub = Long.prototype.subtract;
      Long.prototype.multiply = function multiply(multiplier) {
        if (this.isZero())
          return Long.ZERO;
        if (!Long.isLong(multiplier))
          multiplier = Long.fromValue(multiplier);
        if (multiplier.isZero())
          return Long.ZERO;
        if (this.equals(Long.MIN_VALUE))
          return multiplier.isOdd() ? Long.MIN_VALUE : Long.ZERO;
        if (multiplier.equals(Long.MIN_VALUE))
          return this.isOdd() ? Long.MIN_VALUE : Long.ZERO;
        if (this.isNegative()) {
          if (multiplier.isNegative())
            return this.negate().multiply(multiplier.negate());
          else
            return this.negate().multiply(multiplier).negate();
        } else if (multiplier.isNegative())
          return this.multiply(multiplier.negate()).negate();
        if (this.lessThan(TWO_PWR_24) && multiplier.lessThan(TWO_PWR_24))
          return Long.fromNumber(this.toNumber() * multiplier.toNumber(), this.unsigned);
        var a48 = this.high >>> 16;
        var a32 = this.high & 65535;
        var a16 = this.low >>> 16;
        var a00 = this.low & 65535;
        var b48 = multiplier.high >>> 16;
        var b32 = multiplier.high & 65535;
        var b16 = multiplier.low >>> 16;
        var b00 = multiplier.low & 65535;
        var c48 = 0, c32 = 0, c16 = 0, c00 = 0;
        c00 += a00 * b00;
        c16 += c00 >>> 16;
        c00 &= 65535;
        c16 += a16 * b00;
        c32 += c16 >>> 16;
        c16 &= 65535;
        c16 += a00 * b16;
        c32 += c16 >>> 16;
        c16 &= 65535;
        c32 += a32 * b00;
        c48 += c32 >>> 16;
        c32 &= 65535;
        c32 += a16 * b16;
        c48 += c32 >>> 16;
        c32 &= 65535;
        c32 += a00 * b32;
        c48 += c32 >>> 16;
        c32 &= 65535;
        c48 += a48 * b00 + a32 * b16 + a16 * b32 + a00 * b48;
        c48 &= 65535;
        return Long.fromBits(c16 << 16 | c00, c48 << 16 | c32, this.unsigned);
      };
      Long.prototype.mul = Long.prototype.multiply;
      Long.prototype.divide = function divide(divisor) {
        if (!Long.isLong(divisor))
          divisor = Long.fromValue(divisor);
        if (divisor.isZero())
          throw new Error("division by zero");
        if (this.isZero())
          return this.unsigned ? Long.UZERO : Long.ZERO;
        var approx, rem, res;
        if (this.equals(Long.MIN_VALUE)) {
          if (divisor.equals(Long.ONE) || divisor.equals(Long.NEG_ONE))
            return Long.MIN_VALUE;
          else if (divisor.equals(Long.MIN_VALUE))
            return Long.ONE;
          else {
            var halfThis = this.shiftRight(1);
            approx = halfThis.divide(divisor).shiftLeft(1);
            if (approx.equals(Long.ZERO)) {
              return divisor.isNegative() ? Long.ONE : Long.NEG_ONE;
            } else {
              rem = this.subtract(divisor.multiply(approx));
              res = approx.add(rem.divide(divisor));
              return res;
            }
          }
        } else if (divisor.equals(Long.MIN_VALUE))
          return this.unsigned ? Long.UZERO : Long.ZERO;
        if (this.isNegative()) {
          if (divisor.isNegative())
            return this.negate().divide(divisor.negate());
          return this.negate().divide(divisor).negate();
        } else if (divisor.isNegative())
          return this.divide(divisor.negate()).negate();
        res = Long.ZERO;
        rem = this;
        while (rem.greaterThanOrEqual(divisor)) {
          approx = Math.max(1, Math.floor(rem.toNumber() / divisor.toNumber()));
          var log2 = Math.ceil(Math.log(approx) / Math.LN2), delta = log2 <= 48 ? 1 : Math.pow(2, log2 - 48), approxRes = Long.fromNumber(approx), approxRem = approxRes.multiply(divisor);
          while (approxRem.isNegative() || approxRem.greaterThan(rem)) {
            approx -= delta;
            approxRes = Long.fromNumber(approx, this.unsigned);
            approxRem = approxRes.multiply(divisor);
          }
          if (approxRes.isZero())
            approxRes = Long.ONE;
          res = res.add(approxRes);
          rem = rem.subtract(approxRem);
        }
        return res;
      };
      Long.prototype.div = Long.prototype.divide;
      Long.prototype.modulo = function modulo(divisor) {
        if (!Long.isLong(divisor))
          divisor = Long.fromValue(divisor);
        return this.subtract(this.divide(divisor).multiply(divisor));
      };
      Long.prototype.mod = Long.prototype.modulo;
      Long.prototype.not = function not() {
        return Long.fromBits(~this.low, ~this.high, this.unsigned);
      };
      Long.prototype.and = function and(other) {
        if (!Long.isLong(other))
          other = Long.fromValue(other);
        return Long.fromBits(this.low & other.low, this.high & other.high, this.unsigned);
      };
      Long.prototype.or = function or(other) {
        if (!Long.isLong(other))
          other = Long.fromValue(other);
        return Long.fromBits(this.low | other.low, this.high | other.high, this.unsigned);
      };
      Long.prototype.xor = function xor(other) {
        if (!Long.isLong(other))
          other = Long.fromValue(other);
        return Long.fromBits(this.low ^ other.low, this.high ^ other.high, this.unsigned);
      };
      Long.prototype.shiftLeft = function shiftLeft(numBits) {
        if (Long.isLong(numBits))
          numBits = numBits.toInt();
        if ((numBits &= 63) === 0)
          return this;
        else if (numBits < 32)
          return Long.fromBits(this.low << numBits, this.high << numBits | this.low >>> 32 - numBits, this.unsigned);
        else
          return Long.fromBits(0, this.low << numBits - 32, this.unsigned);
      };
      Long.prototype.shl = Long.prototype.shiftLeft;
      Long.prototype.shiftRight = function shiftRight(numBits) {
        if (Long.isLong(numBits))
          numBits = numBits.toInt();
        if ((numBits &= 63) === 0)
          return this;
        else if (numBits < 32)
          return Long.fromBits(this.low >>> numBits | this.high << 32 - numBits, this.high >> numBits, this.unsigned);
        else
          return Long.fromBits(this.high >> numBits - 32, this.high >= 0 ? 0 : -1, this.unsigned);
      };
      Long.prototype.shr = Long.prototype.shiftRight;
      Long.prototype.shiftRightUnsigned = function shiftRightUnsigned(numBits) {
        if (Long.isLong(numBits))
          numBits = numBits.toInt();
        numBits &= 63;
        if (numBits === 0)
          return this;
        else {
          var high = this.high;
          if (numBits < 32) {
            var low = this.low;
            return Long.fromBits(low >>> numBits | high << 32 - numBits, high >>> numBits, this.unsigned);
          } else if (numBits === 32)
            return Long.fromBits(high, 0, this.unsigned);
          else
            return Long.fromBits(high >>> numBits - 32, 0, this.unsigned);
        }
      };
      Long.prototype.shru = Long.prototype.shiftRightUnsigned;
      Long.prototype.toSigned = function toSigned() {
        if (!this.unsigned)
          return this;
        return new Long(this.low, this.high, false);
      };
      Long.prototype.toUnsigned = function toUnsigned() {
        if (this.unsigned)
          return this;
        return new Long(this.low, this.high, true);
      };
      return Long;
    });
  });
  var require_hyper = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    exports3.Hyper = void 0;
    var _createClass = function() {
      function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
          var descriptor = props[i];
          descriptor.enumerable = descriptor.enumerable || false;
          descriptor.configurable = true;
          if ("value" in descriptor)
            descriptor.writable = true;
          Object.defineProperty(target, descriptor.key, descriptor);
        }
      }
      return function(Constructor, protoProps, staticProps) {
        if (protoProps)
          defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
          defineProperties(Constructor, staticProps);
        return Constructor;
      };
    }();
    var _get = function get(object, property, receiver) {
      if (object === null)
        object = Function.prototype;
      var desc = Object.getOwnPropertyDescriptor(object, property);
      if (desc === void 0) {
        var parent = Object.getPrototypeOf(object);
        if (parent === null) {
          return void 0;
        } else {
          return get(parent, property, receiver);
        }
      } else if ("value" in desc) {
        return desc.value;
      } else {
        var getter = desc.get;
        if (getter === void 0) {
          return void 0;
        }
        return getter.call(receiver);
      }
    };
    var _long = require_Long();
    var _long2 = _interopRequireDefault(_long);
    var _ioMixin = require_io_mixin();
    var _ioMixin2 = _interopRequireDefault(_ioMixin);
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : {default: obj};
    }
    function _classCallCheck(instance, Constructor) {
      if (!(instance instanceof Constructor)) {
        throw new TypeError("Cannot call a class as a function");
      }
    }
    function _possibleConstructorReturn(self2, call) {
      if (!self2) {
        throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      }
      return call && (typeof call === "object" || typeof call === "function") ? call : self2;
    }
    function _inherits(subClass, superClass) {
      if (typeof superClass !== "function" && superClass !== null) {
        throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
      }
      subClass.prototype = Object.create(superClass && superClass.prototype, {constructor: {value: subClass, enumerable: false, writable: true, configurable: true}});
      if (superClass)
        Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }
    var Hyper = exports3.Hyper = function(_Long) {
      _inherits(Hyper2, _Long);
      _createClass(Hyper2, null, [{
        key: "read",
        value: function read(io) {
          var high = io.readInt32BE();
          var low = io.readInt32BE();
          return this.fromBits(low, high);
        }
      }, {
        key: "write",
        value: function write(value, io) {
          if (!(value instanceof this)) {
            throw new Error("XDR Write Error: " + value + " is not a Hyper");
          }
          io.writeInt32BE(value.high);
          io.writeInt32BE(value.low);
        }
      }, {
        key: "fromString",
        value: function fromString(string) {
          if (!/^-?\d+$/.test(string)) {
            throw new Error("Invalid hyper string: " + string);
          }
          var result = _get(Hyper2.__proto__ || Object.getPrototypeOf(Hyper2), "fromString", this).call(this, string, false);
          return new this(result.low, result.high);
        }
      }, {
        key: "fromBits",
        value: function fromBits(low, high) {
          var result = _get(Hyper2.__proto__ || Object.getPrototypeOf(Hyper2), "fromBits", this).call(this, low, high, false);
          return new this(result.low, result.high);
        }
      }, {
        key: "isValid",
        value: function isValid(value) {
          return value instanceof this;
        }
      }]);
      function Hyper2(low, high) {
        _classCallCheck(this, Hyper2);
        return _possibleConstructorReturn(this, (Hyper2.__proto__ || Object.getPrototypeOf(Hyper2)).call(this, low, high, false));
      }
      return Hyper2;
    }(_long2.default);
    (0, _ioMixin2.default)(Hyper);
    Hyper.MAX_VALUE = new Hyper(_long2.default.MAX_VALUE.low, _long2.default.MAX_VALUE.high);
    Hyper.MIN_VALUE = new Hyper(_long2.default.MIN_VALUE.low, _long2.default.MIN_VALUE.high);
  });
  var require_unsigned_int = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    exports3.UnsignedInt = void 0;
    var _isNumber = require_isNumber();
    var _isNumber2 = _interopRequireDefault(_isNumber);
    var _ioMixin = require_io_mixin();
    var _ioMixin2 = _interopRequireDefault(_ioMixin);
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : {default: obj};
    }
    var UnsignedInt = exports3.UnsignedInt = {
      read: function read(io) {
        return io.readUInt32BE();
      },
      write: function write(value, io) {
        if (!(0, _isNumber2.default)(value)) {
          throw new Error("XDR Write Error: not a number");
        }
        if (Math.floor(value) !== value) {
          throw new Error("XDR Write Error: not an integer");
        }
        if (value < 0) {
          throw new Error("XDR Write Error: negative number " + value);
        }
        io.writeUInt32BE(value);
      },
      isValid: function isValid(value) {
        if (!(0, _isNumber2.default)(value)) {
          return false;
        }
        if (Math.floor(value) !== value) {
          return false;
        }
        return value >= UnsignedInt.MIN_VALUE && value <= UnsignedInt.MAX_VALUE;
      }
    };
    UnsignedInt.MAX_VALUE = Math.pow(2, 32) - 1;
    UnsignedInt.MIN_VALUE = 0;
    (0, _ioMixin2.default)(UnsignedInt);
  });
  var require_unsigned_hyper = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    exports3.UnsignedHyper = void 0;
    var _createClass = function() {
      function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
          var descriptor = props[i];
          descriptor.enumerable = descriptor.enumerable || false;
          descriptor.configurable = true;
          if ("value" in descriptor)
            descriptor.writable = true;
          Object.defineProperty(target, descriptor.key, descriptor);
        }
      }
      return function(Constructor, protoProps, staticProps) {
        if (protoProps)
          defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
          defineProperties(Constructor, staticProps);
        return Constructor;
      };
    }();
    var _get = function get(object, property, receiver) {
      if (object === null)
        object = Function.prototype;
      var desc = Object.getOwnPropertyDescriptor(object, property);
      if (desc === void 0) {
        var parent = Object.getPrototypeOf(object);
        if (parent === null) {
          return void 0;
        } else {
          return get(parent, property, receiver);
        }
      } else if ("value" in desc) {
        return desc.value;
      } else {
        var getter = desc.get;
        if (getter === void 0) {
          return void 0;
        }
        return getter.call(receiver);
      }
    };
    var _long = require_Long();
    var _long2 = _interopRequireDefault(_long);
    var _ioMixin = require_io_mixin();
    var _ioMixin2 = _interopRequireDefault(_ioMixin);
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : {default: obj};
    }
    function _classCallCheck(instance, Constructor) {
      if (!(instance instanceof Constructor)) {
        throw new TypeError("Cannot call a class as a function");
      }
    }
    function _possibleConstructorReturn(self2, call) {
      if (!self2) {
        throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      }
      return call && (typeof call === "object" || typeof call === "function") ? call : self2;
    }
    function _inherits(subClass, superClass) {
      if (typeof superClass !== "function" && superClass !== null) {
        throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
      }
      subClass.prototype = Object.create(superClass && superClass.prototype, {constructor: {value: subClass, enumerable: false, writable: true, configurable: true}});
      if (superClass)
        Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }
    var UnsignedHyper = exports3.UnsignedHyper = function(_Long) {
      _inherits(UnsignedHyper2, _Long);
      _createClass(UnsignedHyper2, null, [{
        key: "read",
        value: function read(io) {
          var high = io.readInt32BE();
          var low = io.readInt32BE();
          return this.fromBits(low, high);
        }
      }, {
        key: "write",
        value: function write(value, io) {
          if (!(value instanceof this)) {
            throw new Error("XDR Write Error: " + value + " is not an UnsignedHyper");
          }
          io.writeInt32BE(value.high);
          io.writeInt32BE(value.low);
        }
      }, {
        key: "fromString",
        value: function fromString(string) {
          if (!/^\d+$/.test(string)) {
            throw new Error("Invalid hyper string: " + string);
          }
          var result = _get(UnsignedHyper2.__proto__ || Object.getPrototypeOf(UnsignedHyper2), "fromString", this).call(this, string, true);
          return new this(result.low, result.high);
        }
      }, {
        key: "fromBits",
        value: function fromBits(low, high) {
          var result = _get(UnsignedHyper2.__proto__ || Object.getPrototypeOf(UnsignedHyper2), "fromBits", this).call(this, low, high, true);
          return new this(result.low, result.high);
        }
      }, {
        key: "isValid",
        value: function isValid(value) {
          return value instanceof this;
        }
      }]);
      function UnsignedHyper2(low, high) {
        _classCallCheck(this, UnsignedHyper2);
        return _possibleConstructorReturn(this, (UnsignedHyper2.__proto__ || Object.getPrototypeOf(UnsignedHyper2)).call(this, low, high, true));
      }
      return UnsignedHyper2;
    }(_long2.default);
    (0, _ioMixin2.default)(UnsignedHyper);
    UnsignedHyper.MAX_VALUE = new UnsignedHyper(_long2.default.MAX_UNSIGNED_VALUE.low, _long2.default.MAX_UNSIGNED_VALUE.high);
    UnsignedHyper.MIN_VALUE = new UnsignedHyper(_long2.default.MIN_VALUE.low, _long2.default.MIN_VALUE.high);
  });
  var require_float = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    exports3.Float = void 0;
    var _isNumber = require_isNumber();
    var _isNumber2 = _interopRequireDefault(_isNumber);
    var _ioMixin = require_io_mixin();
    var _ioMixin2 = _interopRequireDefault(_ioMixin);
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : {default: obj};
    }
    var Float = exports3.Float = {
      read: function read(io) {
        return io.readFloatBE();
      },
      write: function write(value, io) {
        if (!(0, _isNumber2.default)(value)) {
          throw new Error("XDR Write Error: not a number");
        }
        io.writeFloatBE(value);
      },
      isValid: function isValid(value) {
        return (0, _isNumber2.default)(value);
      }
    };
    (0, _ioMixin2.default)(Float);
  });
  var require_double = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    exports3.Double = void 0;
    var _isNumber = require_isNumber();
    var _isNumber2 = _interopRequireDefault(_isNumber);
    var _ioMixin = require_io_mixin();
    var _ioMixin2 = _interopRequireDefault(_ioMixin);
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : {default: obj};
    }
    var Double = exports3.Double = {
      read: function read(io) {
        return io.readDoubleBE();
      },
      write: function write(value, io) {
        if (!(0, _isNumber2.default)(value)) {
          throw new Error("XDR Write Error: not a number");
        }
        io.writeDoubleBE(value);
      },
      isValid: function isValid(value) {
        return (0, _isNumber2.default)(value);
      }
    };
    (0, _ioMixin2.default)(Double);
  });
  var require_quadruple = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    exports3.Quadruple = void 0;
    var _ioMixin = require_io_mixin();
    var _ioMixin2 = _interopRequireDefault(_ioMixin);
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : {default: obj};
    }
    var Quadruple = exports3.Quadruple = {
      read: function read() {
        throw new Error("XDR Read Error: quadruple not supported");
      },
      write: function write() {
        throw new Error("XDR Write Error: quadruple not supported");
      },
      isValid: function isValid() {
        return false;
      }
    };
    (0, _ioMixin2.default)(Quadruple);
  });
  var require_isBoolean = __commonJS2((exports3, module22) => {
    var baseGetTag = require_baseGetTag();
    var isObjectLike = require_isObjectLike();
    var boolTag = "[object Boolean]";
    function isBoolean(value) {
      return value === true || value === false || isObjectLike(value) && baseGetTag(value) == boolTag;
    }
    module22.exports = isBoolean;
  });
  var require_bool = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    exports3.Bool = void 0;
    var _isBoolean = require_isBoolean();
    var _isBoolean2 = _interopRequireDefault(_isBoolean);
    var _int = require_int();
    var _ioMixin = require_io_mixin();
    var _ioMixin2 = _interopRequireDefault(_ioMixin);
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : {default: obj};
    }
    var Bool = exports3.Bool = {
      read: function read(io) {
        var value = _int.Int.read(io);
        switch (value) {
          case 0:
            return false;
          case 1:
            return true;
          default:
            throw new Error("XDR Read Error: Got " + value + " when trying to read a bool");
        }
      },
      write: function write(value, io) {
        var intVal = value ? 1 : 0;
        return _int.Int.write(intVal, io);
      },
      isValid: function isValid(value) {
        return (0, _isBoolean2.default)(value);
      }
    };
    (0, _ioMixin2.default)(Bool);
  });
  var require_isString = __commonJS2((exports3, module22) => {
    var baseGetTag = require_baseGetTag();
    var isArray = require_isArray();
    var isObjectLike = require_isObjectLike();
    var stringTag = "[object String]";
    function isString(value) {
      return typeof value == "string" || !isArray(value) && isObjectLike(value) && baseGetTag(value) == stringTag;
    }
    module22.exports = isString;
  });
  var require_string = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    exports3.String = void 0;
    var _createClass = function() {
      function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
          var descriptor = props[i];
          descriptor.enumerable = descriptor.enumerable || false;
          descriptor.configurable = true;
          if ("value" in descriptor)
            descriptor.writable = true;
          Object.defineProperty(target, descriptor.key, descriptor);
        }
      }
      return function(Constructor, protoProps, staticProps) {
        if (protoProps)
          defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
          defineProperties(Constructor, staticProps);
        return Constructor;
      };
    }();
    var _isString = require_isString();
    var _isString2 = _interopRequireDefault(_isString);
    var _isArray = require_isArray();
    var _isArray2 = _interopRequireDefault(_isArray);
    var _int = require_int();
    var _unsignedInt = require_unsigned_int();
    var _util = require_util();
    var _ioMixin = require_io_mixin();
    var _ioMixin2 = _interopRequireDefault(_ioMixin);
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : {default: obj};
    }
    function _classCallCheck(instance, Constructor) {
      if (!(instance instanceof Constructor)) {
        throw new TypeError("Cannot call a class as a function");
      }
    }
    var String2 = exports3.String = function() {
      function String3() {
        var maxLength = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : _unsignedInt.UnsignedInt.MAX_VALUE;
        _classCallCheck(this, String3);
        this._maxLength = maxLength;
      }
      _createClass(String3, [{
        key: "read",
        value: function read(io) {
          var length = _int.Int.read(io);
          if (length > this._maxLength) {
            throw new Error("XDR Read Error: Saw " + length + " length String," + ("max allowed is " + this._maxLength));
          }
          var padding = (0, _util.calculatePadding)(length);
          var result = io.slice(length);
          (0, _util.slicePadding)(io, padding);
          return result.buffer();
        }
      }, {
        key: "readString",
        value: function readString(io) {
          return this.read(io).toString("utf8");
        }
      }, {
        key: "write",
        value: function write(value, io) {
          if (value.length > this._maxLength) {
            throw new Error("XDR Write Error: Got " + value.length + " bytes," + ("max allows is " + this._maxLength));
          }
          var buffer = void 0;
          if ((0, _isString2.default)(value)) {
            buffer = Buffer.from(value, "utf8");
          } else {
            buffer = Buffer.from(value);
          }
          _int.Int.write(buffer.length, io);
          io.writeBufferPadded(buffer);
        }
      }, {
        key: "isValid",
        value: function isValid(value) {
          var buffer = void 0;
          if ((0, _isString2.default)(value)) {
            buffer = Buffer.from(value, "utf8");
          } else if ((0, _isArray2.default)(value) || Buffer.isBuffer(value)) {
            buffer = Buffer.from(value);
          } else {
            return false;
          }
          return buffer.length <= this._maxLength;
        }
      }]);
      return String3;
    }();
    (0, _ioMixin2.default)(String2.prototype);
  });
  var require_opaque = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    exports3.Opaque = void 0;
    var _createClass = function() {
      function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
          var descriptor = props[i];
          descriptor.enumerable = descriptor.enumerable || false;
          descriptor.configurable = true;
          if ("value" in descriptor)
            descriptor.writable = true;
          Object.defineProperty(target, descriptor.key, descriptor);
        }
      }
      return function(Constructor, protoProps, staticProps) {
        if (protoProps)
          defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
          defineProperties(Constructor, staticProps);
        return Constructor;
      };
    }();
    var _util = require_util();
    var _ioMixin = require_io_mixin();
    var _ioMixin2 = _interopRequireDefault(_ioMixin);
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : {default: obj};
    }
    function _classCallCheck(instance, Constructor) {
      if (!(instance instanceof Constructor)) {
        throw new TypeError("Cannot call a class as a function");
      }
    }
    var Opaque = exports3.Opaque = function() {
      function Opaque2(length) {
        _classCallCheck(this, Opaque2);
        this._length = length;
        this._padding = (0, _util.calculatePadding)(length);
      }
      _createClass(Opaque2, [{
        key: "read",
        value: function read(io) {
          var result = io.slice(this._length);
          (0, _util.slicePadding)(io, this._padding);
          return result.buffer();
        }
      }, {
        key: "write",
        value: function write(value, io) {
          if (value.length !== this._length) {
            throw new Error("XDR Write Error: Got " + value.length + " bytes, expected " + this._length);
          }
          io.writeBufferPadded(value);
        }
      }, {
        key: "isValid",
        value: function isValid(value) {
          return Buffer.isBuffer(value) && value.length === this._length;
        }
      }]);
      return Opaque2;
    }();
    (0, _ioMixin2.default)(Opaque.prototype);
  });
  var require_var_opaque = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    exports3.VarOpaque = void 0;
    var _createClass = function() {
      function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
          var descriptor = props[i];
          descriptor.enumerable = descriptor.enumerable || false;
          descriptor.configurable = true;
          if ("value" in descriptor)
            descriptor.writable = true;
          Object.defineProperty(target, descriptor.key, descriptor);
        }
      }
      return function(Constructor, protoProps, staticProps) {
        if (protoProps)
          defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
          defineProperties(Constructor, staticProps);
        return Constructor;
      };
    }();
    var _int = require_int();
    var _unsignedInt = require_unsigned_int();
    var _util = require_util();
    var _ioMixin = require_io_mixin();
    var _ioMixin2 = _interopRequireDefault(_ioMixin);
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : {default: obj};
    }
    function _classCallCheck(instance, Constructor) {
      if (!(instance instanceof Constructor)) {
        throw new TypeError("Cannot call a class as a function");
      }
    }
    var VarOpaque = exports3.VarOpaque = function() {
      function VarOpaque2() {
        var maxLength = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : _unsignedInt.UnsignedInt.MAX_VALUE;
        _classCallCheck(this, VarOpaque2);
        this._maxLength = maxLength;
      }
      _createClass(VarOpaque2, [{
        key: "read",
        value: function read(io) {
          var length = _int.Int.read(io);
          if (length > this._maxLength) {
            throw new Error("XDR Read Error: Saw " + length + " length VarOpaque," + ("max allowed is " + this._maxLength));
          }
          var padding = (0, _util.calculatePadding)(length);
          var result = io.slice(length);
          (0, _util.slicePadding)(io, padding);
          return result.buffer();
        }
      }, {
        key: "write",
        value: function write(value, io) {
          if (value.length > this._maxLength) {
            throw new Error("XDR Write Error: Got " + value.length + " bytes," + ("max allows is " + this._maxLength));
          }
          _int.Int.write(value.length, io);
          io.writeBufferPadded(value);
        }
      }, {
        key: "isValid",
        value: function isValid(value) {
          return Buffer.isBuffer(value) && value.length <= this._maxLength;
        }
      }]);
      return VarOpaque2;
    }();
    (0, _ioMixin2.default)(VarOpaque.prototype);
  });
  var require_arrayEach = __commonJS2((exports3, module22) => {
    function arrayEach(array, iteratee) {
      var index = -1, length = array == null ? 0 : array.length;
      while (++index < length) {
        if (iteratee(array[index], index, array) === false) {
          break;
        }
      }
      return array;
    }
    module22.exports = arrayEach;
  });
  var require_castFunction = __commonJS2((exports3, module22) => {
    var identity = require_identity();
    function castFunction(value) {
      return typeof value == "function" ? value : identity;
    }
    module22.exports = castFunction;
  });
  var require_forEach = __commonJS2((exports3, module22) => {
    var arrayEach = require_arrayEach();
    var baseEach = require_baseEach();
    var castFunction = require_castFunction();
    var isArray = require_isArray();
    function forEach(collection, iteratee) {
      var func = isArray(collection) ? arrayEach : baseEach;
      return func(collection, castFunction(iteratee));
    }
    module22.exports = forEach;
  });
  var require_each = __commonJS2((exports3, module22) => {
    module22.exports = require_forEach();
  });
  var require_trimmedEndIndex = __commonJS2((exports3, module22) => {
    var reWhitespace = /\s/;
    function trimmedEndIndex(string) {
      var index = string.length;
      while (index-- && reWhitespace.test(string.charAt(index))) {
      }
      return index;
    }
    module22.exports = trimmedEndIndex;
  });
  var require_baseTrim = __commonJS2((exports3, module22) => {
    var trimmedEndIndex = require_trimmedEndIndex();
    var reTrimStart = /^\s+/;
    function baseTrim(string) {
      return string ? string.slice(0, trimmedEndIndex(string) + 1).replace(reTrimStart, "") : string;
    }
    module22.exports = baseTrim;
  });
  var require_toNumber = __commonJS2((exports3, module22) => {
    var baseTrim = require_baseTrim();
    var isObject = require_isObject();
    var isSymbol = require_isSymbol();
    var NAN = 0 / 0;
    var reIsBadHex = /^[-+]0x[0-9a-f]+$/i;
    var reIsBinary = /^0b[01]+$/i;
    var reIsOctal = /^0o[0-7]+$/i;
    var freeParseInt = parseInt;
    function toNumber(value) {
      if (typeof value == "number") {
        return value;
      }
      if (isSymbol(value)) {
        return NAN;
      }
      if (isObject(value)) {
        var other = typeof value.valueOf == "function" ? value.valueOf() : value;
        value = isObject(other) ? other + "" : other;
      }
      if (typeof value != "string") {
        return value === 0 ? value : +value;
      }
      value = baseTrim(value);
      var isBinary = reIsBinary.test(value);
      return isBinary || reIsOctal.test(value) ? freeParseInt(value.slice(2), isBinary ? 2 : 8) : reIsBadHex.test(value) ? NAN : +value;
    }
    module22.exports = toNumber;
  });
  var require_toFinite = __commonJS2((exports3, module22) => {
    var toNumber = require_toNumber();
    var INFINITY = 1 / 0;
    var MAX_INTEGER = 17976931348623157e292;
    function toFinite(value) {
      if (!value) {
        return value === 0 ? value : 0;
      }
      value = toNumber(value);
      if (value === INFINITY || value === -INFINITY) {
        var sign = value < 0 ? -1 : 1;
        return sign * MAX_INTEGER;
      }
      return value === value ? value : 0;
    }
    module22.exports = toFinite;
  });
  var require_toInteger = __commonJS2((exports3, module22) => {
    var toFinite = require_toFinite();
    function toInteger(value) {
      var result = toFinite(value), remainder = result % 1;
      return result === result ? remainder ? result - remainder : result : 0;
    }
    module22.exports = toInteger;
  });
  var require_times = __commonJS2((exports3, module22) => {
    var baseTimes = require_baseTimes();
    var castFunction = require_castFunction();
    var toInteger = require_toInteger();
    var MAX_SAFE_INTEGER = 9007199254740991;
    var MAX_ARRAY_LENGTH = 4294967295;
    var nativeMin = Math.min;
    function times(n, iteratee) {
      n = toInteger(n);
      if (n < 1 || n > MAX_SAFE_INTEGER) {
        return [];
      }
      var index = MAX_ARRAY_LENGTH, length = nativeMin(n, MAX_ARRAY_LENGTH);
      iteratee = castFunction(iteratee);
      n -= MAX_ARRAY_LENGTH;
      var result = baseTimes(length, iteratee);
      while (++index < n) {
        iteratee(index);
      }
      return result;
    }
    module22.exports = times;
  });
  var require_array = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    exports3.Array = void 0;
    var _createClass = function() {
      function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
          var descriptor = props[i];
          descriptor.enumerable = descriptor.enumerable || false;
          descriptor.configurable = true;
          if ("value" in descriptor)
            descriptor.writable = true;
          Object.defineProperty(target, descriptor.key, descriptor);
        }
      }
      return function(Constructor, protoProps, staticProps) {
        if (protoProps)
          defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
          defineProperties(Constructor, staticProps);
        return Constructor;
      };
    }();
    var _every = require_every();
    var _every2 = _interopRequireDefault(_every);
    var _each = require_each();
    var _each2 = _interopRequireDefault(_each);
    var _times = require_times();
    var _times2 = _interopRequireDefault(_times);
    var _isArray = require_isArray();
    var _isArray2 = _interopRequireDefault(_isArray);
    var _ioMixin = require_io_mixin();
    var _ioMixin2 = _interopRequireDefault(_ioMixin);
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : {default: obj};
    }
    function _classCallCheck(instance, Constructor) {
      if (!(instance instanceof Constructor)) {
        throw new TypeError("Cannot call a class as a function");
      }
    }
    var Array2 = exports3.Array = function() {
      function Array3(childType, length) {
        _classCallCheck(this, Array3);
        this._childType = childType;
        this._length = length;
      }
      _createClass(Array3, [{
        key: "read",
        value: function read(io) {
          var _this = this;
          return (0, _times2.default)(this._length, function() {
            return _this._childType.read(io);
          });
        }
      }, {
        key: "write",
        value: function write(value, io) {
          var _this2 = this;
          if (!(0, _isArray2.default)(value)) {
            throw new Error("XDR Write Error: value is not array");
          }
          if (value.length !== this._length) {
            throw new Error("XDR Write Error: Got array of size " + value.length + "," + ("expected " + this._length));
          }
          (0, _each2.default)(value, function(child) {
            return _this2._childType.write(child, io);
          });
        }
      }, {
        key: "isValid",
        value: function isValid(value) {
          var _this3 = this;
          if (!(0, _isArray2.default)(value)) {
            return false;
          }
          if (value.length !== this._length) {
            return false;
          }
          return (0, _every2.default)(value, function(child) {
            return _this3._childType.isValid(child);
          });
        }
      }]);
      return Array3;
    }();
    (0, _ioMixin2.default)(Array2.prototype);
  });
  var require_var_array = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    exports3.VarArray = void 0;
    var _createClass = function() {
      function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
          var descriptor = props[i];
          descriptor.enumerable = descriptor.enumerable || false;
          descriptor.configurable = true;
          if ("value" in descriptor)
            descriptor.writable = true;
          Object.defineProperty(target, descriptor.key, descriptor);
        }
      }
      return function(Constructor, protoProps, staticProps) {
        if (protoProps)
          defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
          defineProperties(Constructor, staticProps);
        return Constructor;
      };
    }();
    var _every = require_every();
    var _every2 = _interopRequireDefault(_every);
    var _each = require_each();
    var _each2 = _interopRequireDefault(_each);
    var _times = require_times();
    var _times2 = _interopRequireDefault(_times);
    var _isArray = require_isArray();
    var _isArray2 = _interopRequireDefault(_isArray);
    var _unsignedInt = require_unsigned_int();
    var _int = require_int();
    var _ioMixin = require_io_mixin();
    var _ioMixin2 = _interopRequireDefault(_ioMixin);
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : {default: obj};
    }
    function _classCallCheck(instance, Constructor) {
      if (!(instance instanceof Constructor)) {
        throw new TypeError("Cannot call a class as a function");
      }
    }
    var VarArray = exports3.VarArray = function() {
      function VarArray2(childType) {
        var maxLength = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : _unsignedInt.UnsignedInt.MAX_VALUE;
        _classCallCheck(this, VarArray2);
        this._childType = childType;
        this._maxLength = maxLength;
      }
      _createClass(VarArray2, [{
        key: "read",
        value: function read(io) {
          var _this = this;
          var length = _int.Int.read(io);
          if (length > this._maxLength) {
            throw new Error("XDR Read Error: Saw " + length + " length VarArray," + ("max allowed is " + this._maxLength));
          }
          return (0, _times2.default)(length, function() {
            return _this._childType.read(io);
          });
        }
      }, {
        key: "write",
        value: function write(value, io) {
          var _this2 = this;
          if (!(0, _isArray2.default)(value)) {
            throw new Error("XDR Write Error: value is not array");
          }
          if (value.length > this._maxLength) {
            throw new Error("XDR Write Error: Got array of size " + value.length + "," + ("max allowed is " + this._maxLength));
          }
          _int.Int.write(value.length, io);
          (0, _each2.default)(value, function(child) {
            return _this2._childType.write(child, io);
          });
        }
      }, {
        key: "isValid",
        value: function isValid(value) {
          var _this3 = this;
          if (!(0, _isArray2.default)(value)) {
            return false;
          }
          if (value.length > this._maxLength) {
            return false;
          }
          return (0, _every2.default)(value, function(child) {
            return _this3._childType.isValid(child);
          });
        }
      }]);
      return VarArray2;
    }();
    (0, _ioMixin2.default)(VarArray.prototype);
  });
  var require_isNull = __commonJS2((exports3, module22) => {
    function isNull(value) {
      return value === null;
    }
    module22.exports = isNull;
  });
  var require_isUndefined = __commonJS2((exports3, module22) => {
    function isUndefined(value) {
      return value === void 0;
    }
    module22.exports = isUndefined;
  });
  var require_option = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    exports3.Option = void 0;
    var _createClass = function() {
      function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
          var descriptor = props[i];
          descriptor.enumerable = descriptor.enumerable || false;
          descriptor.configurable = true;
          if ("value" in descriptor)
            descriptor.writable = true;
          Object.defineProperty(target, descriptor.key, descriptor);
        }
      }
      return function(Constructor, protoProps, staticProps) {
        if (protoProps)
          defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
          defineProperties(Constructor, staticProps);
        return Constructor;
      };
    }();
    var _isNull = require_isNull();
    var _isNull2 = _interopRequireDefault(_isNull);
    var _isUndefined = require_isUndefined();
    var _isUndefined2 = _interopRequireDefault(_isUndefined);
    var _bool = require_bool();
    var _ioMixin = require_io_mixin();
    var _ioMixin2 = _interopRequireDefault(_ioMixin);
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : {default: obj};
    }
    function _classCallCheck(instance, Constructor) {
      if (!(instance instanceof Constructor)) {
        throw new TypeError("Cannot call a class as a function");
      }
    }
    var Option = exports3.Option = function() {
      function Option2(childType) {
        _classCallCheck(this, Option2);
        this._childType = childType;
      }
      _createClass(Option2, [{
        key: "read",
        value: function read(io) {
          if (_bool.Bool.read(io)) {
            return this._childType.read(io);
          }
          return void 0;
        }
      }, {
        key: "write",
        value: function write(value, io) {
          var isPresent = !((0, _isNull2.default)(value) || (0, _isUndefined2.default)(value));
          _bool.Bool.write(isPresent, io);
          if (isPresent) {
            this._childType.write(value, io);
          }
        }
      }, {
        key: "isValid",
        value: function isValid(value) {
          if ((0, _isNull2.default)(value)) {
            return true;
          }
          if ((0, _isUndefined2.default)(value)) {
            return true;
          }
          return this._childType.isValid(value);
        }
      }]);
      return Option2;
    }();
    (0, _ioMixin2.default)(Option.prototype);
  });
  var require_void = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    exports3.Void = void 0;
    var _isUndefined = require_isUndefined();
    var _isUndefined2 = _interopRequireDefault(_isUndefined);
    var _ioMixin = require_io_mixin();
    var _ioMixin2 = _interopRequireDefault(_ioMixin);
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : {default: obj};
    }
    var Void = exports3.Void = {
      read: function read() {
        return void 0;
      },
      write: function write(value) {
        if (!(0, _isUndefined2.default)(value)) {
          throw new Error("XDR Write Error: trying to write value to a void slot");
        }
      },
      isValid: function isValid(value) {
        return (0, _isUndefined2.default)(value);
      }
    };
    (0, _ioMixin2.default)(Void);
  });
  var require_baseValues = __commonJS2((exports3, module22) => {
    var arrayMap = require_arrayMap();
    function baseValues(object, props) {
      return arrayMap(props, function(key) {
        return object[key];
      });
    }
    module22.exports = baseValues;
  });
  var require_values = __commonJS2((exports3, module22) => {
    var baseValues = require_baseValues();
    var keys = require_keys();
    function values(object) {
      return object == null ? [] : baseValues(object, keys(object));
    }
    module22.exports = values;
  });
  var require_enum = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    exports3.Enum = void 0;
    var _createClass = function() {
      function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
          var descriptor = props[i];
          descriptor.enumerable = descriptor.enumerable || false;
          descriptor.configurable = true;
          if ("value" in descriptor)
            descriptor.writable = true;
          Object.defineProperty(target, descriptor.key, descriptor);
        }
      }
      return function(Constructor, protoProps, staticProps) {
        if (protoProps)
          defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
          defineProperties(Constructor, staticProps);
        return Constructor;
      };
    }();
    var _each = require_each();
    var _each2 = _interopRequireDefault(_each);
    var _values = require_values();
    var _values2 = _interopRequireDefault(_values);
    var _int = require_int();
    var _ioMixin = require_io_mixin();
    var _ioMixin2 = _interopRequireDefault(_ioMixin);
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : {default: obj};
    }
    function _possibleConstructorReturn(self2, call) {
      if (!self2) {
        throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      }
      return call && (typeof call === "object" || typeof call === "function") ? call : self2;
    }
    function _inherits(subClass, superClass) {
      if (typeof superClass !== "function" && superClass !== null) {
        throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
      }
      subClass.prototype = Object.create(superClass && superClass.prototype, {constructor: {value: subClass, enumerable: false, writable: true, configurable: true}});
      if (superClass)
        Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }
    function _classCallCheck(instance, Constructor) {
      if (!(instance instanceof Constructor)) {
        throw new TypeError("Cannot call a class as a function");
      }
    }
    var Enum = exports3.Enum = function() {
      function Enum2(name, value) {
        _classCallCheck(this, Enum2);
        this.name = name;
        this.value = value;
      }
      _createClass(Enum2, null, [{
        key: "read",
        value: function read(io) {
          var intVal = _int.Int.read(io);
          if (!this._byValue.has(intVal)) {
            throw new Error("XDR Read Error: Unknown " + this.enumName + " member for value " + intVal);
          }
          return this._byValue.get(intVal);
        }
      }, {
        key: "write",
        value: function write(value, io) {
          if (!(value instanceof this)) {
            throw new Error("XDR Write Error: Unknown " + value + " is not a " + this.enumName);
          }
          _int.Int.write(value.value, io);
        }
      }, {
        key: "isValid",
        value: function isValid(value) {
          return value instanceof this;
        }
      }, {
        key: "members",
        value: function members() {
          return this._members;
        }
      }, {
        key: "values",
        value: function values() {
          return (0, _values2.default)(this._members);
        }
      }, {
        key: "fromName",
        value: function fromName(name) {
          var result = this._members[name];
          if (!result) {
            throw new Error(name + " is not a member of " + this.enumName);
          }
          return result;
        }
      }, {
        key: "fromValue",
        value: function fromValue(value) {
          var result = this._byValue.get(value);
          if (!result) {
            throw new Error(value + " is not a value of any member of " + this.enumName);
          }
          return result;
        }
      }, {
        key: "create",
        value: function create(context, name, members) {
          var ChildEnum = function(_Enum) {
            _inherits(ChildEnum2, _Enum);
            function ChildEnum2() {
              _classCallCheck(this, ChildEnum2);
              return _possibleConstructorReturn(this, (ChildEnum2.__proto__ || Object.getPrototypeOf(ChildEnum2)).apply(this, arguments));
            }
            return ChildEnum2;
          }(Enum2);
          ChildEnum.enumName = name;
          context.results[name] = ChildEnum;
          ChildEnum._members = {};
          ChildEnum._byValue = new Map();
          (0, _each2.default)(members, function(value, key) {
            var inst = new ChildEnum(key, value);
            ChildEnum._members[key] = inst;
            ChildEnum._byValue.set(value, inst);
            ChildEnum[key] = function() {
              return inst;
            };
          });
          return ChildEnum;
        }
      }]);
      return Enum2;
    }();
    (0, _ioMixin2.default)(Enum);
  });
  var require_baseMap = __commonJS2((exports3, module22) => {
    var baseEach = require_baseEach();
    var isArrayLike = require_isArrayLike();
    function baseMap(collection, iteratee) {
      var index = -1, result = isArrayLike(collection) ? Array(collection.length) : [];
      baseEach(collection, function(value, key, collection2) {
        result[++index] = iteratee(value, key, collection2);
      });
      return result;
    }
    module22.exports = baseMap;
  });
  var require_map = __commonJS2((exports3, module22) => {
    var arrayMap = require_arrayMap();
    var baseIteratee = require_baseIteratee();
    var baseMap = require_baseMap();
    var isArray = require_isArray();
    function map(collection, iteratee) {
      var func = isArray(collection) ? arrayMap : baseMap;
      return func(collection, baseIteratee(iteratee, 3));
    }
    module22.exports = map;
  });
  var require_fromPairs = __commonJS2((exports3, module22) => {
    function fromPairs(pairs) {
      var index = -1, length = pairs == null ? 0 : pairs.length, result = {};
      while (++index < length) {
        var pair = pairs[index];
        result[pair[0]] = pair[1];
      }
      return result;
    }
    module22.exports = fromPairs;
  });
  var require_reference = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    var _createClass = function() {
      function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
          var descriptor = props[i];
          descriptor.enumerable = descriptor.enumerable || false;
          descriptor.configurable = true;
          if ("value" in descriptor)
            descriptor.writable = true;
          Object.defineProperty(target, descriptor.key, descriptor);
        }
      }
      return function(Constructor, protoProps, staticProps) {
        if (protoProps)
          defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
          defineProperties(Constructor, staticProps);
        return Constructor;
      };
    }();
    function _classCallCheck(instance, Constructor) {
      if (!(instance instanceof Constructor)) {
        throw new TypeError("Cannot call a class as a function");
      }
    }
    var Reference = exports3.Reference = function() {
      function Reference2() {
        _classCallCheck(this, Reference2);
      }
      _createClass(Reference2, [{
        key: "resolve",
        value: function resolve() {
          throw new Error("implement resolve in child class");
        }
      }]);
      return Reference2;
    }();
  });
  var require_struct = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    exports3.Struct = void 0;
    var _slicedToArray = function() {
      function sliceIterator(arr, i) {
        var _arr = [];
        var _n = true;
        var _d = false;
        var _e = void 0;
        try {
          for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
            _arr.push(_s.value);
            if (i && _arr.length === i)
              break;
          }
        } catch (err) {
          _d = true;
          _e = err;
        } finally {
          try {
            if (!_n && _i["return"])
              _i["return"]();
          } finally {
            if (_d)
              throw _e;
          }
        }
        return _arr;
      }
      return function(arr, i) {
        if (Array.isArray(arr)) {
          return arr;
        } else if (Symbol.iterator in Object(arr)) {
          return sliceIterator(arr, i);
        } else {
          throw new TypeError("Invalid attempt to destructure non-iterable instance");
        }
      };
    }();
    var _createClass = function() {
      function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
          var descriptor = props[i];
          descriptor.enumerable = descriptor.enumerable || false;
          descriptor.configurable = true;
          if ("value" in descriptor)
            descriptor.writable = true;
          Object.defineProperty(target, descriptor.key, descriptor);
        }
      }
      return function(Constructor, protoProps, staticProps) {
        if (protoProps)
          defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
          defineProperties(Constructor, staticProps);
        return Constructor;
      };
    }();
    var _each = require_each();
    var _each2 = _interopRequireDefault(_each);
    var _map = require_map();
    var _map2 = _interopRequireDefault(_map);
    var _isUndefined = require_isUndefined();
    var _isUndefined2 = _interopRequireDefault(_isUndefined);
    var _fromPairs = require_fromPairs();
    var _fromPairs2 = _interopRequireDefault(_fromPairs);
    var _reference = require_reference();
    var _ioMixin = require_io_mixin();
    var _ioMixin2 = _interopRequireDefault(_ioMixin);
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : {default: obj};
    }
    function _possibleConstructorReturn(self2, call) {
      if (!self2) {
        throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      }
      return call && (typeof call === "object" || typeof call === "function") ? call : self2;
    }
    function _inherits(subClass, superClass) {
      if (typeof superClass !== "function" && superClass !== null) {
        throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
      }
      subClass.prototype = Object.create(superClass && superClass.prototype, {constructor: {value: subClass, enumerable: false, writable: true, configurable: true}});
      if (superClass)
        Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }
    function _classCallCheck(instance, Constructor) {
      if (!(instance instanceof Constructor)) {
        throw new TypeError("Cannot call a class as a function");
      }
    }
    var Struct = exports3.Struct = function() {
      function Struct2(attributes) {
        _classCallCheck(this, Struct2);
        this._attributes = attributes || {};
      }
      _createClass(Struct2, null, [{
        key: "read",
        value: function read(io) {
          var fields = (0, _map2.default)(this._fields, function(field) {
            var _field = _slicedToArray(field, 2), name = _field[0], type = _field[1];
            var value = type.read(io);
            return [name, value];
          });
          return new this((0, _fromPairs2.default)(fields));
        }
      }, {
        key: "write",
        value: function write(value, io) {
          if (!(value instanceof this)) {
            throw new Error("XDR Write Error: " + value + " is not a " + this.structName);
          }
          (0, _each2.default)(this._fields, function(field) {
            var _field2 = _slicedToArray(field, 2), name = _field2[0], type = _field2[1];
            var attribute = value._attributes[name];
            type.write(attribute, io);
          });
        }
      }, {
        key: "isValid",
        value: function isValid(value) {
          return value instanceof this;
        }
      }, {
        key: "create",
        value: function create(context, name, fields) {
          var ChildStruct = function(_Struct) {
            _inherits(ChildStruct2, _Struct);
            function ChildStruct2() {
              _classCallCheck(this, ChildStruct2);
              return _possibleConstructorReturn(this, (ChildStruct2.__proto__ || Object.getPrototypeOf(ChildStruct2)).apply(this, arguments));
            }
            return ChildStruct2;
          }(Struct2);
          ChildStruct.structName = name;
          context.results[name] = ChildStruct;
          ChildStruct._fields = fields.map(function(_ref) {
            var _ref2 = _slicedToArray(_ref, 2), fieldName = _ref2[0], field = _ref2[1];
            if (field instanceof _reference.Reference) {
              field = field.resolve(context);
            }
            return [fieldName, field];
          });
          (0, _each2.default)(ChildStruct._fields, function(field) {
            var _field3 = _slicedToArray(field, 1), fieldName = _field3[0];
            ChildStruct.prototype[fieldName] = getReadOrWriteAttribute(fieldName);
          });
          return ChildStruct;
        }
      }]);
      return Struct2;
    }();
    (0, _ioMixin2.default)(Struct);
    function getReadOrWriteAttribute(name) {
      return function readOrWriteAttribute(value) {
        if (!(0, _isUndefined2.default)(value)) {
          this._attributes[name] = value;
        }
        return this._attributes[name];
      };
    }
  });
  var require_union = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    exports3.Union = void 0;
    var _slicedToArray = function() {
      function sliceIterator(arr, i) {
        var _arr = [];
        var _n = true;
        var _d = false;
        var _e = void 0;
        try {
          for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
            _arr.push(_s.value);
            if (i && _arr.length === i)
              break;
          }
        } catch (err) {
          _d = true;
          _e = err;
        } finally {
          try {
            if (!_n && _i["return"])
              _i["return"]();
          } finally {
            if (_d)
              throw _e;
          }
        }
        return _arr;
      }
      return function(arr, i) {
        if (Array.isArray(arr)) {
          return arr;
        } else if (Symbol.iterator in Object(arr)) {
          return sliceIterator(arr, i);
        } else {
          throw new TypeError("Invalid attempt to destructure non-iterable instance");
        }
      };
    }();
    var _createClass = function() {
      function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
          var descriptor = props[i];
          descriptor.enumerable = descriptor.enumerable || false;
          descriptor.configurable = true;
          if ("value" in descriptor)
            descriptor.writable = true;
          Object.defineProperty(target, descriptor.key, descriptor);
        }
      }
      return function(Constructor, protoProps, staticProps) {
        if (protoProps)
          defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
          defineProperties(Constructor, staticProps);
        return Constructor;
      };
    }();
    var _each = require_each();
    var _each2 = _interopRequireDefault(_each);
    var _isUndefined = require_isUndefined();
    var _isUndefined2 = _interopRequireDefault(_isUndefined);
    var _isString = require_isString();
    var _isString2 = _interopRequireDefault(_isString);
    var _void = require_void();
    var _reference = require_reference();
    var _ioMixin = require_io_mixin();
    var _ioMixin2 = _interopRequireDefault(_ioMixin);
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : {default: obj};
    }
    function _possibleConstructorReturn(self2, call) {
      if (!self2) {
        throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      }
      return call && (typeof call === "object" || typeof call === "function") ? call : self2;
    }
    function _inherits(subClass, superClass) {
      if (typeof superClass !== "function" && superClass !== null) {
        throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
      }
      subClass.prototype = Object.create(superClass && superClass.prototype, {constructor: {value: subClass, enumerable: false, writable: true, configurable: true}});
      if (superClass)
        Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }
    function _classCallCheck(instance, Constructor) {
      if (!(instance instanceof Constructor)) {
        throw new TypeError("Cannot call a class as a function");
      }
    }
    var Union = exports3.Union = function() {
      function Union2(aSwitch, value) {
        _classCallCheck(this, Union2);
        this.set(aSwitch, value);
      }
      _createClass(Union2, [{
        key: "set",
        value: function set(aSwitch, value) {
          if ((0, _isString2.default)(aSwitch)) {
            aSwitch = this.constructor._switchOn.fromName(aSwitch);
          }
          this._switch = aSwitch;
          this._arm = this.constructor.armForSwitch(this._switch);
          this._armType = this.constructor.armTypeForArm(this._arm);
          this._value = value;
        }
      }, {
        key: "get",
        value: function get() {
          var armName = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : this._arm;
          if (this._arm !== _void.Void && this._arm !== armName) {
            throw new Error(armName + " not set");
          }
          return this._value;
        }
      }, {
        key: "switch",
        value: function _switch() {
          return this._switch;
        }
      }, {
        key: "arm",
        value: function arm() {
          return this._arm;
        }
      }, {
        key: "armType",
        value: function armType() {
          return this._armType;
        }
      }, {
        key: "value",
        value: function value() {
          return this._value;
        }
      }], [{
        key: "armForSwitch",
        value: function armForSwitch(aSwitch) {
          if (this._switches.has(aSwitch)) {
            return this._switches.get(aSwitch);
          }
          if (this._defaultArm) {
            return this._defaultArm;
          }
          throw new Error("Bad union switch: " + aSwitch);
        }
      }, {
        key: "armTypeForArm",
        value: function armTypeForArm(arm) {
          if (arm === _void.Void) {
            return _void.Void;
          }
          return this._arms[arm];
        }
      }, {
        key: "read",
        value: function read(io) {
          var aSwitch = this._switchOn.read(io);
          var arm = this.armForSwitch(aSwitch);
          var armType = this.armTypeForArm(arm);
          var value = void 0;
          if (!(0, _isUndefined2.default)(armType)) {
            value = armType.read(io);
          } else {
            value = arm.read(io);
          }
          return new this(aSwitch, value);
        }
      }, {
        key: "write",
        value: function write(value, io) {
          if (!(value instanceof this)) {
            throw new Error("XDR Write Error: " + value + " is not a " + this.unionName);
          }
          this._switchOn.write(value.switch(), io);
          value.armType().write(value.value(), io);
        }
      }, {
        key: "isValid",
        value: function isValid(value) {
          return value instanceof this;
        }
      }, {
        key: "create",
        value: function create(context, name, config2) {
          var ChildUnion = function(_Union) {
            _inherits(ChildUnion2, _Union);
            function ChildUnion2() {
              _classCallCheck(this, ChildUnion2);
              return _possibleConstructorReturn(this, (ChildUnion2.__proto__ || Object.getPrototypeOf(ChildUnion2)).apply(this, arguments));
            }
            return ChildUnion2;
          }(Union2);
          ChildUnion.unionName = name;
          context.results[name] = ChildUnion;
          if (config2.switchOn instanceof _reference.Reference) {
            ChildUnion._switchOn = config2.switchOn.resolve(context);
          } else {
            ChildUnion._switchOn = config2.switchOn;
          }
          ChildUnion._switches = new Map();
          ChildUnion._arms = {};
          (0, _each2.default)(config2.arms, function(value, armsName) {
            if (value instanceof _reference.Reference) {
              value = value.resolve(context);
            }
            ChildUnion._arms[armsName] = value;
          });
          var defaultArm = config2.defaultArm;
          if (defaultArm instanceof _reference.Reference) {
            defaultArm = defaultArm.resolve(context);
          }
          ChildUnion._defaultArm = defaultArm;
          (0, _each2.default)(config2.switches, function(_ref) {
            var _ref2 = _slicedToArray(_ref, 2), aSwitch = _ref2[0], armName = _ref2[1];
            if ((0, _isString2.default)(aSwitch)) {
              aSwitch = ChildUnion._switchOn.fromName(aSwitch);
            }
            ChildUnion._switches.set(aSwitch, armName);
          });
          if (!(0, _isUndefined2.default)(ChildUnion._switchOn.values)) {
            (0, _each2.default)(ChildUnion._switchOn.values(), function(aSwitch) {
              ChildUnion[aSwitch.name] = function(value) {
                return new ChildUnion(aSwitch, value);
              };
              ChildUnion.prototype[aSwitch.name] = function set(value) {
                return this.set(aSwitch, value);
              };
            });
          }
          (0, _each2.default)(ChildUnion._arms, function(type, armsName) {
            if (type === _void.Void) {
              return;
            }
            ChildUnion.prototype[armsName] = function get() {
              return this.get(armsName);
            };
          });
          return ChildUnion;
        }
      }]);
      return Union2;
    }();
    (0, _ioMixin2.default)(Union);
  });
  var require_types = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    var _int = require_int();
    Object.keys(_int).forEach(function(key) {
      if (key === "default" || key === "__esModule")
        return;
      Object.defineProperty(exports3, key, {
        enumerable: true,
        get: function get() {
          return _int[key];
        }
      });
    });
    var _hyper = require_hyper();
    Object.keys(_hyper).forEach(function(key) {
      if (key === "default" || key === "__esModule")
        return;
      Object.defineProperty(exports3, key, {
        enumerable: true,
        get: function get() {
          return _hyper[key];
        }
      });
    });
    var _unsignedInt = require_unsigned_int();
    Object.keys(_unsignedInt).forEach(function(key) {
      if (key === "default" || key === "__esModule")
        return;
      Object.defineProperty(exports3, key, {
        enumerable: true,
        get: function get() {
          return _unsignedInt[key];
        }
      });
    });
    var _unsignedHyper = require_unsigned_hyper();
    Object.keys(_unsignedHyper).forEach(function(key) {
      if (key === "default" || key === "__esModule")
        return;
      Object.defineProperty(exports3, key, {
        enumerable: true,
        get: function get() {
          return _unsignedHyper[key];
        }
      });
    });
    var _float = require_float();
    Object.keys(_float).forEach(function(key) {
      if (key === "default" || key === "__esModule")
        return;
      Object.defineProperty(exports3, key, {
        enumerable: true,
        get: function get() {
          return _float[key];
        }
      });
    });
    var _double = require_double();
    Object.keys(_double).forEach(function(key) {
      if (key === "default" || key === "__esModule")
        return;
      Object.defineProperty(exports3, key, {
        enumerable: true,
        get: function get() {
          return _double[key];
        }
      });
    });
    var _quadruple = require_quadruple();
    Object.keys(_quadruple).forEach(function(key) {
      if (key === "default" || key === "__esModule")
        return;
      Object.defineProperty(exports3, key, {
        enumerable: true,
        get: function get() {
          return _quadruple[key];
        }
      });
    });
    var _bool = require_bool();
    Object.keys(_bool).forEach(function(key) {
      if (key === "default" || key === "__esModule")
        return;
      Object.defineProperty(exports3, key, {
        enumerable: true,
        get: function get() {
          return _bool[key];
        }
      });
    });
    var _string = require_string();
    Object.keys(_string).forEach(function(key) {
      if (key === "default" || key === "__esModule")
        return;
      Object.defineProperty(exports3, key, {
        enumerable: true,
        get: function get() {
          return _string[key];
        }
      });
    });
    var _opaque = require_opaque();
    Object.keys(_opaque).forEach(function(key) {
      if (key === "default" || key === "__esModule")
        return;
      Object.defineProperty(exports3, key, {
        enumerable: true,
        get: function get() {
          return _opaque[key];
        }
      });
    });
    var _varOpaque = require_var_opaque();
    Object.keys(_varOpaque).forEach(function(key) {
      if (key === "default" || key === "__esModule")
        return;
      Object.defineProperty(exports3, key, {
        enumerable: true,
        get: function get() {
          return _varOpaque[key];
        }
      });
    });
    var _array = require_array();
    Object.keys(_array).forEach(function(key) {
      if (key === "default" || key === "__esModule")
        return;
      Object.defineProperty(exports3, key, {
        enumerable: true,
        get: function get() {
          return _array[key];
        }
      });
    });
    var _varArray = require_var_array();
    Object.keys(_varArray).forEach(function(key) {
      if (key === "default" || key === "__esModule")
        return;
      Object.defineProperty(exports3, key, {
        enumerable: true,
        get: function get() {
          return _varArray[key];
        }
      });
    });
    var _option = require_option();
    Object.keys(_option).forEach(function(key) {
      if (key === "default" || key === "__esModule")
        return;
      Object.defineProperty(exports3, key, {
        enumerable: true,
        get: function get() {
          return _option[key];
        }
      });
    });
    var _void = require_void();
    Object.keys(_void).forEach(function(key) {
      if (key === "default" || key === "__esModule")
        return;
      Object.defineProperty(exports3, key, {
        enumerable: true,
        get: function get() {
          return _void[key];
        }
      });
    });
    var _enum = require_enum();
    Object.keys(_enum).forEach(function(key) {
      if (key === "default" || key === "__esModule")
        return;
      Object.defineProperty(exports3, key, {
        enumerable: true,
        get: function get() {
          return _enum[key];
        }
      });
    });
    var _struct = require_struct();
    Object.keys(_struct).forEach(function(key) {
      if (key === "default" || key === "__esModule")
        return;
      Object.defineProperty(exports3, key, {
        enumerable: true,
        get: function get() {
          return _struct[key];
        }
      });
    });
    var _union = require_union();
    Object.keys(_union).forEach(function(key) {
      if (key === "default" || key === "__esModule")
        return;
      Object.defineProperty(exports3, key, {
        enumerable: true,
        get: function get() {
          return _union[key];
        }
      });
    });
  });
  var require_config = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    var _createClass = function() {
      function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
          var descriptor = props[i];
          descriptor.enumerable = descriptor.enumerable || false;
          descriptor.configurable = true;
          if ("value" in descriptor)
            descriptor.writable = true;
          Object.defineProperty(target, descriptor.key, descriptor);
        }
      }
      return function(Constructor, protoProps, staticProps) {
        if (protoProps)
          defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
          defineProperties(Constructor, staticProps);
        return Constructor;
      };
    }();
    var _reference = require_reference();
    Object.keys(_reference).forEach(function(key) {
      if (key === "default" || key === "__esModule")
        return;
      Object.defineProperty(exports3, key, {
        enumerable: true,
        get: function get() {
          return _reference[key];
        }
      });
    });
    exports3.config = config2;
    var _isUndefined = require_isUndefined();
    var _isUndefined2 = _interopRequireDefault(_isUndefined);
    var _each = require_each();
    var _each2 = _interopRequireDefault(_each);
    var _types = require_types();
    var XDRTypes = _interopRequireWildcard(_types);
    function _interopRequireWildcard(obj) {
      if (obj && obj.__esModule) {
        return obj;
      } else {
        var newObj = {};
        if (obj != null) {
          for (var key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key))
              newObj[key] = obj[key];
          }
        }
        newObj.default = obj;
        return newObj;
      }
    }
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : {default: obj};
    }
    function _classCallCheck(instance, Constructor) {
      if (!(instance instanceof Constructor)) {
        throw new TypeError("Cannot call a class as a function");
      }
    }
    function _possibleConstructorReturn(self2, call) {
      if (!self2) {
        throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      }
      return call && (typeof call === "object" || typeof call === "function") ? call : self2;
    }
    function _inherits(subClass, superClass) {
      if (typeof superClass !== "function" && superClass !== null) {
        throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
      }
      subClass.prototype = Object.create(superClass && superClass.prototype, {constructor: {value: subClass, enumerable: false, writable: true, configurable: true}});
      if (superClass)
        Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }
    function config2(fn) {
      var types2 = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
      if (fn) {
        var builder = new TypeBuilder(types2);
        fn(builder);
        builder.resolve();
      }
      return types2;
    }
    var SimpleReference = function(_Reference) {
      _inherits(SimpleReference2, _Reference);
      function SimpleReference2(name) {
        _classCallCheck(this, SimpleReference2);
        var _this = _possibleConstructorReturn(this, (SimpleReference2.__proto__ || Object.getPrototypeOf(SimpleReference2)).call(this));
        _this.name = name;
        return _this;
      }
      _createClass(SimpleReference2, [{
        key: "resolve",
        value: function resolve(context) {
          var defn = context.definitions[this.name];
          return defn.resolve(context);
        }
      }]);
      return SimpleReference2;
    }(_reference.Reference);
    var ArrayReference = function(_Reference2) {
      _inherits(ArrayReference2, _Reference2);
      function ArrayReference2(childReference, length) {
        var variable = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
        _classCallCheck(this, ArrayReference2);
        var _this2 = _possibleConstructorReturn(this, (ArrayReference2.__proto__ || Object.getPrototypeOf(ArrayReference2)).call(this));
        _this2.childReference = childReference;
        _this2.length = length;
        _this2.variable = variable;
        return _this2;
      }
      _createClass(ArrayReference2, [{
        key: "resolve",
        value: function resolve(context) {
          var resolvedChild = this.childReference;
          var length = this.length;
          if (resolvedChild instanceof _reference.Reference) {
            resolvedChild = resolvedChild.resolve(context);
          }
          if (length instanceof _reference.Reference) {
            length = length.resolve(context);
          }
          if (this.variable) {
            return new XDRTypes.VarArray(resolvedChild, length);
          }
          return new XDRTypes.Array(resolvedChild, length);
        }
      }]);
      return ArrayReference2;
    }(_reference.Reference);
    var OptionReference = function(_Reference3) {
      _inherits(OptionReference2, _Reference3);
      function OptionReference2(childReference) {
        _classCallCheck(this, OptionReference2);
        var _this3 = _possibleConstructorReturn(this, (OptionReference2.__proto__ || Object.getPrototypeOf(OptionReference2)).call(this));
        _this3.childReference = childReference;
        _this3.name = childReference.name;
        return _this3;
      }
      _createClass(OptionReference2, [{
        key: "resolve",
        value: function resolve(context) {
          var resolvedChild = this.childReference;
          if (resolvedChild instanceof _reference.Reference) {
            resolvedChild = resolvedChild.resolve(context);
          }
          return new XDRTypes.Option(resolvedChild);
        }
      }]);
      return OptionReference2;
    }(_reference.Reference);
    var SizedReference = function(_Reference4) {
      _inherits(SizedReference2, _Reference4);
      function SizedReference2(sizedType, length) {
        _classCallCheck(this, SizedReference2);
        var _this4 = _possibleConstructorReturn(this, (SizedReference2.__proto__ || Object.getPrototypeOf(SizedReference2)).call(this));
        _this4.sizedType = sizedType;
        _this4.length = length;
        return _this4;
      }
      _createClass(SizedReference2, [{
        key: "resolve",
        value: function resolve(context) {
          var length = this.length;
          if (length instanceof _reference.Reference) {
            length = length.resolve(context);
          }
          return new this.sizedType(length);
        }
      }]);
      return SizedReference2;
    }(_reference.Reference);
    var Definition = function() {
      function Definition2(constructor, name, cfg) {
        _classCallCheck(this, Definition2);
        this.constructor = constructor;
        this.name = name;
        this.config = cfg;
      }
      _createClass(Definition2, [{
        key: "resolve",
        value: function resolve(context) {
          if (this.name in context.results) {
            return context.results[this.name];
          }
          return this.constructor(context, this.name, this.config);
        }
      }]);
      return Definition2;
    }();
    function createTypedef(context, typeName, value) {
      if (value instanceof _reference.Reference) {
        value = value.resolve(context);
      }
      context.results[typeName] = value;
      return value;
    }
    function createConst(context, name, value) {
      context.results[name] = value;
      return value;
    }
    var TypeBuilder = function() {
      function TypeBuilder2(destination) {
        _classCallCheck(this, TypeBuilder2);
        this._destination = destination;
        this._definitions = {};
      }
      _createClass(TypeBuilder2, [{
        key: "enum",
        value: function _enum(name, members) {
          var result = new Definition(XDRTypes.Enum.create, name, members);
          this.define(name, result);
        }
      }, {
        key: "struct",
        value: function struct(name, members) {
          var result = new Definition(XDRTypes.Struct.create, name, members);
          this.define(name, result);
        }
      }, {
        key: "union",
        value: function union(name, cfg) {
          var result = new Definition(XDRTypes.Union.create, name, cfg);
          this.define(name, result);
        }
      }, {
        key: "typedef",
        value: function typedef(name, cfg) {
          var result = new Definition(createTypedef, name, cfg);
          this.define(name, result);
        }
      }, {
        key: "const",
        value: function _const(name, cfg) {
          var result = new Definition(createConst, name, cfg);
          this.define(name, result);
        }
      }, {
        key: "void",
        value: function _void() {
          return XDRTypes.Void;
        }
      }, {
        key: "bool",
        value: function bool() {
          return XDRTypes.Bool;
        }
      }, {
        key: "int",
        value: function int() {
          return XDRTypes.Int;
        }
      }, {
        key: "hyper",
        value: function hyper() {
          return XDRTypes.Hyper;
        }
      }, {
        key: "uint",
        value: function uint() {
          return XDRTypes.UnsignedInt;
        }
      }, {
        key: "uhyper",
        value: function uhyper() {
          return XDRTypes.UnsignedHyper;
        }
      }, {
        key: "float",
        value: function float() {
          return XDRTypes.Float;
        }
      }, {
        key: "double",
        value: function double() {
          return XDRTypes.Double;
        }
      }, {
        key: "quadruple",
        value: function quadruple() {
          return XDRTypes.Quadruple;
        }
      }, {
        key: "string",
        value: function string(length) {
          return new SizedReference(XDRTypes.String, length);
        }
      }, {
        key: "opaque",
        value: function opaque(length) {
          return new SizedReference(XDRTypes.Opaque, length);
        }
      }, {
        key: "varOpaque",
        value: function varOpaque(length) {
          return new SizedReference(XDRTypes.VarOpaque, length);
        }
      }, {
        key: "array",
        value: function array(childType, length) {
          return new ArrayReference(childType, length);
        }
      }, {
        key: "varArray",
        value: function varArray(childType, maxLength) {
          return new ArrayReference(childType, maxLength, true);
        }
      }, {
        key: "option",
        value: function option(childType) {
          return new OptionReference(childType);
        }
      }, {
        key: "define",
        value: function define2(name, definition) {
          if ((0, _isUndefined2.default)(this._destination[name])) {
            this._definitions[name] = definition;
          } else {
            throw new Error("XDRTypes Error:" + name + " is already defined");
          }
        }
      }, {
        key: "lookup",
        value: function lookup(name) {
          return new SimpleReference(name);
        }
      }, {
        key: "resolve",
        value: function resolve() {
          var _this5 = this;
          (0, _each2.default)(this._definitions, function(defn) {
            defn.resolve({
              definitions: _this5._definitions,
              results: _this5._destination
            });
          });
        }
      }]);
      return TypeBuilder2;
    }();
  });
  var require_lib = __commonJS2((exports3) => {
    "use strict";
    Object.defineProperty(exports3, "__esModule", {
      value: true
    });
    var _types = require_types();
    Object.keys(_types).forEach(function(key) {
      if (key === "default" || key === "__esModule")
        return;
      Object.defineProperty(exports3, key, {
        enumerable: true,
        get: function get() {
          return _types[key];
        }
      });
    });
    var _config = require_config();
    Object.keys(_config).forEach(function(key) {
      if (key === "default" || key === "__esModule")
        return;
      Object.defineProperty(exports3, key, {
        enumerable: true,
        get: function get() {
          return _config[key];
        }
      });
    });
  });
  __markAsModule(exports2);
  __export(exports2, {
    default: () => rebundle_generated_default
  });
  var XDR = __toModule(require_lib());
  var types = XDR.config((xdr2) => {
    xdr2.enum("EnvelopeType", {
      envelopeTypeBundle: 0
    });
    xdr2.typedef("Uint32", xdr2.uint());
    xdr2.typedef("Uint64", xdr2.uhyper());
    xdr2.union("BundleItemExt", {
      switchOn: xdr2.int(),
      switchName: "v",
      switches: [
        [0, xdr2.void()]
      ],
      arms: {}
    });
    xdr2.struct("BundleItem", [
      ["collectionId", xdr2.lookup("Uint64")],
      ["productId", xdr2.lookup("Uint64")],
      ["variantId", xdr2.lookup("Uint64")],
      ["sku", xdr2.string()],
      ["quantity", xdr2.lookup("Uint32")],
      ["ext", xdr2.lookup("BundleItemExt")]
    ]);
    xdr2.union("BundleExt", {
      switchOn: xdr2.int(),
      switchName: "v",
      switches: [
        [0, xdr2.void()]
      ],
      arms: {}
    });
    xdr2.struct("Bundle", [
      ["variantId", xdr2.lookup("Uint64")],
      ["items", xdr2.varArray(xdr2.lookup("BundleItem"), 500)],
      ["version", xdr2.lookup("Uint32")],
      ["ext", xdr2.lookup("BundleExt")]
    ]);
    xdr2.union("BundleEnvelope", {
      switchOn: xdr2.lookup("EnvelopeType"),
      switchName: "type",
      switches: [
        ["envelopeTypeBundle", "v1"]
      ],
      arms: {
        v1: xdr2.lookup("Bundle")
      }
    });
  });
  var rebundle_generated_default = types;
});

// lib/data.js
"use strict";
var {default: xdr} = require_generated();
function toLineItemProperty(bundle) {
  let opts = {
    variantId: xdr.Uint64.fromString(bundle.variantId),
    version: bundle.version || 0,
    items: bundle.items.map((item) => {
      return new xdr.BundleItem({
        collectionId: xdr.Uint64.fromString(item.collectionId),
        productId: xdr.Uint64.fromString(item.productId),
        variantId: xdr.Uint64.fromString(item.variantId),
        sku: item.sku || "",
        quantity: item.quantity,
        ext: new xdr.BundleItemExt(0)
      });
    }),
    ext: new xdr.BundleExt(0)
  };
  const xdrBundle = new xdr.Bundle(opts);
  return xdr.BundleEnvelope.envelopeTypeBundle(xdrBundle).toXDR("base64");
}
exports.toLineItemProperty = toLineItemProperty;
function toBundle(data) {
  const envelope = xdr.BundleEnvelope.fromXDR(data, "base64").v1();
  return {
    variantId: envelope.variantId().toString(),
    version: envelope.version(),
    items: envelope.items().map((item) => {
      return {
        collectionId: item.collectionId().toString(),
        productId: item.productId().toString(),
        variantId: item.variantId().toString(),
        sku: item.sku().toString(),
        quantity: item.quantity()
      };
    })
  };
}
exports.toBundle = toBundle;
exports.encodeLegacyBoxOrder = function(variantId, boxOrder, { version = 1, isVariantAsProduct = false } = {}) {
  const bundle = {
    variantId: variantId.toString(),
    version,
    items: []
  };
  for (const key in boxOrder) {
    if (Object.hasOwnProperty.call(boxOrder, key)) {
      const item = boxOrder[key];
      bundle.items.push({
        collectionId: item.dsId.toString(),
        productId: isVariantAsProduct ? item.product.product.id.toString() : item.pId.toString(),
        variantId: item.vId.toString(),
        sku: item.sku || "",
        quantity: item.qty
      });
    }
  }
  return toLineItemProperty(bundle);
};
exports.decodeToLegacyBoxOrder = function(data, displayVariantAsProduct) {
  let boxOrder = {};
  try {
    const bundle = toBundle(data);
    bundle.items.forEach((item) => {
      boxOrder[item.variantId] = {
        dsId: item.collectionId,
        pId: displayVariantAsProduct ? Number.parseInt(item.variantId) : Number.parseInt(item.productId),
        vId: Number.parseInt(item.variantId),
        sku: item.sku,
        qty: item.quantity
      };
    });
  } catch (e) {
    boxOrder = JSON.parse(Buffer.from(data, "base64").toString());
  }
  return boxOrder;
};
