// @ts-check

/**
 * Compare two string values after sanitizing values
 * @param {String} value1
 * @param {String} value2
 * @returns {Boolean} - if the string values are equal
 */
export const textAreEqual = (value1, value2) => sanitizeText(value1) === sanitizeText(value2);

/**
 * Sanitize string values to make a comparison
 * @param {String} value
 * @returns {String} - value without white spaces and converted to lower case
 */
export const sanitizeText = (value) => value.toLowerCase().trim();
