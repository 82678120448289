import { detect } from 'detect-browser';

/**
 * Scroll to the element with the given id
 * @param {String} elementId - Container element id
 * @param {ScrollIntoViewOptions} [options] - Scroll behavior options
 */
export function scrollIntoView(elementId, options) {
  const browser = detect();
  const isIosDevice = browser?.name === 'safari' || browser?.name === 'ios';
  const defaultOptions = isIosDevice || !browser ? true : { behavior: 'smooth' };

  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView(options ?? defaultOptions);
  }
}
