import { ensureArray } from '../../utils/array';
import { isRenderedInCheckoutContext } from '../../utils/box-config';
import { calculatePriceWithDiscount } from '../../utils/discounts';
import {
  CHARGE_TYPES,
  LINE_ITEM_TYPES,
  tierDiscountIncentive,
  getActiveDiscountIncentives,
} from '../../utils/incentives';

const INCENTIVES_FORMATTERS = {
  tiered_discount: (incentive) => [tierDiscountIncentive(incentive, 'tiered_discount')],
  tiered_discounts: (incentives) => incentives.map((incentive) => tierDiscountIncentive(incentive, 'tiered_discount')),
};

export default {
  state: () => ({
    incentives: [],
  }),
  getters: {
    lineItemTypeFromSelectedPlan: (state, getters) => (selectedPlan) => {
      if (!selectedPlan && getters.getSellingPlanId === 0) {
        return LINE_ITEM_TYPES.ONETIME;
      }

      return LINE_ITEM_TYPES.SUBSCRIPTION;
    },

    chargeTypeFromTemplate: (state, getters, rootState) => {
      if (isRenderedInCheckoutContext(rootState.template)) {
        return CHARGE_TYPES.CHECKOUT;
      }

      return CHARGE_TYPES.RECURRING;
    },

    tierDiscountsIncentive: (state, getters) => (selectedPlan) => {
      let incentives = state.incentives;
      if (getters.chargeTypeFromTemplate === CHARGE_TYPES.CHECKOUT) {
        incentives = getActiveDiscountIncentives(incentives);
      }

      const tierDiscountIncentive = incentives.find(
        ({ chargeTypes, lineItemTypes }) =>
          chargeTypes.has(getters.chargeTypeFromTemplate) &&
          lineItemTypes.has(getters.lineItemTypeFromSelectedPlan(selectedPlan))
      );

      return tierDiscountIncentive?.tiers ?? [];
    },

    showIncentivesStatusBar: (_, getters) => getters.isDynamicBundle && getters.tierDiscountsIncentive().length,

    tierDiscountToApply: (_, getters) => (selectedPlan) => {
      const totalSelected = getters.selectedContentsCount;
      const activeTiers = getters.tierDiscountsIncentive(selectedPlan);
      return activeTiers.find(({ quantity }, index) => {
        const nextTier = activeTiers[index + 1];
        // Tier discounts are always sorted by product quantity
        if (totalSelected >= quantity.value && totalSelected < nextTier?.quantity.value) {
          return activeTiers;
        }

        // This condition is to catch the last tier in case the
        // total selected already achieve the highest discount
        if (totalSelected >= quantity.value && !nextTier) {
          return activeTiers;
        }
      });
    },

    applyTierDiscount: (_, getters) => (value, selectedPlan) => {
      const tier = getters.tierDiscountToApply(selectedPlan);
      return calculatePriceWithDiscount(tier?.discountType ?? 'percentage', value, tier?.discountValue || 0);
    },
  },
  mutations: {
    setIncentives(state, payload) {
      let incentives = [];
      Object.entries(payload).forEach(([key, value]) => {
        const formattedIncentives = (INCENTIVES_FORMATTERS[key] ?? ensureArray)(value);
        incentives = incentives.concat(formattedIncentives);
      });
      state.incentives = incentives;
    },
  },
};
