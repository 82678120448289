function buildProxy(obj, tag, warnOnly) {
  const handler = {
    get(target, property) {
      if (typeof property === 'string') {
        // __ for vue js private properties

        if (
          property in target ||
          property.startsWith('__') ||
          property === 'then' // some code up the stack uses this to check if it's a promise
        ) {
          return target[property];
        }

        const err = `${tag}: Property '${property}' is not defined`;
        if (warnOnly) {
          console.warn(err);
          return target[property];
        } else {
          throw new Error(err);
        }
      }
    },
  };

  return new Proxy(obj, handler);
}

export function buildBundleProduct(product, options = { v2: false, prod: false }) {
  // we don't want to use proxy with legacy customers
  // to avoid breaking live stores.
  if (options.prod && !options.v2) {
    return product;
  }

  return buildProxy(new BundleProduct(product, options.v2), 'bundle-product');
}

class OptionSource {
  constructor(option, collection = {}) {
    this.option = option;
    this.collection = collection;
  }

  get id() {
    return this.option.id;
  }

  get title() {
    return this.collection.title;
  }

  get collectionId() {
    return this.option.option_source_id;
  }

  get selling_plan_groups() {
    return this.selling_plan_groups;
  }

  get itemsCount() {
    if (this.option.items_count) return this.option.items_count;

    // For those limited collections where the min value is 0 and the max value null,
    // we'll evaluate 0 as the min value to handle the data source as unlimited datasource.
    const min = this.option.quantity_min ?? 0;

    return {
      bounded: min !== 0 || this.option.quantity_max !== null,
      min: min,
      max: this.option.quantity_max ?? Infinity,
    };
  }
}

class BundleProductConfig {
  constructor(config, opts = { collections: {} }) {
    this.config = config || {};
    this.collections = opts.collections;
  }

  get defaultFrequency() {
    return undefined;
  }

  get itemsCount() {
    // For future bundles, the items_count will be replaced by a range with the same min and max values
    const { min, max } = this.dynamicRagesBounds;
    if (min === max && Number.isInteger(min)) return min;

    return this.config.items_count;
  }

  get dynamicRanges() {
    return Array.isArray(this.config.ranges) ? this.config.ranges : [];
  }

  get dynamicRagesBounds() {
    if (this.dynamicRanges.length === 0) return {};

    /**
     * It lets us handle the list of ranges as an unique range that covers everything
     * Example
     *  If the variant has multiple ranges like:
     *  [
     *    { quantity_min: 4, quantity_max: 8 },
     *    { quantity_min: 9, quantity_max: null },
     *  ]
     * It'll return the object { min: 4, max: Infinity }. The user must add at least 4 products,
     * and it has no max limit. It's especially useful to validate that the collection limits match the bundle ranges.
     */
    const min = Math.min(...this.dynamicRanges.map(({ quantity_min }) => quantity_min));
    const max = Math.max(...this.dynamicRanges.map(({ quantity_max }) => quantity_max ?? Infinity));
    return { min, max };
  }

  get disabled() {
    return !this.config.enabled;
  }

  get dataSources() {
    let optionSources = this.config.option_sources ?? [];
    return optionSources.map((option) =>
      buildProxy(new OptionSource(option, this.collections[option.option_source_id]), 'option-source')
    );
  }
}

class ProductVariant {
  constructor(variant, v2, opts = { variantsConfig: [], collections: {} }) {
    this.v2 = v2;
    this.variant = buildProxy(variant, `product-variant-${variant.id}`);
    this.variantsConfig = opts.variantsConfig;
    this.collections = opts.collections;
  }

  get id() {
    return this.variant.id;
  }

  get bundleConfig() {
    if (this.v2) {
      let bundleConfig = this.variantsConfig.find(
        (c) => c.external_variant_id.toString() === this.variant.id.toString()
      );

      return buildProxy(
        new BundleProductConfig(bundleConfig, {
          collections: this.collections,
        }),
        `bundle-config-${this.variant.id}`
      );
    }

    // some legacy customers still have rebundleConfig inside the variant
    // let's give priority to this value so we can start deprecating them more easily
    if ('rebundleConfig' in this.variant) {
      return this.variant.rebundleConfig;
    }

    return this.variant.bundleConfig;
  }

  get rebundleConfig() {
    return null;
  }

  get price() {
    return this.variant.price;
  }

  get sellingPlanAllocations() {
    if (this.v2) {
      return this.variant.selling_plan_allocations.map((a) =>
        buildProxy(new SellingPlanAllocation(a), 'selling-plan-allocation')
      );
    }
    return this.variant.sellingPlanAllocations;
  }

  get compareAtPrice() {
    if (this.v2) {
      return this.variant.compare_at_price;
    }
    return this.variant.compareAtPrice;
  }

  get compare_at_price() {
    if ('compare_at_price' in this.variant) {
      return this.variant.compare_at_price;
    }

    return this.variant.compareAtPrice;
  }

  get requiresSellingPlan() {
    if (this.v2) {
      return this.variant.requires_selling_plan;
    }
    return this.variant.requiresSellingPlan;
  }

  get options() {
    return this.variant.options;
  }

  get title() {
    return this.variant.title;
  }

  get image() {
    return this.variant.image;
  }

  get plans() {
    return this.variant.plans ?? [];
  }
}

class BundleSettings {
  constructor(settings, v2) {
    this.v2 = v2;

    if (v2) {
      this.settings = buildProxy(settings, 'bundle-settings-v2', true);
    } else {
      this.settings = settings;
    }
  }

  get defaultFrequency() {
    if (this.v2) {
      const defaultFrequency = this.layoutSettings.defaultFrequency;
      if (defaultFrequency === '') {
        return null;
      }
      return defaultFrequency;
    }
    return this.settings.defaultFrequency;
  }

  get defaultVariantId() {
    if (this.v2) {
      return this.layoutSettings.defaultVariantId;
    }
    return this.settings.defaultVariantId;
  }

  get layoutSettings() {
    if (this.v2) {
      return this.settings.layout_settings;
    }

    return this.settings.layoutSettings;
  }

  get maxPerItem() {
    if (this.v2) {
      return this.settings.max_quantity_per_variant;
    }

    return this.settings.maxPerItem;
  }

  get customizationWindow() {
    if (this.v2) {
      return this.settings.customization_window;
    }
    return this.settings.customizationWindow;
  }

  get customizationDisabledMessage() {
    if (this.v2) {
      return this.settings.customization_window_disabled_message;
    }
    return this.settings.customizationDisabledMessage;
  }

  get crossSells() {
    if (this.v2) {
      return buildProxy(new CrossSells(this.layoutSettings.crossSells, this.v2), 'bundle-settings-cross-sells');
    }

    return buildProxy(new CrossSells(this.settings.crossSells, this.v2), 'bundle-settings-cross-sells');
  }

  get addons() {
    if (this.v2) {
      return buildProxy(new Addons(this.layoutSettings.addons, this.v2), 'bundle-settings-addons');
    }

    return buildProxy(new Addons(this.settings.addons, this.v2), 'bundle-settings-addons');
  }

  get addToCartCallback() {
    if (this.v2) {
      return this.layoutSettings.addToCartCallback;
    }

    return this.settings.addToCartCallback;
  }

  get customizable() {
    if (this.v2) {
      return this.settings.is_customizable;
    }
    return this.settings.customizable;
  }

  get title() {
    if (this.v2) {
      return this.layoutSettings.title;
    }

    return this.settings.title;
  }

  get description() {
    if (this.v2) {
      return this.layoutSettings.description;
    }

    return this.settings.description;
  }

  get priceRule() {
    return this.settings.price_rule ?? '';
  }

  get dynamicFilterProductsByDiscount() {
    // The `dynamic_filter_products_by_discount` setting is only valid for dynamic bundles
    if (this.settings.price_rule === 'dynamic') {
      return this.settings.settings?.dynamic_filter_products_by_discount ?? false;
    }

    return false;
  }
}

class SellingPlanGroup {
  constructor(group) {
    this.group = group;
  }

  get selling_plans() {
    return this.group.selling_plans;
  }
}

class SellingPlanAllocation {
  constructor(allocation) {
    this.allocation = allocation;
  }

  get compareAtPrice() {
    return this.allocation.compare_at_price;
  }

  get price() {
    return this.allocation.price;
  }

  get sellingPlan() {
    return this.allocation.selling_plan;
  }

  toJSON() {
    return {
      compareAtPrice: this.compareAtPrice,
      price: this.price,
      sellingPlan: this.sellingPlan,
    };
  }
}

class Addons {
  constructor(addons, v2) {
    if (this.v2) {
      this.addons = buildProxy('addons-v2', addons);
    } else {
      this.addons = addons;
    }
    this.v2 = v2;
  }

  get enabled() {
    return this.addons.enabled || true;
  }
}

class CrossSells {
  constructor(crossSells, v2) {
    if (this.v2) {
      this.crossSells = buildProxy('cross-sells-v2', crossSells);
    } else {
      this.crossSells = crossSells;
    }
    this.v2 = v2;
  }

  get enabled() {
    return this.crossSells.enabled || true;
  }
}

class AddonProduct {
  constructor(product, v2) {
    this.product = buildProxy(product, 'addon-product-internal');
    this.v2 = v2;
  }

  get price() {
    return this.product.price;
  }

  get sellingPlanGroups() {
    if (this.v2) {
      return this.product.selling_plan_groups.map((g) => buildProxy(new SellingPlanGroup(g), 'selling-plan-group'));
    }

    return this.product.sellingPlanGroups;
  }

  get variants() {
    return this.product.variants.map((v) => buildProxy(new ProductVariant(v, this.v2), 'addon-product-variant'));
  }

  get id() {
    return this.product.id;
  }

  get subscriptionData() {
    return this.product.subscription_data;
  }

  get featured_image() {
    return this.product.featured_image;
  }

  get images() {
    return this.product.images;
  }

  get title() {
    return this.product.title;
  }

  get description() {
    return this.product.description;
  }

  get available() {
    return this.product.available;
  }
}

export default class BundleProduct {
  constructor(product, v2 = false) {
    this.v2 = v2;
    if (this.v2) {
      this.product = buildProxy(product, 'bundle-product-v2');
    } else {
      this.product = product;
    }
  }

  get shopSettings() {
    if (this.v2) {
      return {};
    }

    return this.product.shopSettings ?? {};
  }

  get filters() {
    return this.product.filters;
  }

  get handle() {
    return decodeURIComponent(this.product.url).split('/products/')[1];
  }

  get variants() {
    if (this.v2) {
      return this.product.variants.map((v) =>
        buildProxy(
          new ProductVariant(v, true, {
            variantsConfig: this.product.bundle_settings.variants,
            collections: this.collections,
          }),
          `variant-${v.id}`
        )
      );
    }

    return this.product.variants.map((v) => buildProxy(new ProductVariant(v, false), `variant-${v.id}`));
  }

  get rebundleSettings() {
    return null;
  }

  get bundleSettings() {
    if (this.v2) {
      return buildProxy(new BundleSettings(this.product.bundle_settings, this.v2), 'bundle-settings-v2');
    }

    if (this.product.rebundleSettings) {
      return buildProxy(new BundleSettings(this.product.rebundleSettings, this.v2), 'bundle-settings');
    }

    return buildProxy(new BundleSettings(this.product.bundleSettings, this.v2), 'bundle-settings');
  }

  get collections() {
    return this.product.collections ?? {};
  }

  get contentsByVariant() {
    if (this.v2) {
      // this doesn't exist on v2
      return null;
    }

    return this.product.contentsByVariant;
  }

  get sellingPlanGroups() {
    if (this.v2) {
      return this.product.selling_plan_groups.map((g) => buildProxy(new SellingPlanGroup(g), 'selling-plan-group'));
    }

    return this.product.sellingPlanGroups;
  }

  get sellingPlanAllocations() {
    if (this.v2) {
      return this.product.selling_plan_allocations.map((a) =>
        buildProxy(new SellingPlanAllocation(a), 'selling-plan-allocation')
      );
    }

    return this.product.sellingPlanAllocations;
  }

  get addons() {
    if (this.v2) {
      if (this.addon) {
        return this.addon;
      }

      this.addon = this.product.addons;
      this.addon.products = this.addon.products.map((a) => buildProxy(new AddonProduct(a, this.v2), 'addon-product'));

      return this.addon;
    }
    return this.product.addons;
  }

  get id() {
    return this.product.id;
  }

  get available() {
    return this.product.available;
  }

  get options() {
    return this.product.options;
  }

  get title() {
    return this.product.title;
  }

  get defaultVariantId() {
    if (this.v2) {
      return this.bundleSettings.defaultVariantId;
    }
    return this.product.defaultVariantId;
  }

  get subscriptionData() {
    if (this.v2) {
      return this.product.subscription_data;
    }

    return this.product.subscriptionData;
  }

  get subscriptionDefaults() {
    return this.product.subscription_defaults;
  }

  get crossSells() {
    if (this.v2) {
      return this.product.cross_sells;
    }

    return this.product.crossSells;
  }

  get isBundle() {
    if (this.v2) {
      // any v2 product is a bundle product
      return true;
    }

    return this.product.isBundle;
  }

  get price() {
    if (this.v2) {
      return null;
    }

    return this.product.price;
  }
  get shopFeatureFlags() {
    if (this.v2) {
      return [];
    }

    return this.product.shop_feature_flags;
  }

  get incentives() {
    try {
      if (this.bundleSettings.priceRule === 'dynamic') {
        return this.product.incentives ?? { tiered_discounts: [] };
      }

      return { tiered_discounts: [] };
    } catch (error) {
      return { tiered_discounts: [] };
    }
  }
}
