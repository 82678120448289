export default function getBaseUrl(config = {}) {
  const currentLocation = new URL(window.location);
  const portalBasePath = `/portal/${config.customerHash}`;
  const result = /(?<appProxy>\/tools\/recurring(-\d+|))/.exec(currentLocation.pathname);
  const inAppProxy = result?.groups.appProxy ?? '';

  // This helps to mount the widget outside
  // of the context of recharge
  if (config.appProxy) return `${config.appProxy}${portalBasePath}`;

  return `${currentLocation.origin}${inAppProxy}${portalBasePath}`;
}
