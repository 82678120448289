// @ts-check

/**
 * @typedef {Object} ProductVariant
 * @property {Array<{selling_plan_name: String, selling_plan_group_id: String, selling_plan_id: number; selling_plan: {id: number}}>} selling_plan_allocations
 *
 * @typedef {Object} SellingPlan
 * @property {String} id
 * @property {String} name
 *
 * @typedef {Object} Product
 * @property {Number} id
 * @property {Array<{id: String, selling_plans: Array<SellingPlan>}>} [selling_plan_groups]
 * @property {Array<{id: String, selling_plans: Array<SellingPlan>}>} [sellingPlanGroups]
 * @property {Array<ProductVariant>} variants
 */

import { mapArrayToHashTable } from './array';
import { getSellingPlanFrequencyAndUnit, isSameSellingPlan } from './selling-plan';

/**
 * Returns an array of selling plan names for a product
 * @param {Product} product
 * @returns {Product & {sellingPlanGroupsById: Object}} product with selling plan groups by id
 */
export const mapProductSellingPlanGroupsById = (product) => {
  const productSellingPlanGroups = product.sellingPlanGroups ?? product.selling_plan_groups ?? [];

  const sellingPlanGroups = {};
  productSellingPlanGroups.forEach((sellingPlanGroup) => {
    const sellingPlans = mapArrayToHashTable(sellingPlanGroup.selling_plans, 'id');
    sellingPlanGroups[sellingPlanGroup.id] = { ...sellingPlanGroup, selling_plans: sellingPlans };
  });

  return { ...product, sellingPlanGroupsById: sellingPlanGroups };
};

/**
 * Attach the product selling plan name to each product variant
 * @param {ProductVariant} productVariant - product variant
 * @param {Object} sellingPlanGroupsById - product selling plan groups by id
 * @returns {ProductVariant} product variant with selling plan names
 */
export const attachSellingPlanDataToProductVariant = (productVariant, sellingPlanGroupsById) => {
  const sellingPlanAllocations = (productVariant.selling_plan_allocations ?? []).map((sellingPlanAllocation) => {
    const { selling_plan_group_id, selling_plan_id, selling_plan } = sellingPlanAllocation;
    const sellingPlan = sellingPlanGroupsById[selling_plan_group_id]?.selling_plans[selling_plan_id ?? selling_plan.id];

    const frequencyAndUnit = getSellingPlanFrequencyAndUnit(sellingPlan?.options ?? []);
    return {
      ...sellingPlanAllocation,
      // TODO: Remove validation/comparison by selling plan' name. https://recharge.atlassian.net/browse/BUN-1601
      selling_plan_name: sellingPlan?.name ?? '',
      selling_plan_price_adjustments: formatPricesAdjustments(sellingPlan?.price_adjustments),
      selling_plan_frequency_unit: frequencyAndUnit,
      price_adjustments: sellingPlan?.price_adjustments ?? [],
    };
  });

  return { ...productVariant, selling_plan_allocations: sellingPlanAllocations };
};

/**
 * Update the product variants plans allocation with the product selling plan group name
 * @param {Product} product
 * @returns {Product}
 */
export const mapProductVariantsSellingPlans = (product) => {
  const productWithSellingPlansById = mapProductSellingPlanGroupsById(product);

  return {
    ...productWithSellingPlansById,
    variants: productWithSellingPlansById.variants.map((variant) =>
      attachSellingPlanDataToProductVariant(variant, productWithSellingPlansById.sellingPlanGroupsById)
    ),
  };
};

/**
 * Format the selling plan price adjustments as "<value-type>-<value>""
 * @param {Array<{value_type: string, value: string}>} pricesAdjustments
 * @returns {Array<String>}
 */
export const formatPricesAdjustments = (pricesAdjustments) =>
  (pricesAdjustments ?? []).map(({ value_type, value }) => `${value_type}-${Number(value) || 0}`);

const getImages = (variantImage, productImages) => {
  if (!variantImage?.src) return productImages;

  return [variantImage.src];
};

const getMedia = (variantMedia, productMedia) => {
  if (!variantMedia?.preview_image?.src) return productMedia;

  return [{ ...variantMedia, ...variantMedia.preview_image.src }];
};

/**
 * Parse a variant object as product adding some parent product properties
 * @param {Object} product - parent product
 * @param {Object} variant
 * @returns {Object} - variant with additional properties taken from its parent product
 */
export const parseVariantAsProduct = (product, variant, options = {}) => {
  const defaultVariantTitle = product.variants.length > 1 ? variant.title : '';
  const variantTitle = defaultVariantTitle || product.title;
  const productAndVariantTitle = `${product.title} ${defaultVariantTitle}`.trim();

  return {
    ...variant,
    title: options.useProductAndVariant ? productAndVariantTitle : variantTitle,
    product,
    images: getImages(variant.featured_image, product.images ?? []),
    media: getMedia(variant.feature_media, product.media ?? []),
    selling_plan_groups: product.selling_plan_groups ?? [],
    variants: [variant],
    featured_image: variant.featured_image?.src ?? product.featured_image,
    description: product.description,
    tags: product.tags,
  };
};

export const findSelectionItemSellingPlanId = (sellingPlanAllocations, sampleSellingPlan) => {
  const sellingPlanAllocation = sellingPlanAllocations.find((allocation) =>
    isSameSellingPlan(allocation, sampleSellingPlan)
  );
  return sellingPlanAllocation?.selling_plan?.id ?? sellingPlanAllocation?.selling_plan_id ?? 0;
};
