// @ts-check
/**
 * @typedef {Object} PlanApi
 * @property {String} type - plan type (subscription or onetime).
 * @property {String} title - plan title.
 * @property {Number} id - Plan ID.
 * @property {Number} sort_order - Plan sort order.
 * @property {String} discount_type - Plan discount type (percentage).
 * @property {String} discount_amount - Plan discount amount.
 * @property {String} external_plan_id - Shopify plan ID.
 * @property {String} external_plan_group_id - Shopify plan group ID.
 * @property {Object} channel_settings - Channel settings.
 * @property {Object} channel_settings.customer_portal - Customer portal settings.
 * @property {Boolean} channel_settings.customer_portal.display - Display plan in customer portal.
 * @property {Object} subscription_preferences - Subscription preferences.
 * @property {String} subscription_preferences.charge_interval_frequency - Charge interval frequency.
 * @property {String} subscription_preferences.interval_unit - Interval unit (day, week, or month).
 * @property {String} subscription_preferences.order_interval_frequency - Order interval frequency.
 * @property {Array<Number>} external_variant_ids - Shopify variant IDs.
 * @property {Boolean} has_variant_restrictions
 *
 * @typedef {Object} SellingPlanOptions
 * @property {String} name - Selling plan option name.
 * @property {String} value - Selling plan option value.
 *
 * @typedef {Object} SellingPlanGroup
 * @property {Number} id - Selling plan group ID.
 * @property {Array<SellingPlan>} selling_plans - Selling plans.
 *
 * @typedef {Object} SellingPlanAllocation
 * @property {Number} price - Selling plan price.
 * @property {Number} compare_at_price - Selling plan compare at price.
 * @property {Number} per_delivery_price - Selling plan per delivery price.
 * @property {Number} selling_plan_id - Selling plan ID.
 * @property {String} selling_plan_group_id - Selling plan group ID.
 * @property {SellingPlan} selling_plan - Selling plan.
 *
 * @typedef {Object} SellingPlan
 * @property {Number} id - Selling plan ID.
 * @property {String} title - Selling plan title.
 * @property {Array<SellingPlanOptions>} options - Selling plan options.
 *
 * @typedef {Object} BundleVariant
 * @property {Array<SellingPlanAllocation>} selling_plan_allocations - Selling plan allocations.
 *
 *
 * @typedef {Object} BundleProduct
 * @property {Array<BundleVariant>} variants - Bundle variants.
 * @property {Array<SellingPlanGroup>} selling_plan_groups - Selling plan groups.
 *
 * @typedef {Object} Plan
 * @property {String} type - plan type (subscription or onetime).
 * @property {String} title - plan title.
 * @property {Number} id - Plan ID.
 * @property {Number} sortOrder - Plan sort order.
 * @property {String} discountType - Plan discount type (percentage).
 * @property {String} discountAmount - Plan discount amount.
 * @property {String} externalPlanId - Shopify plan ID.
 * @property {?String} externalPlanGroupId - Shopify plan group ID.
 * @property {Object} channelSettings - Channel settings.
 * @property {Boolean} channelSettings.display - Display plan in customer portal.
 * @property {Object} subscriptionPreferences - Subscription preferences.
 * @property {String} subscriptionPreferences.chargeIntervalFrequency - Charge interval frequency.
 * @property {?String} subscriptionPreferences.intervalUnit - Interval unit (day, week, or month).
 * @property {String} subscriptionPreferences.orderIntervalFrequency - Order interval frequency.
 * @property {Array<Number>} externalVariantIds - Shopify variant IDs.
 * @property {Object} [sellingPlanGroup] - Selling plan group.
 * @property {Boolean} hasVariantRestrictions
 *
 */

import { mapArrayToHashTable } from './array';
import { calculatePriceWithDiscount } from './discounts';
import { ONETIME_PLAN, ONETIME_FREQUENCY, ONETIME_SELLING_PLAN, SUBSCRIPTION_PLAN_TYPE } from '../store/constants';
import { getRechargePlanIdsFromSellingPlan } from './selling-plan';

export const isOnetimePlan = (plan) => plan.type === ONETIME_PLAN.type;

/**
 * Filter Recharge plans by bundle product variants selling plan allocations
 * @param {Array<Plan>} plans - Recharge product plans from the API
 * @param {Array<SellingPlanAllocation>} sellingPlansAllocations - Selling plan allocations from bundle product variant
 * @returns
 */
const filterPlansByPlanAllocation = (plans, sellingPlansAllocations) => {
  // Return all plans for those RCS store due to they don't have selling plan groups
  if (sellingPlansAllocations.length === 0) return plans;

  // Get all selling plan groups IDs and selling plans ids from the bundle variant to compare with data from plans
  const sellingPlanIds = new Set(
    sellingPlansAllocations.map((allocation) => `${allocation.selling_plan_group_id}-${allocation.selling_plan.id}`)
  );

  // Filter all plans that match with the selling plans ids. One time plan is the only one which does not have
  // selling plan group information. So, we must use the plan type to ensure it is included in the result when it's
  // present in the plans list
  return plans.filter(
    (plan) => sellingPlanIds.has(`${plan.sellingPlanGroup?.id}-${plan.externalPlanId}`) || isOnetimePlan(plan)
  );
};

/**
 * Assign Recharge plans to each bundle product variants based on the selling plan allocations
 * @param {BundleProduct} bundleProduct - Bundle product from bundle data response
 * @param {Array<PlanApi>} plans - Recharge product plans from the API
 * @returns {BundleProduct} - Bundle product with Recharge plans assigned
 */
export const assignPlansToVariant = (bundleProduct, plans) => {
  const updatedPlans = mergePlansAndSellingPlans(bundleProduct.selling_plan_groups, plans);
  bundleProduct.variants = bundleProduct.variants.map((variant) => {
    // Get Recharge Plans ID from the selling_plan options to filter the plans. If selling plans don't have
    // recharge plan ID, it'll assign all plans to the variant assuming the PSP is not enabled
    const sellingPlanOptions = variant.selling_plan_allocations.flatMap(({ selling_plan }) => selling_plan.options);
    const rechargePlansIds = getRechargePlanIdsFromSellingPlan(sellingPlanOptions);
    const filteredPlans = rechargePlansIds.length
      ? filterPlansByPlanAllocation(updatedPlans, variant.selling_plan_allocations)
      : updatedPlans;
    return { ...variant, plans: filteredPlans };
  });

  return bundleProduct;
};

/**
 * Filter active plans, sort, and merge Recharge plans with Shopify selling plan groups
 * @param {Array<SellingPlanGroup>} sellingPlanGroups
 * @param {Array<PlanApi>} plans - Recharge product plans from the API
 * @returns {Array<Plan>} - Formatted recharge plans
 */
function mergePlansAndSellingPlans(sellingPlanGroups, plans) {
  const planGroups = sellingPlanGroups.reduce((acc, group) => {
    const sellingPlans = mapArrayToHashTable(group.selling_plans, 'id');

    // Map selling plan group by recharge plan id
    const sellingPlanOptions = group.selling_plans.flatMap((sellingPlan) => sellingPlan.options);
    getRechargePlanIdsFromSellingPlan(sellingPlanOptions).forEach((id) => {
      acc[id] = { ...group, selling_plans: sellingPlans };
    });
    return acc;
  }, {});

  // This is a temporal fix for the double write of plans in stores that have not activated the PSP feature yet.
  // When the product is not added to Recharge, the plans are not written to the DB. We must return a generic
  // onetime plan in this case.
  if (Array.isArray(plans) && plans.length === 0) {
    // Adding the `as ProductWithPlan`, because this only happens in case the product is a Subscription Product
    return [
      {
        ...ONETIME_PLAN,
        sellingPlanGroup: {
          name: ONETIME_SELLING_PLAN.name,
          selling_plans: { [ONETIME_SELLING_PLAN.id]: ONETIME_SELLING_PLAN },
        },
      },
    ];
  }

  // Assign Shopify selling plan group to each Recharge plan
  const formattedPlans = plans.map((plan) => ({
    ...mapPlan(plan),
    sellingPlanGroup: isOnetimePlan(plan)
      ? {
          name: ONETIME_SELLING_PLAN.name,
          selling_plans: { [ONETIME_SELLING_PLAN.id]: ONETIME_SELLING_PLAN },
        }
      : planGroups[plan.id],
  }));

  // Filter and sort Recharge active plans
  return getActivePlans(formattedPlans);
}

/**]
 * Get active Recharge plans
 * @param {Array<Plan>} plans - Recharge plans
 * @returns {Array<Plan>} - Active Recharge plans
 */
export function getActivePlans(plans) {
  return plans.filter((plan) => plan.channelSettings.display).sort((a, b) => a.sortOrder - b.sortOrder);
}

/**
 * Map Recharge plan from the API to the app format
 * @param {PlanApi} plan - Recharge plan from the API
 * @returns {Plan} - Plan data
 */
const mapPlan = (plan) => ({
  type: plan.type,
  title: isOnetimePlan(plan) ? ONETIME_SELLING_PLAN.name : plan.title,
  id: plan.id,
  sortOrder: plan.sort_order,
  discountType: plan.discount_type,
  discountAmount: Number.isNaN(Number(plan.discount_amount || 0)) ? '0' : plan.discount_amount,
  externalPlanId: isOnetimePlan(plan) ? String(ONETIME_SELLING_PLAN.id) : plan.external_plan_id,
  externalPlanGroupId: plan.external_plan_group_id,
  channelSettings: {
    display: plan.channel_settings.customer_portal.display,
  },
  subscriptionPreferences: {
    chargeIntervalFrequency: plan.subscription_preferences.charge_interval_frequency?.toString() ?? ONETIME_FREQUENCY,
    intervalUnit: plan.subscription_preferences.interval_unit,
    orderIntervalFrequency: plan.subscription_preferences.order_interval_frequency?.toString() ?? ONETIME_FREQUENCY,
  },
  hasVariantRestrictions: plan.has_variant_restrictions,
  externalVariantIds: plan.external_variant_ids,
});

/**
 * Get Recharge subscription plans
 * @param {Array<Plan>} plans - Recharge plans
 * @returns {Array<Plan>} - Recharge subscription plans
 */
export const getSubscriptionPlans = (plans) =>
  getActivePlans(plans).filter((plan) => plan.type === SUBSCRIPTION_PLAN_TYPE);

/**
 * Get Recharge plan by frequency options (unit and interval) or return the first plan as default
 * @param {Array<Plan>} plans - Recharge plans
 * @param {Object} [subscriptionPreferences]
 * @param {String} subscriptionPreferences.unit - Subscription unit (day, week, or month).
 * @param {String} subscriptionPreferences.orderFrequency - Order interval frequency.
 * @param {String} subscriptionPreferences.chargeFrequency - Charge interval frequency.
 * @param {Number} [variantFullPrice] - Variant full price
 * @returns {Plan} - Recharge plan that matches with the subscription preferences or the first plan as default
 */
export const getPlanByFrequencyOptions = (plans, subscriptionPreferences, variantFullPrice) => {
  if (!subscriptionPreferences) {
    return getSubscriptionPlans(plans)[0] || getActivePlans(plans)[0];
  }

  const { unit, orderFrequency, chargeFrequency } = subscriptionPreferences;
  const subscriptionPlans = plans.filter(
    ({ subscriptionPreferences: { chargeIntervalFrequency, intervalUnit, orderIntervalFrequency } }) =>
      chargeIntervalFrequency === chargeFrequency && intervalUnit === unit && orderIntervalFrequency === orderFrequency
  );

  // If there are more than 1 plan with the same frequency options, return the first one that has the highest discount
  return getHighestDiscountPlan(subscriptionPlans, variantFullPrice)?.plan;
};

/**
 * Validate if product is prepaid
 * @param {Array<Plan>} plans - Recharge plans
 * @returns {Boolean} - True if all plans are prepaid
 */
export const isPrepaid = (plans) => {
  const activePlans = getActivePlans(plans);
  return activePlans.length > 0 && activePlans.every((plan) => plan.type === 'prepaid');
};

/**
 * Get highest discount plan
 * @param {Array<Plan>} plans
 * @param {Number} [variantFullPrice]
 * @returns Return the plan with the highest discount
 */
export const getHighestDiscountPlan = (plans, variantFullPrice) =>
  plans
    .map((plan) => ({
      plan,
      discount: calculatePriceWithDiscount(plan.discountType, variantFullPrice, plan.discountAmount),
    }))
    // Sort by discount amount from higher to lower
    .sort((a, b) => a.discount - b.discount)[0];
