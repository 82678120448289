function RetryableFetchError(statusCode, body = {}) {
  this.name = 'RetryableFetchError';

  this.body = body;
  this.statusCode = statusCode;
  this.message = JSON.stringify(body);
}
RetryableFetchError.prototype = Error.prototype;
RetryableFetchError.RETRYABLE_STATUS_CODES = [404, 500, 502, 503, 504];
RetryableFetchError.MAX_ATTEMPTS = 3;

export { RetryableFetchError };
