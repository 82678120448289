// @ts-check

/**
 * Map a list of objects to a hash table
 * @param {Array<Object>} array - array to map
 * @param {String} key - key to use as hash table key
 * @returns {Object} hash table
 */

export const mapArrayToHashTable = (array, key) =>
  array.reduce((acc, current) => {
    acc[current[key]] = current;
    return acc;
  }, {});

export const ensureArray = (record) => {
  // Do nothing if it's already an array
  if (Array.isArray(record)) return record;

  // Return an empty array if it's an empty object
  if (Object.keys(record).length === 0) return [];

  return [record];
};
