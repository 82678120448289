import { calculatePriceWithDiscount, discountByPercentage } from '../../utils/discounts';
import { parsePrice } from '../../utils/price';
import uniq from '../../utils/uniq';
import { ONETIME_FREQUENCY, ONETIME_SELLING_PLAN_ID, ONETIME_SELLING_PLAN_NAME } from '../constants';
import { usedWithinEmbeddedThemeContext } from '../../utils/customer-portal';
import { sanitizeText } from '../../utils/text';
import {
  getHighestDiscountSellingPlan,
  getSellingPlanFrequencies,
  getSellingPlanFrequencyAndUnit,
} from '../../utils/selling-plan';

export default {
  getters: {
    // TODO: Remove validation/comparison by selling plan' name. https://recharge.atlassian.net/browse/BUN-1601
    itemPriceBySellingPlanByName: (state, getters) => (item, variant, sellingPlanName) => {
      if (!sellingPlanName || sellingPlanName === ONETIME_SELLING_PLAN_NAME || getters.isOnetimeSelected) {
        return variant.price;
      }

      return getters.itemPriceBySubscriptionSellingPlanByName(item, variant, sellingPlanName);
    },

    // TODO: Remove validation/comparison by selling plan' name. https://recharge.atlassian.net/browse/BUN-1601
    itemPriceBySubscriptionSellingPlanByName: (state, getters) => (item, variant, sellingPlanName) => {
      if (getters.isShopifySubscription) {
        const sellingPlans = item.selling_plan_groups.flatMap((group) => group.selling_plans);

        const itemSellingPlan =
          sellingPlans.find((sellingPlan) => sellingPlan.name === sellingPlanName) ?? sellingPlans[0];

        if (!itemSellingPlan || itemSellingPlan.price_adjustments.length === 0) {
          return variant.price;
        }

        const price = variant.selling_plan_allocations.find((spa) => spa.selling_plan_id === itemSellingPlan.id)?.price;
        return price ?? variant.price;
      }

      return 0;
    },

    itemPriceBySellingPlan: (state, getters) => (item, variant, sellingPlanFrequencyUnit) => {
      if (sellingPlanFrequencyUnit == null) return 0;

      if (sellingPlanFrequencyUnit === ONETIME_FREQUENCY || getters.isOnetimeSelected) {
        return variant.price;
      }

      return getters.itemPriceBySubscriptionSellingPlan(item, variant, sellingPlanFrequencyUnit);
    },

    itemPriceBySubscriptionSellingPlan: (state, getters) => (item, variant, sellingPlanFrequencyUnit) => {
      if (getters.isShopifySubscription) {
        const itemSellingPlanAllocations = variant.selling_plan_allocations.filter(
          ({ selling_plan_frequency_unit }) => selling_plan_frequency_unit === sellingPlanFrequencyUnit
        );

        // TODO: Remove validation/comparison by selling plan' name. https://recharge.atlassian.net/browse/BUN-1601
        if (!itemSellingPlanAllocations.length) return 0;

        const itemSellingPlanAllocation = getHighestDiscountSellingPlan(itemSellingPlanAllocations);
        if (!itemSellingPlanAllocation?.price_adjustments.length) {
          return variant.price;
        }

        return itemSellingPlanAllocation?.price ?? variant.price;
      }

      const sellingPlan = getters.dataSources
        .map((ds) => ds.collection.subscription_data_by_product_id)
        .find((data) => data[item.id]);

      // we learned that subscription_data can look like this
      // {"last_sync_timestamp":"2020-04-10 21:10:24.584215"}
      // so !sellingPlan was evaluating to false and then
      // a couple of lines above Number(sellingPlan[item.id].discount_percentage
      // was evaluating to NaN

      // Selling plan data may not be synced yet
      if (!sellingPlan || !sellingPlan[item.id].discount_percentage) {
        return variant.price;
      }

      return discountByPercentage(variant.price, Number(sellingPlan[item.id].discount_percentage));
    },

    getFirstSubscriptionFrequencyOption(state, getters) {
      return getters.normalizedFrequencyOptions.find(({ name }) => name !== ONETIME_SELLING_PLAN_NAME);
    },

    // TODO: Remove validation/comparison by selling plan' name. https://recharge.atlassian.net/browse/BUN-1601
    getSelectedSellingPlanName: (state, getters) => (sellingPlanFrequencyUnit) => {
      let selectedSellingPlanName = sellingPlanFrequencyUnit || getters.selectedSellingPlanName;
      if (![ONETIME_SELLING_PLAN_NAME, String(ONETIME_SELLING_PLAN_ID)].includes(selectedSellingPlanName))
        return selectedSellingPlanName;

      /* 
      When the Subscribe & Save feature is enabled, we replace the onetime frequency option with the first subscription
      frequency to handle onetime prices as subscription price. This is only for the UI and it does not affect the global state.
      */
      if (usedWithinEmbeddedThemeContext() && getters.isSubscribeAndSaveEnabled) {
        // TODO: Acá
        const frequency = getters.normalizedFrequencyOptions.find(({ name }) => name !== ONETIME_SELLING_PLAN_NAME);
        selectedSellingPlanName = frequency?.name ?? selectedSellingPlanName;
      }

      return selectedSellingPlanName;
    },

    getSelectedSellingPlanFrequencyUnit: (state, getters) => (sellingPlanFrequencyUnit) => {
      let selectedSellingFrequency = sellingPlanFrequencyUnit || getters.selectedSellingPlanFrequencyAndUnit;
      if (![ONETIME_SELLING_PLAN_NAME, ONETIME_FREQUENCY].includes(selectedSellingFrequency))
        return selectedSellingFrequency;

      /* 
      When the Subscribe & Save feature is enabled, we replace the onetime frequency option with the first subscription
      frequency to handle onetime prices as subscription price. This is only for the UI and it does not affect the global state.
      */
      if (usedWithinEmbeddedThemeContext() && getters.isSubscribeAndSaveEnabled) {
        // TODO: Acá
        const frequency = getters.normalizedFrequencyOptions.find(
          ({ id }) => String(id) !== String(ONETIME_SELLING_PLAN_ID)
        );
        selectedSellingFrequency = frequency?.frequencyAndUnit ?? selectedSellingFrequency;
      }
      return selectedSellingFrequency;
    },

    itemPriceByPlan: (state, getters) => (product, variant) => {
      const variantFullPrice = getters.getVariantFullPrice(variant);

      const { intervalUnit, orderIntervalFrequency } = getters.selectedPlan.subscriptionPreferences;
      const frequencyUnit = `${orderIntervalFrequency}-${intervalUnit}`;
      let active = getters.itemPriceBySellingPlan(product, variant, frequencyUnit) / 100;
      if (!active) {
        // TODO: Remove validation/comparison by selling plan' name. https://recharge.atlassian.net/browse/BUN-1601
        active = getters.itemPriceBySellingPlanByName(product, variant, getters.selectedPlan.title) / 100;
      }

      if (getters.dynamicFilterProductsByDiscount) {
        const { discountType = 'percentage', discountAmount = 0 } = getters.selectedPlan ?? {};
        active = calculatePriceWithDiscount(discountType, variantFullPrice, discountAmount);
      }

      return {
        compareAt: variantFullPrice,
        active,
      };
    },

    itemPrice: (state, getters) => (item, variantId, sellingPlanFrequencyUnit) => {
      const selectedVariant = item.variants.find((v) => v.id === variantId) ?? item.variants[0];
      if (getters.useRechargePlans) {
        const value = getters.itemPriceByPlan(item, selectedVariant);
        value.active = getters.applyTierDiscount(value.active);
        return value;
      }

      const oneTime = getters.itemPriceBySellingPlan(item, selectedVariant, ONETIME_FREQUENCY) / 100;

      let subscription =
        getters.itemPriceBySubscriptionSellingPlan(item, selectedVariant, sellingPlanFrequencyUnit) / 100;
      if (!subscription) {
        // TODO: Remove validation/comparison by selling plan' name. https://recharge.atlassian.net/browse/BUN-1601
        subscription =
          getters.itemPriceBySubscriptionSellingPlanByName(item, selectedVariant, sellingPlanFrequencyUnit) / 100;
      }

      const selectedSellingPlanFrequencyUnit = getters.getSelectedSellingPlanFrequencyUnit(sellingPlanFrequencyUnit);
      let active = getters.itemPriceBySellingPlan(item, selectedVariant, selectedSellingPlanFrequencyUnit) / 100;
      if (!active) {
        // TODO: Remove validation/comparison by selling plan' name. https://recharge.atlassian.net/browse/BUN-1601
        let selectedSellingPlanName = getters.getSelectedSellingPlanName(sellingPlanFrequencyUnit);
        active = getters.itemPriceBySellingPlanByName(item, selectedVariant, selectedSellingPlanName) / 100;
      }

      const originalCompareAtPrice = selectedVariant.compare_at_price / 100;

      const pricesSortedDescending = [oneTime, active, originalCompareAtPrice].sort((a, b) => b - a);

      const compareAt = pricesSortedDescending[0];

      return {
        active: getters.applyTierDiscount(active),
        compareAt,
        oneTime,
        subscription: getters.applyTierDiscount(subscription, sellingPlanFrequencyUnit),
      };
    },

    itemTotals: (state, getters) => (item, variantId, sellingPlanFrequencyUnit) => {
      const { active, compareAt, oneTime, subscription } = getters.itemPrice(item, variantId, sellingPlanFrequencyUnit);

      const qty = getters.selectedQty(item);
      return {
        active: parsePrice(active) * qty,
        compareAt: parsePrice(compareAt) * qty,
        oneTime: parsePrice(oneTime) * qty,
        subscription: parsePrice(subscription) * qty,
      };
    },

    getSubscriptionDataByProductId: (state, getters) => (product) => {
      const subscriptionData = getters.dataSources
        .map((ds) => ds.collection.subscription_data_by_product_id)
        .find((data) => data[product.id]);

      return subscriptionData ? subscriptionData[product.id] : null;
    },

    getAllSellingPlansFromGroups: (_, getters) => (item) => {
      if (getters.isShopifySubscription) {
        const sellingPlanGroups = item.sellingPlanGroups ?? item.selling_plan_groups ?? [];
        return sellingPlanGroups.flatMap(({ selling_plans }) => selling_plans);
      }

      return [];
    },

    getSellingPlansNames: (state, getters) => (item) => {
      if (getters.isShopifySubscription) {
        return getSellingPlanFrequencies(getters.getAllSellingPlansFromGroups(item));
      }

      const subscriptionData = getters.getSubscriptionDataByProductId(item);
      if (subscriptionData) {
        return subscriptionData.shipping_interval_frequency?.split(',') || [];
      }

      return [];
    },

    getSellingPlansDataByName: (state, getters) => (productVariant, product) => {
      // Fox SCI stores, we always have selling plan information at variant level named as selling_plan_allocations
      if (getters.isShopifySubscription) {
        return productVariant.selling_plan_allocations
          .filter(
            // TODO: Remove validation/comparison by selling plan' name. https://recharge.atlassian.net/browse/BUN-1601
            ({ selling_plan_name, selling_plan_frequency_unit }) => !!selling_plan_name || !!selling_plan_frequency_unit
          )
          .reduce((acc, current) => {
            const { selling_plan_name, selling_plan_frequency_unit } = current;
            // TODO: Remove validation/comparison by selling plan' name. https://recharge.atlassian.net/browse/BUN-1601
            if (selling_plan_name) {
              acc[sanitizeText(selling_plan_name)] = current;
            }

            if (selling_plan_frequency_unit) {
              acc[selling_plan_frequency_unit] = current;
            }
            return acc;
          }, {});
      }

      const subscriptionData = getters.getSubscriptionDataByProductId(product);
      if (!subscriptionData) return {};

      // For RCS stores we must rely on the subscription_data_by_product_id object located in each collection object.
      // Object has the structure: {<external-product-id>: Object<subscription-data>>}
      return (subscriptionData.shipping_interval_frequency?.split(',') ?? []).reduce((acc, current) => {
        const discountValue = subscriptionData.discount_percentage;
        //  We take the price adjustment information from the discount_percentage.
        // It handles the discount as a percentage discount because it's what the key describes
        const priceAdjustment = discountValue ? [`percentage-${Number(discountValue) || 0}`] : [];
        acc[current] = { ...subscriptionData, selling_plan_price_adjustments: priceAdjustment };
        return acc;
      }, {});
    },

    getSellingPlanAllocationFrequencies: (state, getters) => (product, productVariant) => {
      if (getters.isShopifySubscription) {
        return new Set(Object.keys(getters.getSellingPlansDataByName(productVariant, product)));
      }

      // For RCS stores, subscription data is associated to the product level, not at variant level
      const subscriptionData = getters.getSubscriptionDataByProductId(product);
      if (subscriptionData) {
        return new Set(subscriptionData.shipping_interval_frequency?.split(',') || []);
      }

      return new Set([]);
    },

    // TODO: Remove validation/comparison by selling plan' name. https://recharge.atlassian.net/browse/BUN-1601
    getSellingPlanAllocationsNames: (state, getters) => (product, productVariant) => {
      let sellingPlanNames = [];

      if (getters.isShopifySubscription && !getters.subscription) {
        sellingPlanNames = Object.keys(getters.getSellingPlansDataByName(productVariant, product));
      } else {
        sellingPlanNames = getters.getSellingPlansNames(product);
      }

      return new Set(sellingPlanNames.map(sanitizeText));
    },

    bundleDynamicPriceBySellingPlan: (state, getters, rootState) => (sellingPlanFrequencyUnit) => {
      const sum = (price1, price2) => price1 + price2;

      const selectedProducts = uniq(
        getters.dataSources
          .flatMap((ds) => getters.getDataSourceCollection(ds).products)
          .filter((product) => getters.selectedQty(product) > 0),
        'id'
      );

      const prices = selectedProducts.map((product) => {
        const variantId = rootState.selectedContents[product.id]?.vId;
        return getters.itemTotals(product, variantId, sellingPlanFrequencyUnit);
      });

      const active = prices.map((price) => price.active).reduce(sum, 0);
      const oneTime = prices.map((price) => price.oneTime).reduce(sum, 0);
      const compareAtPrice = prices.map((price) => price.compareAt).reduce(sum, 0);
      const subscription = prices.map((price) => price.subscription).reduce(sum, 0);

      return {
        active,
        oneTime,
        subscription,
        compareAtPrice,
      };
    },

    bundleSellingPlanFrequencyUnit(state, getters, rootState) {
      // Get the selling plan frequency-unit data from the selected selling plan allocation
      const selectedSellingPlan = rootState.selectedSellingPlanAllocation?.sellingPlan;
      // Use the selling plan frequency-unit from the selected variant first selling plan allocation
      const selectedVariantSellingPlan = getters.activeVariant?.sellingPlanAllocations?.[0]?.sellingPlan;

      const options = selectedSellingPlan?.options ?? selectedVariantSellingPlan?.options;
      const frequencyUnit = getSellingPlanFrequencyAndUnit(options ?? []);
      if (frequencyUnit) {
        return frequencyUnit;
      }

      // Use the selling plan frequency-unit  from the first selling plan allocation in the bundle product
      if (getters.isShopifySubscription) {
        const fallbackSellingPlan = rootState.product.sellingPlanGroups[0].group.selling_plans[0];
        return getSellingPlanFrequencyAndUnit(fallbackSellingPlan.options ?? []);
      }

      return rootState.product.subscriptionData.shipping_interval_frequency?.split(',')[0];
    },

    // TODO: Remove validation/comparison by selling plan' name. https://recharge.atlassian.net/browse/BUN-1601
    bundleSellingPlans(state, getters, rootState) {
      // Get the selling plan name from the selected selling plan allocation
      const sellingPlan = rootState.selectedSellingPlanAllocation?.sellingPlan;
      if (sellingPlan) {
        return [sellingPlan.name];
      }

      // Get the selling plan name from the selected variant first selling plan allocation
      const bundleVariantPlanAllocations = getters.activeVariant?.sellingPlanAllocations ?? [];
      if (bundleVariantPlanAllocations.length) {
        return bundleVariantPlanAllocations.map(({ sellingPlan }) => sellingPlan.name);
      }

      // Get the selling plan name from the first selling plan allocation in the bundle product
      if (getters.isShopifySubscription) {
        return rootState.product.sellingPlanGroups[0].group.selling_plans.map((group) => group.name);
      }

      return rootState.product.subscriptionData.shipping_interval_frequency?.split(',') || [];
    },

    /**
     * Calculates the number of products pending to be added or removed to create a subscription using a dynamic ranges bundle
     * @returns {number} The difference between the bundle selection count and variant dynamic ranges
     */
    dynamicRangeCountDifference: (state, getters) => {
      const requiredProductsCount = getters.remainingRequiredProducts;
      const { min, max } = getters.getDynamicRangesBounds;

      /**
       * Sometimes, the user can change the selected variant, and the new variant could have a lower range.
       * This validation calculates the difference between the current selection and the ranges, and in case the
       * result is lower than 0, return the difference. Otherwise, continue to calculate the remaining products count.
       * Example
       *  Initial variant's ranges: { quantity_min: 1, quantity_max: 6 }, selected products: 6
       *  New selected variant's ranges: { quantity_min: 1, quantity_max: 3 }, difference -3
       * In the situation described above, the user must remove at least 3 products to balance the bundle
       */
      if (max - getters.selectedContentsCount < 0) {
        return max - getters.selectedContentsCount;
      }

      /**
       * Sometimes, the minimum range value can be less than the collection limits. So, to have a more consistent
       * validation it considers the collection's limits to calculate the remainder products.
       * Example
       *  - The dynamic range is between 1 and 100
       *  - Collection limit is exactly 10 (min and max equal to 10)
       * In this case, I must add minimum of 10 products to balance the selection.
       *  */
      return Math.max(min - getters.selectedContentsCount, requiredProductsCount);
    },

    dynamicRanges: (state, getters) => getters.activeVariantConfig.dynamicRanges,

    hasDynamicRanges: (state, getters) => {
      // return getters.isDynamicBundle && Boolean(getters.dynamicRanges.length);
      const haveRanges = getters.isDynamicBundle && Boolean(getters.dynamicRanges.length);
      if (!haveRanges) {
        return false;
      }

      const { min, max } = getters.getDynamicRangesBounds;
      return min < max;
    },

    /**
     * Validates if the amount of selected products satisfies at least one of the variant's ranges
     * @param {number} totalItems
     * @returns {boolean} The total of selected products matches with any of the variant's ranges.
     */
    selectionsWithinVariantRanges: (state, getters) => (totalItems) => {
      const ranges = getters.dynamicRanges.filter(({ quantity_min, quantity_max }) => {
        const max = quantity_max ?? totalItems;
        return totalItems >= quantity_min && totalItems <= max;
      });

      return Boolean(ranges.length);
    },

    /**
     * Gets the minimum and maximum value of the whole list of dynamic ranges
     * @returns {object} The min and max value
     */
    getDynamicRangesBounds: (state, getters) => {
      return getters.activeVariantConfig.dynamicRagesBounds;
    },

    canAddDynamicBundle: (state, getters) => (dataSource) => {
      if (dataSource.itemsCount.bounded) {
        return getters.canAddWithLimitedCollection(dataSource);
      }

      return getters.canAddWithUnlimitedCollection;
    },
  },
};
