import c from 'currency.js';

export default function getPriceAsNumber(price, currency) {
  const currencyOptions = {
    USD: { decimal: '.', separator: ',' },
    EUR: { decimal: ',', separator: '.' },
  };
  let options = currency ? currencyOptions[currency] : currencyOptions.USD;

  return c(price, options).value;
}
