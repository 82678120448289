export const ONETIME_SELLING_PLAN_ID = 0;

// Fallback "frequency" value for ONETIMEs to avoid having null/undefined frequency values
export const ONETIME_FREQUENCY = '0';

export const ONETIME_SELLING_PLAN_NAME = 'Only once';

// Reference: https://support.rechargepayments.com/hc/en-us/articles/12069641360279-Can-I-preview-the-bundle-on-my-storefront-without-publishing-it-
export const EXPECTED_VIEW_PARAM_VALUE = 'recharge-bundle';

export const SUBSCRIPTION_PLAN_TYPE = 'subscription';

export const ONETIME_SELLING_PLAN = {
  checkout_charge: { value: 0, value_type: 'percentage' },
  options: [],
  price_adjustments: [],
  description: null,
  name: 'Only once',
  recurring_deliveries: false,
  id: 0,
};

export const ONETIME_PLAN = {
  type: 'onetime',
  title: 'Only once',
  id: 0,
  sortOrder: 0,
  discountType: 'percentage',
  discountAmount: '0',
  externalPlanGroupId: null,
  externalPlanId: '0',
  channelSettings: { display: true },
  subscriptionPreferences: {
    chargeIntervalFrequency: ONETIME_FREQUENCY,
    intervalUnit: null,
    orderIntervalFrequency: ONETIME_FREQUENCY,
  },
  hasVariantRestrictions: false,
  externalVariantIds: [],
};
