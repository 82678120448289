import rebundleData from '../rebundle-data';

const REBUNDLE_PROPERTIES = {
  _box_order: true,
  _box_skus: true,
  _box_variant_id: true,
  box_contents: true,
};

function removeRebundleProperties(properties) {
  return properties.filter(({ name }) => {
    return !REBUNDLE_PROPERTIES[name];
  });
}
export default function validateProperties(subscription) {
  const boxOrder = subscription.properties.find((p) => p.name === '_box_order');

  if (boxOrder) {
    try {
      const bundle = rebundleData.toBundle(boxOrder.value);
      if (bundle.variantId.toString() !== subscription.shopify_variant_id.toString()) {
        return removeRebundleProperties(subscription.properties);
      }
    } catch (e) {
      // this is probably an old box format, let's try _box_variant_id
      const variantId = subscription.properties.find((p) => p.name === '_box_variant_id');
      if (variantId && variantId.value.toString() !== subscription.shopify_variant_id.toString()) {
        return removeRebundleProperties(subscription.properties);
      }
    }
  }

  return subscription.properties;
}
