import { fetch } from 'whatwg-fetch';
import { usedWithinEmbeddedThemeContext } from './customer-portal';
import { Logger } from '../plugins/logger';

export default async function loadWidgetSettings() {
  // Shopify Store
  const shopifyStoreName = window.Shopify?.shop;

  try {
    const url = `${process.env.CDN_ENDPOINT}store/${shopifyStoreName}/2020-12/widget_settings.json`;
    const response = await fetch(url, {
      headers: {
        accept: 'application/json',
      },
    });

    if (response.ok) {
      try {
        const res = await response.json();

        if (!res.widget_settings.bundle_translations) {
          return {};
        }

        const translations = JSON.parse(res.widget_settings.bundle_translations);

        const mapTranslationsPDP = {
          boxContents: translations.pdp_bundle_contents,
          remainder: {
            add: translations.pdp_choose_items,
            addMaximum: translations.pdp_choose_maximum,
            addRange: translations.pdp_choose_range,
            chooseAtLeast: translations.pdp_dynamic_ranges_choose_at_least,
            statusAdd: translations.pdp_status_add,
            statusChooseAtLeast: translations.pdp_status_choose_at_least,
            statusAddRange: translations.pdp_status_add_range,
          },
          boxItem: {
            add: translations.pdp_add,
            remove: translations.pdp_remove,
          },
          addToCart: {
            completed: translations.pdp_add_to_cart,
            add: translations.pdp_add_items_to_continue,
            added: translations.pdp_product_added,
            remove: translations.pdp_remove,
            updateSelections: translations.pdp_update_your_selections_to_continue,
            soldOut: translations.pdp_sold_out,
            error: translations.pdp_add_to_cart_failed,
            dynamicRangesAdd: translations.pdp_dynamic_ranges_add,
            dynamicRangesAddAtLeast: translations.pdp_dynamic_ranges_add_at_least,
          },
          goToCart: translations.pdp_proceed_to_cart,
          alert: {
            planChanged: translations.pdp_plan_changed_alert,
            add: translations.pdp_choose_items_alert,
            remove: translations.pdp_remove_items_alert,
            selectionCount: translations.pdp_selection_count_alert,
            collectionComplete: translations.pdp_selection_completed_alert,
            complete: translations.pdp_add_to_cart_alert,
            maxReached: translations.pdp_max_per_item_reached_alert,
            dynamicRangeChooseAsManyAsYouLike: translations.pdp_dynamic_ranges_choose_as_many_as_you_like,
          },
          frequency: {
            label: translations.pdp_frequency,
            subscriptionLabel: translations.pdp_subscribe,
            oneTimeLabel: translations.pdp_only_once,
            option: translations.pdp_frequency_value,
          },
          month: translations.pdp_month,
          week: translations.pdp_week,
          day: translations.pdp_day,
          filters: {
            label: translations.pdp_filters,
            selectedCount: translations.pdp_items_selected_filters,
            apply: translations.pdp_apply_filters,
            clear: translations.pdp_clear_all_filters,
          },
          addOns: {
            title: translations['pdp_add_repeating_add-ons'],
            description: translations['pdp_add_repeating_add-ons_description'],
          },
          oneOffExtras: {
            extras: translations['pdp_one-off_extras'],
            title: translations['pdp_add_one-off_extras'],
            description: translations['pdp_add_one-off_extras_description'],
            summary: translations.pdp_order_summary,
            otherItems: translations.pdp_regular_delivery,
            total: translations.pdp_total,
            addToCart: translations.pdp_add_to_cart_extras,
          },
          multiStep: {
            summaryStep: {
              addons: translations['pdp_repeating_add-ons'],
              total: translations.pdp_total,
            },
          },
          back: translations.pdp_back,
          next: translations.pdp_next,
          cancel: translations.pdp_cancel,
          incentives: {
            discountTierTitle: translations['pdp_tier_discount_save_title'],
            discountTierMessage: translations['pdp_tier_discount_add_message'],
            discountTierSummaryMessage: translations['pdp_tier_discount_notice_message'],
            discountTierExtraOff: translations['pdp_tier_discount_extra_discount_off'],
          },
        };

        const mapTranslationsCP = {
          remainder: {
            add: translations.cpb_status_add,
            chooseAtLeast: translations.cpb_dynamic_ranges_choose_at_least,
            statusAdd: translations.cpb_status_add,
            statusChooseAtLeast: translations.cpb_status_choose_at_least,
            statusAddRange: translations.cpb_status_add_range,
            addRange: translations.cpb_status_add_range,
          },
          boxItem: {
            add: translations.cpb_add,
            remove: translations.cpb_remove,
          },
          addToCart: {
            add: translations.cpb_add_items_to_continue,
            remove: translations.cpb_remove_items_to_continue,
            addMore: translations.cpb_add_more_items,
            removeSome: translations.cpb_remove_some_items,
            dynamicRangesAdd: translations.cpb_dynamic_ranges_add,
            dynamicRangesAddAtLeast: translations.cpb_dynamic_ranges_add_at_least,
          },
          alert: {
            planChanged: translations.cpb_plan_changed_alert,
            add: translations.cpb_choose_items_alert,
            remove: translations.cpb_remove_items_alert,
            selectionCount: translations.cpb_selection_count_alert,
            collectionComplete: translations.cpb_selection_completed_alert,
            complete: translations.cpb_add_to_cart_alert,
            maxReached: translations.cpb_max_per_item_reached_alert,
            dynamicRangesMaxSelectionCount: translations.cpb_dynamic_ranges_max_selection_alert,
            dynamicRangeChooseAsManyAsYouLike: translations.cpb_dynamic_ranges_choose_as_many_as_you_like,
          },
          month: translations.cpb_month,
          week: translations.cpb_week,
          day: translations.cpb_day,
          cancel: translations.cpb_cancel,
          frequency: {
            label: translations.cpb_frequency,
            option: translations.cpb_frequency_values,
          },
          filters: {
            label: translations.cpb_filters,
            selectedCount: translations.cpb_items_selected_filters,
            apply: translations.cpb_apply_filter,
            clear: translations.cpb_clear_all_filters,
          },
          subscriptionPage: {
            contentsFor: translations.cpb_contents_for,
            updateContents: translations.cpb_update_bundle_contents,
            select: translations.cpb_select_bundle_contents,
            your: translations.cpb_your_title,
            save: translations.cpb_save_button_text,
            saving: translations.cpb_saving_message,
            saved: translations.cpb_saved_message,
            yourNextOrder: translations.cpb_your_next_order,
            missingContentsTitle: translations.cpb_select_your_contents_to_continue_title_modal,
            missingContentsDescription: translations.cpb_select_your_contents_to_continue_message_modal,
          },
          order: {
            add: translations.cpb_add_to_order,
          },
          incentives: {
            discountTierTitle: translations['cpb_tier_discount_save_title'],
            discountTierMessage: translations['cpb_tier_discount_add_message'],
          },
        };

        const isRenderedWithinCustomerPortal =
          !!window.ReCharge?.Novum || !!window.ReCharge?.Prima || usedWithinEmbeddedThemeContext();
        return {
          ...res,
          widget_settings: {
            ...res.widget_settings,
            bundle_translations: isRenderedWithinCustomerPortal ? mapTranslationsCP : mapTranslationsPDP,
          },
        };
      } catch (e) {
        Logger.getInstance().error(e);
        return null;
      }
    }

    return null;
  } catch (e) {
    Logger.getInstance().error(e);
    return null;
  }
}
