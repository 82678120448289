import { Logger } from '../../plugins/logger';
import { mapProductVariantsSellingPlans } from '../../utils/product';

export default {
  state: () => ({
    addonsOrder: {},
  }),

  getters: {
    addonProducts: (state, getters, rootState) => {
      let addons = rootState.product.addons?.products ?? [];

      if (getters.isShopifySubscription) {
        const bundleSellingPlanNames = getters.getSellingPlansNames(rootState.product);

        // Only addons with same selling plans as the bundle product are returned
        addons = addons.filter((addon) => {
          const sellingPlanNames = new Set(getters.getSellingPlansNames(addon));
          const isValid = bundleSellingPlanNames.every((planName) => sellingPlanNames.has(planName));

          if (isValid) {
            const bundlePlansNames = bundleSellingPlanNames.join(',');
            Logger.getInstance().warning(
              `Addon products need equivalent selling plans (Name and Frequency) to the bundle parent product. Make sure "${addon.title}" has a "${bundlePlansNames}" plan configured.`
            );
          }
          return isValid;
        });

        if (!addons.length) {
          Logger.getInstance().error(
            'Addon products need equivalent selling plans (Name and Frequency) to the bundle parent product. Please review your add-ons selling plans.'
          );
        }
        const logCommitMessage = `Addons - products filtered by plan name`;
        Logger.getInstance().commitLogs(logCommitMessage);
      }
      return addons;
    },

    addonsOrderFiltered: (state) => {
      var filteredKeys = Object.keys(state.addonsOrder).filter(function (item) {
        return state.addonsOrder[item].quantity > 0;
      });

      var filteredOrder = {};

      filteredKeys.forEach(function (key) {
        filteredOrder[key] = state.addonsOrder[key];
      });

      return filteredOrder;
    },

    addonsOrderTotals: (state, getters) => {
      let totals = {
        compareAt: 0,
        subscription: 0,
        oneTime: 0,
        active: 0,
      };

      Object.keys(getters.addonsOrderFiltered).forEach((productId) => {
        let order = getters.addonsOrderFiltered[productId];
        let product = getters.addonProducts.find((product) => Number(product.id) === Number(productId));
        let variant = product.variants.find((variant) => Number(variant.id) === order.id);

        let compareAtPrice = getters.variantCompareAtPrice(product, variant) * order.quantity;
        let subscriptionPrice = getters.variantSubscriptionPrice(product, variant) * order.quantity;
        let oneTimePrice = getters.variantOneTimePrice(variant) * order.quantity;
        let activePrice = getters.variantActivePrice(product, variant) * order.quantity;

        totals.compareAt += compareAtPrice;
        totals.subscription += subscriptionPrice;
        totals.oneTime += oneTimePrice;
        totals.active += activePrice;
      });

      return totals;
    },

    getAddonPlansProperties: () => (addon) => {
      return {
        selling_plan_groups: addon.product.selling_plan_groups,
        variants: addon.product.variants.map((variant) => ({
          ...variant,
          sellingPlanAllocations: variant.sellingPlanAllocations ?? variant.selling_plan_allocations,
        })),
      };
    },
  },

  actions: {
    setDefaultAddonsOrder({ commit, getters }) {
      let defaultOrder = {};
      if (getters.addonProducts?.length) {
        const preselectedContent = getters.paramsAddonsContents;
        getters.addonProducts.forEach((product) => {
          const { variantId, quantity } = preselectedContent[product.id] ?? {};
          const updatedProduct = mapProductVariantsSellingPlans(getters.getAddonPlansProperties(product));
          const firstAvailableVariant = updatedProduct.variants.find((v) => v.available);
          const preselectedVariant = updatedProduct.variants.find(
            (variant) => variant.available && Number(variantId) === Number(variant.id)
          );
          const defaultVariant = preselectedVariant ?? firstAvailableVariant ?? updatedProduct.variants[0];

          defaultOrder[product.id] = {
            id: Number(defaultVariant.id),
            quantity: preselectedVariant ? quantity : 0,
            subscriptionData: product.subscriptionData,
            sellingPlanAllocations: defaultVariant.selling_plan_allocations,
            product: updatedProduct,
          };
        });
      }
      commit('updateAddonsOrder', defaultOrder);
    },
  },

  mutations: {
    updateAddonsOrder(state, payload) {
      state.addonsOrder = payload;
    },
  },
};
