// @ts-check
const RCS_DOMAIN = 'https://checkout.rechargeapps.com/r/checkout';

/**
 * Get the cart token from the cookie
 * @returns {string} cart token (empty string if not found)
 */
const getCartTokenFromCookie = () => {
  const [cookieValue = ''] = document.cookie.match(/cart=\w+/) ?? [];
  return cookieValue.replace('cart=', '');
};

/**
 * Get the Shopify store domain from the window Shopify object
 * @returns {string} shopify store domain (empty string if not found)
 */
const getShopifyStoreDomain = () => {
  // @ts-expect-error Shopify is not defined on window
  return window.Shopify?.shop ?? '';
};

/**
 * Get the Recharge Checkout URL
 * @returns {string} the checkout URL for Recharge Checkout
 */
export const getRCSCheckoutURL = () => {
  const cartToken = getCartTokenFromCookie();
  const shopifyStoredomain = getShopifyStoreDomain();

  return `${RCS_DOMAIN}?myshopify_domain=${shopifyStoredomain}&cart_token=${cartToken}`;
};
