import Vuex from 'vuex';

import actions from './actions';
import getters from './getters';
import addonsModule from './modules/addons';
import dynamicBundlesModule from './modules/dynamic-bundles';
import orderModule from './modules/order';
import paramsModule from './modules/params';
import subscriptionModule from './modules/subscription';
import incentivesModule from './modules/incentives';

export function createStore(i18n) {
  return new Vuex.Store({
    modules: {
      subscription: subscriptionModule,
      addons: addonsModule,
      order: orderModule,
      dynamicBundle: dynamicBundlesModule,
      params: paramsModule,
      incentives: incentivesModule,
    },

    state: {
      busy: true,

      isLoading: false,

      product: {},

      activeVariantId: null,

      activeFilters: {},

      selectedFrequency: null,

      selectedSellingPlanAllocation: null,

      selectedContents: {},

      shopSettings: {},

      // Private
      // For now this is meant to be in development only.
      // When this value is specified all HTTP request go through this proxy.
      // This value allow us to hit any store in localhost.
      appProxy: null,

      // If configured, all calls to bundle data will go through this proxy.
      // Resulting in an http request like: http://my.proxy.com/bundle-data/1234
      bundleDataCorsProxy: null,

      flowCompleted: false,

      template: null,

      permissions: null,

      subscriptionConfig: null,

      selectedPlan: null,

      // This will store products that are missing in the bundle-data file.
      // The bundle-data file contains products that are within the Bundle collections
      // while, this object contains products that does not exist in the bundle-data.
      missingVariants: {},

      configInitialState: null,

      hideSubscriptionForm: false,
    },

    mutations: {
      updateActiveVariantId(state, payload) {
        state.activeVariantId = Number(payload);
      },

      updateSelectedContents(state, payload) {
        state.selectedContents = payload;
      },

      updateSelectedFrequency(state, payload) {
        state.selectedFrequency = payload;
      },

      updateSelectedPlan(state, payload) {
        state.selectedPlan = payload;
      },

      updateSelectedSellingPlanAllocation(state, payload) {
        state.selectedSellingPlanAllocation = payload;
      },

      updateActiveFilters(state, payload) {
        state.activeFilters = payload;
      },

      setProduct(state, payload) {
        state.product = payload;
      },

      setTemplate(state, payload) {
        state.template = payload;
      },

      setBusyState(state, payload) {
        state.busy = payload;
      },

      setIsLoading(state, payload) {
        state.isLoading = payload;
      },

      setShopSettings(state, payload) {
        state.shopSettings = payload ?? {};
      },

      setAppProxy(state, payload) {
        state.appProxy = payload;
      },

      setBundleDataCorsProxy(state, payload) {
        state.bundleDataCorsProxy = payload;
      },

      setAsCompleted(state, payload) {
        state.flowCompleted = payload;
      },

      setSources(state, payload) {
        state.sources = payload;
      },

      setStorePermissions(state, payload) {
        state.permissions = payload;
      },

      setSubscriptionConfig(state, payload) {
        state.subscriptionConfig = payload;
      },

      setMissingVariants(state, { missingVariants }) {
        state.missingVariants = missingVariants;
      },

      setConfigInitialState(state, payload) {
        state.configInitialState = payload;
      },

      setHideSubscriptionForm(state, payload) {
        state.hideSubscriptionForm = payload;
      },
    },

    getters: getters(i18n),

    actions: actions(i18n),
  });
}
